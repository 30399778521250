<template>
  <div class="com-content index-page">
    <div class="kv">
      <p>
        <span class="border wow animate__animated animate__line">
          <i class="animate__animated"></i>
          <i class="animate__animated"></i>
          <i class="animate__animated"></i
        ></span>
        <span
          class="tit tit-h1 wow animate__animated animate__slideInUps"
          v-html="kvData.slogan_content"
        ></span>
      </p>
      <video
        :src="kvData.media_image_url"
        type="video/mp4"
        muted
        autoplay="autoplay"
        loop="loop"
        x5-playsinline="true"
        playsinline="true"
        webkit-playsinline="true"
        x-webkit-airplay="allow"
        preload="auto"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
      ></video>
      <p class="scroll-tip">{{ $t("scrollTip") }}</p>
      <div class="mask"></div>
    </div>
    <!-- video -->
    <div class="video">
      <div class="left">
        <div class="border-parent">
          <span class="border wow animate__animated animate__line">
            <i class="animate__animated"></i>
            <i class="animate__animated"></i>
            <i class="animate__animated"></i>
          </span>
          <p
            class="
              tit tit-h1
              right-line
              wow
              animate__animated animate__slideInUps
            "
            v-html="homeData.video.videoTitle"
          ></p>
        </div>

        <p class="wow animate__animated animate__slideInUps">
          <router-link to="/about">{{ $t("moreAbout") }}</router-link>
        </p>
      </div>
      <div class="right wow animate__animated animate__slideInUps">
        <div class="video-con">
          <video
            id="video"
            :src="homeData.video.videoUrl"
            :poster="homeData.video.imageUrl"
          ></video>
          <div class="control">
            <img src="../assets/p1/play.png" alt="" />
            <img src="../assets/p1/pause.png" alt="" />
          </div>
          <p v-html="homeData.video.videoInfo"></p>
        </div>
      </div>
    </div>
    <!-- CEO 介绍 -->
    <div class="person">
      <div class="border-parent">
        <span class="border border-left wow animate__animated animate__line">
          <i class="animate__animated"></i>
          <i class="animate__animated"></i>
          <i class="animate__animated"></i>
        </span>
        <p
          class="tit wow left-line animate__animated animate__slideInUps"
          v-html="homeData.ceo.ceoTitle"
        ></p>
      </div>
      <div class="main">
        <div class="left">
          <p
            class="wow animate__animated animate__slideInUps"
            v-html="homeData.ceo.ceoInfo"
          ></p>
        </div>
        <div class="right">
          <p>
            <router-link to="/team">{{
              $t("team")
            }}</router-link>
          </p>
          <div class="person wow animate__animated animate__slideInUps">
            <img src="../assets/p1/p1-person.png" alt="" />
          </div>
          <div class="wow animate__animated animate__slideInUps">
            <p>{{ homeData.ceo.ceoName }}</p>
            <p>{{ homeData.ceo.ceoPosition }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 业务概览 -->
    <div class="p4">
      <p class="c-tit tit wow animate__animated animate__slideInUps">
        {{ $t("p1tit3") }}
      </p>

      <div class="list wow animate__animated animate__slideInUps">

        <div class="item" v-for="(item,index) in homeData.metricsList" v-bind:key="index">
          <div>
            <p class="tit" v-html="splitString(item.number)"></p>
            <p class="tit">{{item.info}}</p>
            <i></i>
            <p v-html="item.content"></p>
            <img src="../assets/common-grren-arrow.png" alt="" />
            <div class="border fixed"><i></i><i></i><i></i></div>
          </div>
          <div><img :src="item.imgUrl" alt="" /></div>
        </div>

      </div>
    </div>
    <div  id="mapParentTop"></div>
    <maps></maps>
    <!-- 地图 待修改 -->
    <div class="p6">
      <h4 class="c-tit">
        <label class="tit tit-h2 wow animate__animated animate__slideInUps">{{
          $t("p1tit5")
        }}</label>
        <span
          ><router-link to="/news">{{ $t("p1tit51") }}</router-link></span
        >
      </h4>
      <div
        class="news-list pc wow animate__animated animate__slideInUps"
        :class="{ column: $store.state.isWap }"
      >
        <swiper
          :slides-per-view="$store.state.isWap ? 2 : 3"
          :space-between="50"
          @swiper="onSwiper"
          @slide-change-transition-end="swiperPcChange"
        >
          <template
            v-for="(item, index) in newsList"
            v-bind:key="index"
          >
            <swiper-slide>
              <div class="news-item">
                <div @click="this.$router.push('/news/' + item.id)">
                  <img :src="item.news_image_url" alt="" />
                  <div class="hover">
                    <img src="../assets/news-arrow.png" alt="" />
                  </div>
                  <div class="border fixed"><i></i><i></i><i></i></div>
                </div>
                <p>{{ item.release_time }} / {{ item.news_type }}</p>
                <p>{{ item.news_title }}</p>
                <router-link :to="'/news/' + item.id" class="read-more">{{
                  $t("more")
                }}</router-link>
              </div>
            </swiper-slide>
          </template>
        </swiper>
        <div class="arrows pc">
          <img
            src="../assets/left-arrow.svg"
            alt=""
            :style="pcPrevDisable ? disabledStyle : ''"
            @click="pcPrev"
          />
          <img
            src="../assets/right-arrow.svg"
            alt=""
            :style="pcNextDisable ? disabledStyle : ''"
            @click="pcNext"
          />
        </div>

        <div class="pagePoint" v-if="$store.state.isWap">
          <template
            v-for="(item, index) in $store.state.newsContent[$store.state.lang]"
            v-bind:key="index"
          >
            <li :class="{ active: swiperPc.activeIndex == index }"></li>
          </template>
        </div>
      </div>

      <div
        class="news-list wap wow animate__animated animate__slideInUps"
        :class="{
          'swiper-grid swiper-grid-column mySwiper': $store.state.isWap,
        }"
      >
        <swiper :slides-per-view="1" :space-between="50" @swiper="wapSwiper">
          <template
            v-for="(item, index) in newsList"
            v-bind:key="index"
          >
            <swiper-slide v-if="index % 2 == 0 && newsList[index + 1]">
              <div class="news-item">
                <div @click="this.$router.push('/news/' + item.id)">
                  <img :src="item.news_image_url" alt="" />
                  <div class="hover">
                    <img src="../assets/news-arrow.png" alt="" />
                  </div>
                  <div class="border fixed"><i></i><i></i><i></i></div>
                </div>
                <p>{{ item.release_time }} / {{ item.news_type }}</p>
                <p>{{ item.news_title }}</p>
                <router-link :to="'/news/' + item.id" class="read-more">{{
                  $t("more")
                }}</router-link>
              </div>

              <div class="news-item">
                <div @click="this.$router.push('/news/' +newsList[index + 1].id)">
                  <img :src="newsList[index + 1].news_image_url" alt="" />
                  <div class="hover">
                    <img src="../assets/news-arrow.png" alt="" />
                  </div>
                  <div class="border fixed"><i></i><i></i><i></i></div>
                </div>
                <p>
                  {{ newsList[index + 1].release_time }} / {{ newsList[index + 1].news_type }}
                </p>
                <p>{{ newsList[index + 1].news_title }}</p>
                <router-link
                  :to="'/news/' + newsList[index + 1].id"
                  class="read-more"
                  >{{ $t("more") }}</router-link
                >
              </div>
            </swiper-slide>
          </template>
        </swiper>

        <div class="pagePoint" v-if="$store.state.isWap" style="width: 100%">
          <template
            v-for="(item, index) in $store.state.newsContent[$store.state.lang]"
            v-bind:key="index"
          >
            <li
              :class="{ active: swiperWap.activeIndex == index }"
              v-if="index < parseInt(newsList.length / 2)"
            ></li>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/vue";
import wow from "wowjs";
import maps from "./element/map.vue";
// Import Swiper styles
import "swiper/swiper.less";
// import required modules
import { Grid } from "swiper";


import {getNewList} from '../api/new'
import {getHome} from '../api/common'
export default defineComponent({
  name: "Index",
  components: {
    Swiper,
    SwiperSlide,
    maps,
  },
  setup() {
    return {
      modules: [Grid],
    };
  },
  data() {
    return {
      timeRange: [],
      swiperPc: "",
      swiperWap: "",
      disabledStyle: { opacity: 0.5 ,'pointer-events':'none'},
      pcPrevDisable: true,
      wapPrevDisable: true,
      pcNextDisable: false,
      wapNextDisable: false,  
      isWap: this.$store.state.isWap,
      newsList:[{}],
      kvData:{},
      homeData:{video:{},ceo:{},metricsList:{},globalNetwork:{headquarters:{},coreOffices:{},portfolioCompanies:{}},},
      lang:window.localStorage.getItem('language') || "EN",
    };
  },
  mounted() {



    if(this.$store.state.kvData[0]){
      this.kvData = this.$store.state.kvData[0]
    }else{
      setTimeout(()=>{
        this.kvData = this.$store.state.kvData[0]
      },500)
    }

    setTimeout(() => {
      this.handleVideo();
      this.handleCLick();
    });

    new wow.WOW({
      boxClass: "wow", //需要执行动画的元素的 class
      animateClass: "animated", //animation.css 动画的 class
      offset: 200, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备上执行动画
      live: true, //异步加载的内容是否有效
    }).init();
    this.getNewsList()
    this.getHomeArray()
  },
  methods: {
    onSwiper(swiper) {
      this.swiperPc = swiper;
    },
    wapSwiper(swiper) {
      this.swiperWap = swiper;
    },
    swiperPcChange(e){
      this.pcNextDisable = false;
      this.pcPrevDisable = false;
      if (e.activeIndex <= 0) this.pcPrevDisable = true;
      if (e.activeIndex >= this.newsData.length - 3) this.pcNextDisable = true;


    },
    pcPrev() {
      this.swiperPc.slidePrev();
    },
    pcNext() {
      this.swiperPc.slideNext();
    },
    wapPrev() {
      this.swiperWap.slidePrev();
      this.wapNextDisable = false;
      if (this.swiperWap.activeIndex === 0) this.wapPrevDisable = true;
    },
    wapNext() {
      this.swiperWap.slideNext();
      this.wapPrevDisable = false;
      if (this.swiperWap.activeIndex === 2) this.wapNextDisable = true;
    },
    handleVideo() {
      $(".control").hover(
        function () {
          if (!$("#video")[0].paused) {
            $(".control>img:last-child").show();
          }
        },
        function () {
          $(".control>img:last-child").hide();
        }
      );
    },
    index(e) {
      return e + 1;
    },
    handleCLick() {
      const _this = this;
      console.log($("#video"));
      $(".control>img:first-child").click(function () {
        $("#video")[0].play();
        $(".control>img:first-child").hide();
        _this.handleVideo();
      });
      $(".control>img:last-child").click(function () {
        $("#video")[0].pause();
        $(".control>img:last-child").hide();
        $(".control>img:first-child").show();
        _this.handleVideo();
      });
    },
    getNewsList(){
      getNewList({
        page:0,
        page_num:6,
      }).then(res => {
        
        this.newsList = res.info.news
        this.newsList.forEach(item => {
          item.release_time = this.$store.state.timeFormat(item.release_time)
        })
      })
    },
    getHomeArray(){
      getHome({}).then(res => {
        this.homeData = res.info;
        console.log(this.homeData);
      })
    },
    splitString(str) {
      let a = str.split(",");
      if(a[1]){
        return a[this.lang == "EN" ? 1 : 0];
      }else{
        return str
      }
    }
  }
});
</script>

<style scoped lang="less">
.scroll-tip {
  position: absolute;
  color: #fff;
  font-size: 1.4rem;
  left: 23%;
  top: 70%;
  padding-left: 3rem;
  background: url("../assets/p1/icon-scroll.png") no-repeat;
  background-size: 1.2rem;
  background-position: 0 center;
}
@import "../style";
.kv {
  video {
    width: 100%;
    border: 0;
    vertical-align: bottom;
  }
  .border {
    max-width: 33rem;
  }
  p {
    z-index: 1;
    width:fit-content !important;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
  }
}
.video {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 100px 40px;
  margin-bottom: 180px;
  .left {
    width: 50%;
    align-self: center;
    position: relative;
    top: 22rem;
    .tit {
      display: inline-block;
      position: relative;
      font-size: 7.2rem;
    }
    > p:last-child {
      font-size: 1.7rem;
      position: absolute;
      bottom: -40%;
      display: inline-block;
      left: 0;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: @lineColor;
        position: absolute;
        bottom: -5px;
      }
    }
  }
  .right {
    width: 50%;
    align-self: center;
    .video-con {
      position: relative;
      &:after {
        width: 100%;
        height: 100%;
        content: "";
        display: block;
        background: @baseBgColor;
        position: absolute;
        top: 180px;
        right: 30px;
      }
      .control {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          &:last-child {
            display: none;
          }
        }
      }
      > video {
        width: 100%;
        z-index: 2;
        position: relative;
      }
      > p {
        color: #fff;
        position: absolute;
        bottom: -120px;
        left: 0;
        z-index: 2;
        width: 51%;
        line-height: 1.5;
        font-size: 1.4rem;
      }
    }
  }
}
.person {
  color: #fff;
  position: relative;
  overflow: hidden;
  .border-parent {
    font-size: 7.2rem;
    position: absolute;
    left: 16%;
    top: 35%;
    z-index: 2;
    width: 42%;
  }
  .main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .left {
      width: 40%;
      background: @baseBgColor;
      position: relative;
      p {
        position: absolute;
        width: 75%;
        line-height: 1.5;
        bottom: 100px;
        left: 35px;
        font-size: 1.7rem;
      }
    }
    .right {
      width: 60%;
      background: url("../assets/p1/person-bg.png") no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      padding-top: 135px;
      > p:first-child {
        position: absolute;
        right: 100px;
        top: 100px;
        font-size: 1.7rem;
        a {
          color: #ffffff;
        }
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background: @lineColor;
          position: absolute;
          bottom: -5px;
        }
      }
      .person {
        img {
          width: 60%;
          position: relative;
          left: 15%;
          bottom: -5px;
        }
      }
      > div:last-child {
        position: absolute;
        bottom: 100px;
        left: 30px;
        text-align: left;
        font-size: 1rem;
        p:first-child {
          font-size: 3rem;
        }
      }
    }
  }
}
.c-tit {
  font-size: 7.2rem;
  color: @baseTextColor;
  margin-bottom: 95px;
  padding-left: 5.5rem;
  &.white {
    color: #fff;
  }
}
.p4 {
  margin-top: 100px;
  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 25%;
      position: relative;
      &:after {
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        background: #ccc;
        position: absolute;
        right: 0;
        top: 0;
      }
      // &:last-child {
      //   &:after {
      //     display: none;
      //   }
      //   > div > p {
      //     margin-top: r0em;
      //   }
      //   i{
      //     margin-bottom: calc(12.4rem + 5rem);
      //   }
      // }
      > div {
        position: relative;
        p {
          &:first-child {
            font-size: 12rem;
            margin-bottom: 3.8rem;
            sup {
              font-size: 5rem;
            }
          }
          &:nth-child(2) {
            font-size: 3.6rem;
            margin-bottom: 7.5rem;
            margin-top: -5rem;
            font-family: "enTitle", "zhTit";
          }
          &:nth-child(4) {
            font-size: 1.7rem;
            width: 60%;
            padding-bottom: 2rem;
            transform: translateY(10rem);
            transition: all 0.5s;
          }
        }
        > i {
          display: block;
          width: 60px;
          height: 2px;
          background: @baseBgColor;
          margin-bottom: 12.4rem;
        }
        &:first-child {
          padding-left: 5.5rem;
          padding-top: 6rem;
          height: 48rem;
          img {
            position: absolute;
            bottom: 40px;
            right: 22px;
            opacity: 0;
            transition: all 0.5s;
          }
        }
        &:last-child {
          overflow: hidden;
          img {
            width: 100%;
            position: relative;
            transform: translateY(105%);
            display: block;
            transition: all 0.5s;
          }
        }
        .border {
          max-width: 100%;
          :first-child {
            height: 0;
            width: 4px;
            top: 0;
            left: 0;
          }
          i:nth-child(2) {
            width: 0;
            height: 4px;
            top: 0;
            right: 0;
          }
          i:nth-child(3) {
            height: 0;
            width: 4px;
            right: 0;
            bottom: 0;
            margin-bottom: 0;
          }
        }
      }
      &:hover,
      &.hover {
        .border {
          i:first-child {
            height: 20%;
            transition: all 0.2s 0.4s linear;
          }
          i:nth-child(2) {
            width: 100%;
            transition: all 0.2s 0.2s linear;
          }
          i:nth-child(3) {
            height: 100%;
            transition: all 0.2s linear;
          }
        }
      }
      &:hover {
        > div {
          &:first-child {
            > p {
              &:nth-child(4) {
                transform: translateY(0);
              }
            }
            .border {
              opacity: 1;
            }
            img {
              opacity: 1;
            }
          }
          &:last-child {
            img {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}
.p6 {
  .c-tit {
    margin-top: 100px;
    margin-bottom: 50px;
    span {
      float: right;
      font-size: 1.7rem;
      margin-right: 200px;
      margin-top: 2rem;
      position: relative;
      font-family: "enRegular", "zhRegular";
      a {
        color: @baseTextColor;
      }
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: @lineColor;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.2s;
      }
    }
  }
  .news-list.wap {
    display: none;
  }
  .news-list {
    width: 96%;
    margin: auto;
  }
  .news-item {
    > div {
      cursor: pointer;
      position: relative;
      > img {
        width: 100%;
        display: block;
      }
      .hover {
        transition: all 0.5s;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        opacity: 0;
        > img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.2rem;
          height: 7.2rem;
        }
      }
      .border {
        max-width: 100%;
        i:nth-child(1):first-child {
          height: 0;
          width: 4px;
          top: 0;
          left: 0;
        }
        i:nth-child(2) {
          width: 0;
          height: 4px;
          top: 0;
          right: 0;
        }
        i:nth-child(3) {
          height: 0;
          width: 4px;
          right: 0;
          bottom: 0;
        }
      }
      &:hover {
        .hover {
          opacity: 1;
        }
        .border {
          i:first-child {
            height: 25%;
            transition: all 0.2s 0.4s linear;
          }
          i:nth-child(2) {
            width: 100%;
            transition: all 0.2s 0.2s linear;
          }
          i:nth-child(3) {
            height: 100%;
            transition: all 0.2s linear;
            height: 100%;
          }
        }
      }
    }
    > p {
      &:nth-child(2) {
        font-size: 1.4rem;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      &:nth-child(3) {
        font-size: 2rem;
        line-height: 1.5;
        margin-bottom: 50px;
        height: 9rem;
        overflow: hidden;
      }
      &.read-more {
        border-bottom: 2px solid @lineColor;
        display: inline-block;
      }
    }
  }
  .arrows {
    width: 100%;
    margin-top: 100px;
    padding-bottom: 100px;
    text-align: right;
    img {
      cursor: pointer;
      width: 40px;
      &:first-child {
        margin-right: 56px;
      }
      &:last-child {
        margin-right: 240px;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .scroll-tip {
    display: none;
  }
  .border {
    max-width: 30rem;
    max-height: 30rem;
  }
  .c-tit {
    margin-bottom: 8rem;
    padding-left: 4%;
    padding-right: 4%;
  }
  .kv {
    height: 90vh;
    max-height: 145rem;
    overflow: hidden;
    p:first-child {
      span {
        font-size: 7rem;
      }
    }
    video {
      position: absolute;
      right: -132rem;
      height: 100%;
      width: 260rem;
      min-height: 136rem;
    }
    .border {
      left: -3rem;
      right: auto;
      i {
        &:first-child {
          left: auto;
          right: 0;
        }
        &:nth-child(2) {
          left: 0;
          right: auto;
        }
        &:nth-child(3) {
          left: 0;
          right: auto;
        }
      }
    }
  }
  .video {
    background: #ededed;
    flex-direction: column;
    margin: auto;
    padding-bottom: 40rem;
    padding-left: 20px;
    padding-right: 20px;
    .left {
      width: 100%;
      top: 0;
      align-self: flex-start;
      .tit {
        &:after {
          width: 280px;
          left: 3rem;
          top: -40%;
        }
      }
      > p:last-child {
        display: none;
        &:after {
        }
      }
    }
    .right {
      width: 100%;
      align-self: flex-start;
      margin-top: 4rem;
      .video-con {
        &:after {
          right: 12px;
          top: 114px;
        }
        .control {
          img {
            &:last-child {
            }
          }
        }
        > video {
        }
        > p {
          font-size: 2rem;
          width: 90%;
          bottom: -80px;
        }
      }
    }
  }
  .person {
    position: relative;
    background: #183763;
    .tit {
      position: relative;
      font-size: 6rem;
      width: 83%;
      padding-top: 1rem;
      &:after {
        left: -6%;
        top: 10%;
        width: 200px;
      }
    }
    .main {
      flex-direction: column;
      .left {
        width: 100%;
        padding-top: 8rem;
        p {
          position: initial;
          margin: auto;
          font-size: 2rem;
          width: 80%;
        }
      }
      .right {
        width: 100%;
        margin-top: 15rem;
        padding-top: 10rem;
        > p:first-child {
          top: 30px;
          right: 30px;
          font-size: 2rem;
          &:after {
            width: 100%;
          }
        }
        .person {
          background: none;
          overflow: hidden;
          img {
            width: 90%;
            left: 5%;
          }
        }
        > div:last-child {
          top: 30px;
          left: 30px;
          bottom: auto;
          font-size: 1.5rem;
          p:first-child {
            font-size: 3rem;
          }
        }
      }
    }
    .border-parent {
      position: relative;
      margin-top: 10rem;
      left: 10%;
      width: 100%;
    }
  }
  .c-tit {
    &.white {
    }
  }
  .p4 {
    margin-top: 12rem;
    .list {
      .item {
        width: 50%;
        &:after {
        }
        &:last-child {
          border-top:1px solid #d5d8dc;
          &:after {
          }
        }
        &:nth-child(3){
          border-top:1px solid #d5d8dc;
        }
        > div {
          i {
            margin-bottom: 30px;
            width: 30px;
          }
          &:first-child {
            padding-left: 3rem;
            padding-top: 30px;
            height: 35rem;
            > p {
              &:nth-child(4) {
                font-size: 2rem;
                width: 90%;
                height: 10rem;
              }
              &:nth-child(2) {
                margin-bottom: 30px;
                margin-top: 0;
              }
              &:first-child {
                margin-bottom: 0px;
                font-size: 10rem;
                margin-top: 0;
                sup {
                  margin-top: -76px;
                  display: inline-block;
                }
              }
            }
            .border {
              opacity: 1;
              pointer-events: none;
              max-height: 100%;
            }
            img {
              display: none;
            }
          }
        }
        &:last-child {
          i {
            margin-bottom: 30px;
          }
          > div {
            p {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .p5 {
    height: 100vh;
    overflow: hidden;
    .c-tit {
      left: 21px;
      top: 108px;
    }
    > img {
    }
    > ul {
      font-size: 2rem;
      flex-direction: column;
      width: 100%;
      left: 21px;
      top: 170px;
      li {
        width: fit-content;
        margin-bottom: 2rem;
        &.active {
          &:after {
          }
        }
      }
    }
    .mark {
      display: none;
      ul {
        li {
          &:nth-child(2) {
          }
          &:last-child {
          }
        }
      }
    }
    .tip {
      font-size: 1.5rem;
      img {
      }
    }
    .city {
      // TODO 城市和城市滑动
      > div {
        > p {
          &.c2 {
          }
          &.c3 {
          }
        }
        &:first-child {
        }
        &:nth-child(2) {
        }
        &:nth-child(3) {
        }
        &:nth-child(4) {
          p {
          }
        }
        &:nth-child(5) {
        }
        &:nth-child(6) {
        }
        &:nth-child(7) {
        }
        &:nth-child(8) {
        }
        &:nth-child(9) {
        }
      }
    }
  }
  .p6 {
    .c-tit {
      margin-bottom: -4rem;
      span {
        font-size: 2rem;
        margin-right: 2rem;
        font-size: 2.8rem;
        font-weight: bold;
        a {
        }
        &:after {
        }
      }
    }
    .news-list.pc {
      display: none;
    }
    .news-list.wap {
      display: block;
    }
    .news-list {
      padding-bottom: 13rem;
      width: 90%;
      .swiper-container {
        .swiper-wrapper {
          div {
            margin: 0;
          }
        }
      }
    }
    .news-item {
      position: relative;
      padding-bottom: 0.1rem;
      padding-top: 10rem;
      > div {
        > img {
        }
        .hover {
          > img {
          }
        }
        &:hover {
          .hover {
          }
          .border {
          }
        }
      }
      > p {
        font-size: 2rem;
        &:nth-child(2) {
          font-size: 2rem;
        }
        &:nth-child(3) {
          font-size: 3rem;
          height: 13rem;
          margin-bottom: 6rem;
        }
        &.read-more {
        }
      }
    }
    .arrows {
      img {
        &:first-child {
        }
        &:last-child {
        }
      }
    }
  }
}
</style>
