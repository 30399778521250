<template>
  <div class="container">
    <div class="height100"></div>
    <div class="header" :class="{ active: headerScrollClass }" @mouseleave="headerMouse('leave')">
      <div class="common-logo" @click="this.$router.push('/')">
        <div class="inner">
          <div></div>
          <p v-html="$t('slogan')" class="tit"></p>
        </div>
      </div>
      <div class="find-mark" :class="{'active':headerFindMark}"></div>
      <ul>
        <li @mouseenter="headerMouse('leave')">
          <router-link to="/" active-class="current">{{
            $t("header1")
          }}</router-link>
        </li>
        <li @mouseenter="headerMouse('enter','about')">
          <router-link to="/about" active-class="current" class="find" :class="{active:headerFindActive == 'about'}">{{
            $t("header2")
          }}</router-link>
          <div :class="{active:headerFindActive == 'about'}">
            <router-link :to="'/about?anchorpoint=p3&s='+ Math.random()" >{{
              $t("header2_1")
            }}</router-link>
            <router-link :to="'/about?anchorpoint=our-people&s='+ Math.random()" >{{
              $t("header2_2")
            }}</router-link>
            <router-link :to="'/about?anchorpoint=p7&s='+ Math.random()" v-if="$t('header2_4') != 'false'" >{{
              $t("header2_4")
            }}</router-link>
            <router-link :to="'/about?anchorpoint=p5&s='+ Math.random()" >{{
              $t("header2_3")
            }}</router-link>
          </div>
        </li>
        <li @mouseenter="headerMouse('enter','ecosystem')">
          <router-link to="/value" active-class="current" class="find" :class="{active:headerFindActive == 'ecosystem'}">{{
            $t("header3")
          }}</router-link>
          <div :class="{active:headerFindActive == 'ecosystem'}">
            <router-link :to="'/value?anchorpoint=p2&s='+ Math.random()">{{
              $t("header3_1")
            }}</router-link>
            <router-link :to="'/value?anchorpoint=p6&s='+ Math.random()">{{
              $t("header3_2")
            }}</router-link>
          </div>
        </li>
        <li @mouseenter="headerMouse('leave')">
          <router-link to="/news" active-class="current">{{
            $t("header4")
          }}</router-link>
        </li>
        <li @mouseenter="headerMouse('enter','contactUs')">
          <router-link to="/contact" active-class="current" class="find" :class="{active:headerFindActive == 'contactUs'}">{{
            $t("header5")
          }}</router-link>
          <div :class="{active:headerFindActive == 'contactUs'}">
            <router-link :to="'/contact?anchorpoint=p6&s='+ Math.random()">{{
              $t("header5_1")
            }}</router-link>
            <router-link :to="'/contact?anchorpoint=contact-us&s='+ Math.random()">{{
              $t("header5_2")
            }}</router-link>
          </div>
        </li>
      </ul>
      <div class="icons" @click.stop style="margin-right:2rem;">
        <img
          src="./assets/in-icon1.svg"
          alt=""
          @click="
            open(
              'https://www.linkedin.com/company/c-bridge-capital/?viewAsMember=true'
            )
          "
        />
        <img
          src="./assets/wechat-icon1.svg"
          alt=""
          @click="showHeaderQCode = true"
        />
        <div class="qr-code" v-if="showHeaderQCode">
          <img src="./assets/qr-code-10year.jpg" />
        </div>
      </div>
      <div class="lang" @click.stop>
        <label
          >{{ $i18n.locale }} <img src="./assets/drop-down.png" alt=""
        /></label>
        <ul>
          <li @click="changeLang('EN')">EN</li>
          <li @click="changeLang('CN')">CN</li>
        </ul>
      </div>
      <div class="wap-menu" @click="offMenu">
        <p>MENU</p>
      </div>
    </div>
    <div class="wap-menu-list">
      <ul>
        <li>
          <router-link to="/" @click="offMenu" active-class="current">{{
            $t("header1")
          }}</router-link>
        </li>
        <li>
          <router-link to="/about" @click="offMenu" active-class="current">{{
            $t("header2")
          }}</router-link>
          <div class="arrow" :class="{active:headerFindActive == 'about'}" @click="headerMouse('enter','about')"></div>
          <div class="find" :class="{active:headerFindActive == 'about'}">
            <router-link :to="'/about?anchorpoint=p3&s='+ Math.random()"  @click="offMenu">{{
              $t("header2_1")
            }}</router-link>
            <router-link :to="'/about?anchorpoint=our-people&s='+ Math.random()" @click="offMenu" >{{
              $t("header2_2")
            }}</router-link>
            <router-link :to="'/about?anchorpoint=p7&s='+ Math.random()" @click="offMenu"  v-if="$t('header2_4') != 'false'" >{{
              $t("header2_4")
            }}</router-link>
            <router-link :to="'/about?anchorpoint=p5&s='+ Math.random()" @click="offMenu" >{{
              $t("header2_3")
            }}</router-link>
          </div>
        </li>
        <li>
          <router-link to="/value" @click="offMenu" active-class="current">{{
            $t("header3")
          }}</router-link>
          <div class="arrow" :class="{active:headerFindActive == 'ecosystem'}" @click="headerMouse('enter','ecosystem')"></div>
          <div class="find" :class="{active:headerFindActive == 'ecosystem'}">
            <router-link :to="'/value?anchorpoint=p2&s='+ Math.random()" @click="offMenu">{{
              $t("header3_1")
            }}</router-link>
            <router-link :to="'/value?anchorpoint=p6&s='+ Math.random()" @click="offMenu">{{
              $t("header3_2")
            }}</router-link>
          </div>
        </li>
        <li>
          <router-link to="/news" @click="offMenu" active-class="current">{{
            $t("header4")
          }}</router-link>
        </li>
        <li>
          <router-link to="/contact" @click="offMenu" active-class="current">{{
            $t("header5")
          }}</router-link>
          <div class="arrow" :class="{active:headerFindActive == 'contactUs'}" @click="headerMouse('enter','contactUs')"></div>
          <div class="find" :class="{active:headerFindActive == 'contactUs'}">
            <router-link :to="'/contact?anchorpoint=p6&s='+ Math.random()" @click="offMenu">{{
              $t("header5_1")
            }}</router-link>
            <router-link :to="'/contact?anchorpoint=contact-us&s='+ Math.random()" @click="offMenu">{{
              $t("header5_2")
            }}</router-link>
          </div>
        </li>
      </ul>
      <div class="icons pc" @click.stop >
        <img
          src="./assets/in-icon1.png"
          alt=""
          @click="
            open(
              'https://www.linkedin.com/company/c-bridge-capital/?viewAsMember=true'
            )
          "
        />
        <img
          src="./assets/wechat-icon1.png"
          alt=""
          @click="showHeaderQCode = true"
        />
        <div class="qr-code" v-if="showHeaderQCode">
          <img src="./assets/qr-code.jpg" />
        </div>
      </div>
    </div>
    <div class="section" v-if="hasRouterView">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div class="top">
        <div class="common-logo">
          <div class="inner">
            <div><img src="./assets/logo-10year.png" alt="" /></div>
            <p v-html="$t('slogan')" class="tit"></p>
          </div>
        </div>
        <ul class="">
          <li>
            <router-link to="/about">{{ $t("header2") }}</router-link>
          </li>
          <li>
            <router-link to="/value">{{ $t("header3") }}</router-link>
          </li>
          <li>
            <router-link to="/news">{{ $t("header4") }}</router-link>
          </li>
          <li>
            <router-link to="/contact">{{ $t("header5") }}</router-link>
          </li>
        </ul>
        <ul class="">
          <li class="title">{{ $t("footContactUs") }}</li>
          <li>info@cbridgecap.com</li>
          <li>www.cbridgecap.com</li>
          <li @click.stop>
            <img
              src="./assets/in-icon.svg"
              alt=""
              @click="
                open(
                  'https://www.linkedin.com/company/c-bridge-capital/?viewAsMember=true'
                )
              "
            />
            <img
              src="./assets/wechat-icon.svg"
              alt=""
              @click="showFooterQCode = true"
            />
            <div class="qr-code" v-if="showFooterQCode">
              <img src="./assets/qr-code.jpg" />
            </div>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <p>© 2022 CBC Group. All Rights Reserved.</p>
        <p @click="goBack">
          <span>
            {{ $t("toTop") }}<img src="./assets/to-bottom-arrow.png" alt="" />
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import $ from "jquery";
import {getKv} from './api/common'
export default defineComponent({
  name: "App",
  data() {
    return {
      headerScrollClass: false,
      showHeaderQCode: false,
      showFooterQCode: false,
      hasRouterView:false,
      headerFindMark:false,
      headerFindActive:null,
    };
  },
  watch: {
    $route() {
      // 回到顶部
      if(this.$route.query.anchorpoint == undefined){
        window.scrollTo(0, 0);
        $(".animated").removeClass("animated");
      }
    },
  },
  mounted() {
    let _this = this;
    this.handleClick();
    _this.size();
    setTimeout(function () {
      _this.controlScrollHeader();
      _this.size();
      window.onresize = function () {
        _this.size();
      };
    }, 1000);

    document.addEventListener("click", this.bodyCloseMenus);
    $("body").addClass(this.$store.state.lang);
    this.getKv();
  },
  methods: {
    headerMouse(e,active){
      console.log(e,active)
      if(e == 'enter' && (active !== this.headerFindActive || window.innerWidth > 960)){
        if(this.headerFindMark && active !== this.headerFindActive){
          this.headerFindMark = false;
          this.headerFindActive = null;
          setTimeout(() => {
            this.headerFindMark = true;
            this.headerFindActive = active;
          },300)
        }else{
          this.headerFindMark = true;
          this.headerFindActive = active;
        }
      }else{
        this.headerFindMark = false;
        this.headerFindActive = null;
      }
    },
    bodyCloseMenus() {
      let self = this;
      if (self.showHeaderQCode == true) {
        self.showHeaderQCode = false;
      }
      if (self.showFooterQCode == true) {
        self.showFooterQCode = false;
      }
      $(".lang").removeClass("open");
    },
    beforeDestroy() {
      document.removeEventListener("click", this.bodyCloseMenus);
    },
    open(e) {
      window.open(e);
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.$store.state.lang = lang;
      window.localStorage.setItem("language", lang);
      window.location.reload()
    },
    handleClick() {
      $(".lang label").click(function () {
        if ($(".lang").hasClass("open")) {
          $(".lang").removeClass("open");
        } else {
          $(".lang").addClass("open");
        }
      });
    },
    offMenu() {
      if ($(".wap-menu-list").hasClass("open")) {
        $(".wap-menu-list").removeClass("open");
      } else {
        $(".wap-menu-list").addClass("open");
      }
    },
    controlScrollHeader() {
      window.onscroll = () => {
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        if (t !== 0) {
          this.headerScrollClass = true;
        } else {
          this.headerScrollClass = false;
        }
      };
    },
    size() {
      if (window.innerWidth < 690) {
        $("html").css({ "font-size": innerWidth * 0.01333 + "px" });
      } else {
        $("html").css({ "font-size": innerWidth * 0.0052 + "px" });
      }
      this.$store.state.isWap = innerWidth < 960;
    },
    goBack() {
      $("html,body").animate({ scrollTop: 0 }, 500);
    },
    getKv(){
      getKv().then(res=>{
        this.$store.state.kvData = res.info
        this.hasRouterView = true;
      })
    }
  },
});
</script>

<style lang="less">
@import "./style";
.container {
  width: 100%;
  height: 100%;
  .height100 {
    height: 10rem;
  }
  .common-logo {
    width: 26.5rem;
    height: 12rem;
    background-color: @baseBgColor;
    z-index: 2;
    margin-left: 4rem;
    transition: all 0.4s;
    cursor: pointer;
    div.inner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      color: #fff;
      transition: all 0.4s;
      > div {
        // width: 7.5rem;
        width:18.5rem;
        height: 7.5rem;
        align-self: center;
        margin-right: 2.5rem;
        // background-image: url("./assets/logo-10year.png");
        background-image: url("./assets/logo-10year.png");
        background-size: 100%;
        background-repeat: no-repeat;
        transition: all 0.4s;
        img {
          width: 100%;
          transition: all 0.4s;
        }
      }
      > p {
        font-size: 1.6rem;
        align-self: center;
        letter-spacing: 1px;
        // 10 year
        display: none;
      }
    }
  }
  .header {
    width: 100%;
    height: 10rem;
    background: url("./assets/top-header-bg.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    &.active {
      background-color: rgba(255, 255, 255, 1);
      background-image: none;
      &:after{
        content:'';
        display:block;
        position:absolute;
        bottom:0;
        width:100%;
        left:0;      
        border-bottom:1px solid #d5d8dc;

      }
      .common-logo {
        background: rgba(255, 255, 255, 0);
        color: #000000;
        div.inner {
          color: #000000;
          > div {
            // width: 6rem;
            // height: 6rem;
            width: 16rem;
            height: 8rem;
            // background-image: url("./assets/logo2.png");
            background-image: url("./assets/logo2-10year.png");
          }
        }
      }
    }
    .find-mark{
      top:10rem;
      height:8rem;
      background:#ffffff;
      width:100%;
      position:absolute;
      height:0;
      transition:all 0.5s;
      &.active{
        height:8rem;
      }
    }
    ul {
      width: 40%;
      list-style: none;
      display: flex;
      justify-content: space-between;
      margin-left: 20%;
      position:relative;
      li {
        align-self: center;
        >a {
          text-decoration: none;
          color: @baseTextColor;
          position: relative;
          font-weight: bold;
          line-height: 10rem;
          display: block;
          padding-right:3rem;
          &:after {
            content: "";
            display: block;
            width: 0;
            height: 3px;
            background-color: #04dccb;
            position: absolute;
            bottom: 0rem;
            left: calc(50% - 1.5rem);
            transform: translateX(-50%);
            transition: all 0.2s;
            z-index: 111;
          }
          &.find{
            &:before{
              content:'';
              display:block;
              width:0.5rem;
              height:0.5rem;
              border-right:2px solid #002742;
              border-top:2px solid #002742;
              position:absolute;
              right:1rem;
              top:-0.5rem;
              bottom:0;
              margin:auto;
              transform:rotate(135deg);
              transition:all 0.5s;
              pointer-events: none;
             }
             &.active{
              &:before{
                top:0rem;
                transform:rotate(-45deg) translatey(0.4rem);
                border-color:#04dccb;
              }
             }
          }
          &.current {
            &:after {
              width: calc(90% - 1.5rem);
            }
          }
        }
        >div{
          position: absolute;
          left:0;
          top:8.5rem;
          width:100%;;
          height:8rem;
          line-height:8rem;
          display:flex;
          height:0;
          overflow: hidden;;
          transition:all 0.5s;
          >a{
            margin-right:8%;
            font-weight: bold;
          }
          &.active{
            height:8rem;
          }
        }
      }
    }
    .icons {
      display: flex;
      margin-left: 5%;
      position: relative;
      img {
        width: 3rem;
        align-self: center;
        &:first-child {
          margin-right: 20px;
        }
      }
      .qr-code {
        position: absolute;
        width: 30rem;
        top: 10rem;
        left: 50%;
        margin-left: -12rem;
        img {
          width: 100%;
        }
      }
    }
    .lang {
      width: 10%;
      display: flex;
      position: relative;
      ul {
        position: absolute;
        display: block;
        top: 70%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        padding-left: 0;
        margin: 0;
        height: 0;
        overflow: hidden;
        transition: all 0.2s;
        background-color: #f8f8f8;
        z-index: 2;
        li {
          line-height: 3rem;
          width: 50%;
          margin: auto;
          cursor: default;
          &:first-child {
            border-bottom: 1px solid rgba(4, 220, 203, 0.25);
          }
        }
      }

      label {
        width: 100%;
        align-self: center;
        text-align: center;
        img {
          transition: all 0.2s;
        }
      }
      &.open {
        ul {
          height: 6rem;
        }
        label {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
    .wap-menu {
      align-self: center;
      display: none;
    }
  }
  .wap-menu-list {
    display: none;
  }
  .footer {
    background-color: #132847;
    font-size: 1.4rem;
    color: #fff;
    .common-logo {
      background-color: #132847;
      margin-left:0rem; 
    }
    .top {
      display: flex;
      padding-top: 90px;
      padding-bottom: 90px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      ul {
        list-style: none;
        .title {
          font-size: 1.7rem;
        }
        &:nth-child(2) {
          padding-left: calc(50% - 270px);
        }
        &:last-child {
          padding-left: 10%;
          li {
            &:last-child {
              img {
                width: 4rem;
              }
              img:first-child {
                margin-right: 10px;
              }
            }
          }
        }
        li {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
            position: relative;
            .qr-code {
              position: absolute;
              width: 25rem;
              bottom: 8rem;
              left: 50%;
              margin-left: -10rem;
              img {
                width: 100%;
              }
            }
          }
        }
        a {
          color: #fff;
          transition: all 0.5s;
          &:hover {
            color: #04dccb;
          }
        }
      }
    }
    .bottom {
      padding: 35px;
      display: flex;
      p {
        width: 50%;
        &:last-child {
          span {
            display: inline-block;
            width: fit-content;
            cursor: pointer;
          }
          img {
            height: 1.125rem;
            transform: rotate(180deg);
            cursor: pointer;
            position: relative;
            top: 2px;
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .com-content {
    background: #ededed;
  }
}
.right-line,
.left-line {
  &.img-line {
    width: 80%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    > img {
      width: 100%;
    }
    &:after {
      width: 65%;
      top: -15%;
      z-index: 0;
      left: 45%;
    }
  }
}
.left-line {
  &:after {
    background-image: url("./assets/line-left.png");
    left: -5%;
  }
}

.read-more {
  position: absolute;
  font-size: 1.7rem;
  bottom: 0;
  left: 0;
  color: @baseTextColor;
  cursor:pointer;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #04dccb;
    position: absolute;
    bottom: 0;
  }
}
.common-tit {
  font-size: 7.2rem;
}
.border-parent {
  display: inline-block;
  position: relative;
}
.border {
  max-width: 25rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -4rem;
  right: -4rem;
  pointer-events: none;
  z-index: 30;
  i {
    display: block;
    background: @lineColor;
    position: absolute;
    &:first-child {
      height: 0;
      width: 4px;
      top: 0;
      left: 0;
    }
    &:nth-child(2) {
      width: 0;
      height: 4px;
      top: 0;
      right: 0;
    }
    &:nth-child(3) {
      height: 0;
      width: 4px;
      right: 0;
      bottom: 0;
    }
  }
  &.border-left {
    left: -3rem;
    right: auto;
    i {
      &:first-child {
        left: auto;
        right: 0;
      }
      &:nth-child(2) {
        left: 0;
        right: auto;
      }
      &:nth-child(3) {
        left: 0;
        right: auto;
      }
    }
  }
  &.fixed {
    max-width: 100%;
    left: 0;
    right: 0;
    top: 0;
    i:first-child {
      height: 0;
      width: 4px;
      top: 0;
      left: 0;
    }
    i:nth-child(2) {
      width: 0;
      height: 4px;
      top: 0;
      right: 0;
    }
    i:nth-child(3) {
      height: 0;
      width: 4px;
      right: 0;
      bottom: 0;
    }
    &.active {
      i:first-child {
        height: 20%;
        transition: all 0.2s 0.4s linear;
      }
      i:nth-child(2) {
        width: 100%;
        transition: all 0.2s 0.2s linear;
      }
      i:nth-child(3) {
        height: 100%;
        transition: all 0.2s linear;
      }
    }
  }
}

.lineAnimation {
}

.common-desc {
  background: #ededed;
  padding-top: 16rem;
  padding-bottom: 16rem;
  > .inner {
    display: flex;
    width: 65%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
  }
  .left {
    position: relative;
    width: 48%;
    > img {
      width: 90%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
    .right-line {
      > img {
        z-index: 2;
        position: relative;
      }
    }
    &:after {
      top: 0;
      left: 48%;
      width: 50%;
      z-index: 0;
    }
  }
  .right {
    width: 48%;
    .tit {
      font-size: 4.8rem;
      margin-bottom: 8rem;
    }
    i {
      display: block;
      width: 4rem;
      height: 2px;
      background: @baseTextColor;
      margin-bottom: 50px;
    }
    .details {
      height: 17rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        background: rgb(220, 220, 220);
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: @lineColor;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #333;
      }
      &::-webkit-scrollbar-corner {
        background: #179a16;
      }
      p {
        line-height: 1.75;
        font-size: 1.4rem;
        margin-bottom: 50px;
        padding-right: 20px;
      }
    }
  }
  .border {
    width: 22rem;
    height: 18rem;
    i:nth-child(1) {
      width: 4px;
    }
    i:nth-child(2) {
      height: 4px;
    }
    i:nth-child(3) {
      width: 4px;
    }
  }
}
.carl {
  position: relative;
  .tit {
    cursor: pointer;
    position: relative;
    z-index: 20;
  }
  > img {
    position: absolute;
    right: 20px;
    transition: all 0.5s;
    opacity: 1;
    z-index: 9;
    &:first-child {
      top: 3.5rem;
      opacity: 1;
    }
    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
    }
  }
  > p {
    padding-left: 1rem;
    &:nth-child(3) {
      font-size: 3.6rem;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      border-top: 2px solid @baseTextColor;
    }
    &:nth-child(4) {
      font-size: 1.4rem;
      width: 55%;
      line-height: 2;
      transition: all 0.5s;
      overflow: hidden;
      height: 0;
      display: block;
      &:after {
        content: "";
        display: block;
        height: 2rem;
      }
    }
  }
  &:last-child {
    > p {
      &:nth-child(3) {
        border-bottom: 2px solid @baseTextColor;
      }
    }
  }
  &.open {
    > img {
      &:first-child {
        opacity: 0;
      }
      &:nth-child(2) {
        opacity: 1;
      }
    }
    > p {
      &:nth-child(3) {
        border-bottom: 0;
      }
      &:nth-child(4) {
        height: auto;
      }
    }
    .border {
      opacity: 1;
      i:first-child {
        height: 25%;
        transition: all 0.2s 0.4s linear;
      }
      i:nth-child(2) {
        width: 100%;
        transition: all 0.2s 0.2s linear;
      }
      i:nth-child(3) {
        height: 100%;
        transition: all 0.2s linear;
      }
    }
  }
}
.pagePoint {
  display: flex;
  list-style: none;
  margin-top: 5rem;
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  li {
    width: 10px;
    height: 2px;
    background: #a8b2bf;
    margin-right: 7px;
    &.active {
      width: 20px;
      background: #04dccb;
    }
  }
}
@media screen and (max-width: 960px) {
  .container {
    .common-logo {
      width: 30rem;
      height: 70px;
      div.inner {
        > p {
          font-size: 1.6rem;
        }
      }
    }
    .height100 {
      height: 60px;
    }
    .header {
      height: 60px;
      width: calc(100% - 24px);
      right: 0;
      margin: auto;
      background-position: left;
      .find-mark{
        display:none;
      }
      > ul {
        display: none;
      }
      .icons {
        display: none;
      }
      .lang {
        padding-left: 28%;
        width: 15%;
        font-size: 2.5rem;
        ul {
          top: 74%;
          left: 79%;
          li{
            line-height: 4.8rem;
          }
        }
        label {
          > img {
            top: 0;
            width: 1.9rem;
          }
        }
        &.open{
          ul{
            height:10rem;
          }
        }
      }
      .wap-menu {
        display: block;
        margin-right: 10px;
        cursor: default;
        font-size: 2rem;
        &:before,
        &:after {
          content: "";
          display: block;
          height: 2px;
          background: #002742;
        }
      }
    }
    .wap-menu-list {
      width: 100%;
      position: fixed;
      background-color: #fff;
      transition: all 0.2s;
      padding-left: 2%;
      padding-right: 50px;
      box-sizing: border-box;
      height: 0;
      overflow: hidden;
      &.open {
        height: calc(100vh - 50px);
        display: block;
        z-index: 80;
      }
      ul {
        font-size: 2.8rem;
        margin-top: 10rem;
        li {
          border-top:1px solid #e5e8ec;
          padding-left:3rem;
          position:relative;
          &:nth-child(2),&:nth-child(3),&:nth-child(5){
            .arrow{
              width:10rem;
              height:10rem;
              position:absolute;
              right:0;
              top:0;
              &.active{
                &:before{
                  transform:rotate(-135deg);
                  border-color:#04dccb;
                }
              }
              &:before{
                content:'';
                display:block;
                width:1.4rem;
                height:1.4rem;
                position: absolute;
                border-right:1px solid #002742;
                border-bottom:1px solid #002742;
                right:1rem;
                top:5rem;
                transform:rotate(45deg);
                transition:all 0.4s;
              }
            }
          }
          &:first-child {
            border:0;
          }
          >.find{
            padding-left:6rem;
            overflow:hidden;
            max-height:0;
            transition:all 0.4s;
            a{
              padding-top:3rem;
              padding-bottom:3rem;
            }
            &.active{
              max-height:60rem;
            }
          }
          
        }
        a {
          text-decoration: none;
          color: @baseTextColor;
          position: relative;
          padding:4rem 0;
          display:block;
          font-weight:bold;
          &:after {
            content: "";
            display: none;
            width: 0;
            height: 2px;
            background-color: @lineColor;
            position: absolute;
            bottom: -15px;
            transition: all 0.2s;
          }
          &.current {
            &:after {
              width: 100%;
            }
          }
        }
      }
      .icons {
        position: absolute;
        bottom: 100px;
        width: calc(100% - 100px);
        padding-top: 50px;
        border-top: 1px solid @baseBgColor;
        img {
          margin-right: 30px;
        }
        .qr-code {
          position: absolute;
          width: 26rem;
          bottom: 8rem;
          > img {
            width: 100%;
          }
        }
      }
    }
    .footer {
      .top,
      .bottom {
        flex-wrap: wrap;
        padding-top: 10rem;
        padding-bottom: 10rem;
      }
      .common-logo {
        padding-bottom: 5rem;
        padding-left: 1.5rem;
        width: 38rem;
        height: 90px;
        .inner {
          justify-content:space-between;
          > div {
            width: 15rem;
          }
          > p {
            font-size: 4rem;
            padding-top: 7rem;
          }
        }
      }
      .top {
        ul {
          &:nth-child(2),
          &:last-child {
            width: 100%;
            padding-left: 3.5rem;
            font-size: 3rem;
            font-weight: bold;
            margin-top:4rem;
            .title {
              font-size: 3rem;
            }
            li:last-child {
              > img {
                width: 6rem;
              }
              .qr-code {
                left: 15%;
              }
            }
          }
          &:last-child {
            li:last-child{
              margin-top:6rem;
            }
          }
        }
      }
      .bottom {
        font-size: 2rem;
        padding-bottom: 5rem;
        padding-top: 5rem;
        padding-left:3.5rem;
        p {
          width: 100%;
          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  .common-desc {
    flex-direction: column;
    width: 100%;
    padding-bottom: 10rem;
    .border {
      width: 30rem;
      height: 30rem;
      z-index: 1;
    }
    .inner {
      width: 100%;
    }
    .right-line.img-line,
    .left-line.img-line {
      top: 0;
      transform: translateY(0);
      position: relative;
    }
    .left {
      width: 100%;
      > img {
      }
      &:after {
      }
    }
    .right {
      width: 80%;
      margin: auto;
      margin-top: 8rem;
      .tit {
        margin-bottom:4rem;
      }
      i {
        margin-bottom:4rem;
      }
      .details {
        overflow: auto;
        height: auto;
        p {
          font-size: 2.5rem;
          padding: 0;
          margin-bottom:2rem;
        }
        &::-webkit-scrollbar {
        }
        &::-webkit-scrollbar-track {
        }
        &::-webkit-scrollbar-thumb {
        }
        &::-webkit-scrollbar-thumb:hover {
        }
        &::-webkit-scrollbar-corner {
        }
        p {
        }
      }
    }
  }

  .carl {
    > img {
      &:first-child {
        top: 23px;
      }
      &:nth-child(2) {
      }
    }
    > p {
      &:nth-child(3) {
        padding-right: 20%;
        margin-left: 0;
        font-size: 4rem;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      &:nth-child(4) {
        font-size: 2rem;
        width: 80%;
        margin-left: 10px;
      }
    }
    &:last-child {
      > p {
        &:nth-child(3) {
        }
      }
    }
    &.open {
      > img {
        &:first-child {
        }
        &:nth-child(2) {
        }
      }
      > p {
        &:nth-child(3) {
        }
        &:nth-child(4) {
        }
      }
      .border {
      }
    }
  }
  .read-more {
    font-size: 2.3rem;
  }
  .border {
    max-width: 30rem;
    i {
      &:first-child {
      }
      &:nth-child(2) {
      }
      &:nth-child(3) {
      }
    }
    &.border-left {
      i {
        &:first-child {
        }
        &:nth-child(2) {
        }
        &:nth-child(3) {
        }
      }
    }
    &.fixed {
      i:first-child {
      }
      i:nth-child(2) {
      }
      i:nth-child(3) {
      }
      &.active {
        i:first-child {
        }
        i:nth-child(2) {
        }
        i:nth-child(3) {
        }
      }
    }
  }
}


// 动画

@keyframes slideInUps {
  0% {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes CNslideInUps {
  0% {
    -webkit-transform: translate3d(0, 100px, 0) scale(0.85, 1);
    transform: translate3d(0, 100px, 0) scale(0.85, 1);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0) scale(0.85, 1);
    transform: translateZ(0) scale(0.85, 1);
    opacity: 1;
  }
}

.animate__slideInUps {
  -webkit-animation-name: slideInUps;
  animation-name: slideInUps;
}

.CN .animate__slideInUps.tit {
  -webkit-animation-name: CNslideInUps;
  animation-name: CNslideInUps;
}

@keyframes animate__line_i1 {
  0% {
    height: 0;
  }
  to {
    height: 25%;
  }
}
@keyframes animate__line_i2 {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes animate__line_i3 {
  0% {
    height: 0;
  }
  to {
    height: 100%;
  }
}
.animate__line.animated {
  i:nth-child(1) {
    height: 18%;
    transition: 0.5s 1.5s linear;
  }
  i:nth-child(2) {
    width: 100%;
    transition: 0.5s 1s linear;
  }
  i:nth-child(3) {
    height: 100%;
    transition: 0.5s 0.5s linear;
  }
}
.new-detaill .news-content .right > div {
    margin-top: 3rem;
    line-height:4.5rem;
    word-break:break-word;;
        sup{
          font-size:1.2rem;
        }
  p ,li{
    margin-top: 3rem;
    line-height:4.5rem;
    word-break: break-word;;
    strong,b{
      font-weight:bold;
    }
  }
  img,video{
    max-width:100%;
    pointer-events:none;
  }
}
.p3 .top .left span p {
  line-height:3rem;
  p{
    margin-top:4rem;
  }
}
.new-detaill .news-content .right > div{
          overflow-y:scroll;
          -webkit-overflow-scrolling:touch;
          &::-webkit-scrollbar {
            display:block;
            width: 2px;
            background: hsla(0, 0%, 84.7%, 0.5);
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #04dccb;
          }
}

.CN{
  .common-desc .right .details p{
    text-indent:3rem;
  }
  .p3 .top .left span p{
    text-indent:3rem;
  }
  .new-detaill .news-content .right > div{
    text-indent:4rem;
    P{
      text-indent:4rem;
    }
  }
}
// .swiper-grid .swiper-wrapper{flex-wrap:wrap}
// .swiper-grid-column .swiper-wrapper{flex-wrap:wrap;flex-direction:column}
</style>
