<template>
  <div class="com-content value-page">
    <kv :slogan="2"></kv>
    <!-- 生态介绍 价值 -->
    <div class="p2 common-desc">
      <div class="inner">
        <div class="left">
          <div class="right-line img-line">
            <span class="border wow animate__animated animate__line">
              <i class="animate__animated"></i>
              <i class="animate__animated"></i>
              <i class="animate__animated"></i>
            </span>
            <img
              :src="data.introduce.imgurl"
              class="wow animate__animated animate__slideInUps"
              alt=""
            />
          </div>
        </div>
        <div class="right">
          <p
            class="tit wow animate__animated animate__slideInUps"
            v-html="data.introduce.title"
          ></p>
          <i></i>
          <div class="details wow animate__animated animate__slideInUps" v-html="data.introduce.content">
          </div>
        </div>
      </div>
    </div>
    <!-- 生态内容 -->
    <div v-for="(item,index) in data.valueList" v-bind:key="index"  :class="index % 2 ? 'p4' : 'p3'">
      <p
        class="tit tit-h2 wow animate__animated animate__slideInUps"
        v-html="item.title"
      ></p>
      <div>
        <div class="right wow animate__animated animate__slideInUps">
          <img :src="item.imgUrl" alt="" />
        </div>
        <div class="left">
          <p class="tit wow animate__animated animate__slideInUps">{{ index + 1 }}</p>
          <p class="wow animate__animated animate__slideInUps">
            <i class="wow animate__animated animate__slideInUps"></i>

            {{item.content}}
          </p>
          <div class="border active border-left fixed">
            <i></i><i></i><i></i>
          </div>
        </div>
      </div>
    </div>
    <!-- logo -->
    <div class="p6">
      <p class="tit wow animate__animated animate__slideInUps">
        {{ $t("p4Tit5") }}
      </p>
      <div class="arrows wow animate__animated animate__slideInUps" v-if="!$store.state.isWap">
        <img
          src="../assets/left-arrow.svg"
          :style="swiperPrevDisabled ? disabledStyle : ''"
          alt=""
          @click="prev"
        />
        <img
          src="../assets/right-arrow.svg"
          :style="swiperNextDisabled ? disabledStyle : ''"
          alt=""
          @click="next"
        />
      </div>
      <div class="con">
        <div class="menu wow animate__animated animate__slideInUps">
          <p @click="changeList('biopharmaList', 1)">
            <span :class="showList === 1 ? 'active' : ''">{{
              $t("p4Tit6")
            }}</span>
          </p>
          <p @click="changeList('medTech', 2)">
            <span :class="showList === 2 ? 'active' : ''">{{
              $t("p4Tit7")
            }}</span>
          </p>
          <p @click="changeList('medicalServices', 3)">
            <span :class="showList === 3 ? 'active' : ''">{{
              $t("p4Tit8")
            }}</span>
          </p>
          <!-- <span><img src="../assets/white-drap.png" alt="" /></span> -->
        </div>
        <div class="list list1">
          <swiper
            :slides-per-view="$store.state.isWap ? 1 : 3"
            :space-between="35"
            @swiper="onSwiper"
            @slide-change-transition-end="swiperChange">
          >
            <template v-for="(item, index) in ecosystemItem" v-bind:key="index">
              <swiper-slide v-if="index % 2 == 0">
                <div class="item wow animate__animated animate__slideInUps">
                  <img :src="item.imgUrl" alt="" />
                  <div class="border fixed"><i></i><i></i><i></i></div>
                  <div class="desc">
                    <p>
                      {{ item.info }}
                    </p>
                  </div>
                </div>

                <div
                  class="item wow animate__animated animate__slideInUps"
                  v-if="ecosystemItem[index + 1]"
                >
                  <img :src="ecosystemItem[index + 1].imgUrl" alt="" />
                  <div class="border fixed"><i></i><i></i><i></i></div>
                  <div class="desc">
                    <p>
                      {{ ecosystemItem[index + 1].info }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </template>
          </swiper>
        </div>
      <div class="pagePoint" v-if="$store.state.isWap">
        <template v-for="(item,index) in ecosystemItem" v-bind:key="index">
          <li :class="{active:swiper.activeIndex==index / 2}" v-if="index%2 == 0"></li>
        </template>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Kv from "@/components/element/Kv";
import { Swiper, SwiperSlide } from "swiper/vue";
import wow from "wowjs";
import "swiper/swiper.less";

import $ from "jquery";
import {getEcology} from '../api/common'
export default defineComponent({
  name: "Value",
  components: { Kv, Swiper, SwiperSlide },
  data() {
    return {
      data:{
        introduce:{},
        valueList:[],
        logoList:{
          biopharmaList:[],
          MedTech:[],
          medicalServices:[],
        }
      },
      timeRange: [],
      showList: 1,
      swiper: "",
      disabledStyle: { opacity: 0.5,'pointer-events':'none' },
      swiperPrevDisabled: true,
      swiperNextDisabled: false,
      ecosystemData: {
        biopharmaList: [
          
        ],
        medTech: [
          
        ],
        medicalServices: [
          
        ],
      },
      ecosystemItem: [],
      langs: "",
    };
  },
  watch: {
    $route() {
      if(this.$route.query.anchorpoint && $("."+this.$route.query.anchorpoint).length != 0 )
        $("html,body").animate({scrollTop:$("."+this.$route.query.anchorpoint).offset().top - 50}, 800); 
    },
  },
  created() {
    this.changeList("Biopharma", 1);
  },
  mounted() {
    setTimeout(() =>{
      if(this.$route.query.anchorpoint && $("."+this.$route.query.anchorpoint).offset().top )
        $("html,body").animate({scrollTop:$("."+this.$route.query.anchorpoint).offset().top - 50}, 800); 
    },400)
    new wow.WOW({
      boxClass: "wow", //需要执行动画的元素的 class
      animateClass: "animated", //animation.css 动画的 class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备上执行动画
      live: true, //异步加载的内容是否有效
    }).init();
    this.langs = this.$store.state.lang;
    this.getEcology()
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    swiperChange(e){

      this.swiperNextDisabled = false;
      this.swiperPrevDisabled = false;
      if (e.activeIndex === 0) this.swiperPrevDisabled = true;
      if (
        e.activeIndex ===
        parseInt((this.ecosystemItem.length - 4) / 2)
      )
        this.swiperNextDisabled = true;
    },
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      // TODO 如果这里根据内容改变了swiper的个数，手动判断改一下Index
      // 如果三个swiper冲突了，可以重新定义两个，分别赋值。要是接入接口数据，更方便，在切换列表时直接替换swiper内的内容，不需要切换div了
      this.swiper.slideNext();
    },
    changeList(n, index) {
      this.ecosystemItem = this.ecosystemData[n];
      console.log(this.ecosystemData)
      this.showList = 0;
      this.swiperPrevDisabled = true;

      this.swiperNextDisabled = false;

      setTimeout(() => {
        this.showList = index;
      });
    },
    getEcology(){
      getEcology().then(res => {
        this.data = res.info;
        this.ecosystemData = this.data.logoList
        
          this.changeList('biopharmaList', 1)
        setTimeout( () => {
            this.swiper.update()
          },400)
      })
    }
  },
});
</script>

<style scoped lang="less">
@import "../style";
.arrows {
  display: inline-block;
  img {
    width: 40px;
    cursor:pointer;
    &:first-child {
      margin-right: 50px;
      //opacity: 0.5;
    }
    &:last-child {
      margin-right: 200px;
    }
  }
}
.p3,
.p4,
.p5 {
  width: 100%;
  margin-top: 11.25rem;
  position: relative;
  > p {
    color: #fff;
    position: absolute;
    top: 38%;
    left: 25%;
    z-index: 2;
    font-size: 7.2rem;
  }
  > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .left {
      width: 40%;
      color: #fff;
      background: @baseBgColor;
      position: absolute;
      height: calc(100% - 120px);
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      .border {
        opacity: 1;
        i:nth-child(1) {
          right: 0;
          left: auto;
        }
      }
      > p {
        width: 70%;
        margin-left: 3rem;
        &:first-child {
          font-size: 12rem;
          margin-top: 5.5rem;
          margin-bottom: 17.5rem;
        }
        &:nth-child(2) {
          font-size: 1.4rem;
          line-height: 2;
          position: absolute;
          bottom: 7rem;
          > i {
            width: 4rem;
            height: 2px;
            background: #fff;
            display: block;
            margin-bottom: 2.5rem;
          }
        }
      }
    }
    .right {
      width: 60%;
      margin-left: 40%;
      > img {
        width: 100%;
      }
    }
  }
}
.p4 {
  > p {
    left: 48%;
  }
  > div {
    flex-direction: row-reverse;
    .left {
      left: auto;
      right: 0;
      .border {
        i:nth-child(1) {
          left: 0;
          right: auto;
        }
      }
    }
    .right {
      margin-left: 0;
      margin-right: 40%;
    }
  }
}
.p6 {
  background: #e7e7e7;
  padding: 6rem 3rem;
  margin-top: 11.25rem;
  position: relative;
  .arrows {
    position: absolute;
    right: 0;
    top: 6.5rem;
  }
  > p {
    font-size: 7.2rem;
  }
  .con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4rem;
    .menu {
      display: flex;
      flex-direction: column;
      width: 15%;
      position: relative;
      > p {
        margin-bottom: 1.5rem;
        cursor: pointer;
        > span {
          font-size: 1.7rem;
          position: relative;
          transition: all 0.5s;
          &:after {
            content: "";
            display: block;
            width: 0;
            height: 2px;
            background: @lineColor;
            position: absolute;
            border: -5px;
            left: 0;
          }
          &.active {
            &:after {
              width: 100%;
            }
          }
        }
      }
      > span {
        position: absolute;
        bottom: 5%;
        left: 0;
      }
    }
    .list {
      width: 85%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &:hover {
        cursor: url("../assets/white-drap.png"), auto;
      }
      .item {
        width: 100%;
        height: calc(28rem);
        position: relative;
        margin-bottom: 5%;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
          overflow:hidden;
        > img {
          width: 100%;
          filter: grayscale(1);
          transition: all 0.5s;
          transform: scale(0.7);
        }
        .desc {
          background: @baseBgColor;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          overflow: hidden;
          transition: all 0.5s;
          height: 0;
          box-sizing: border-box;
          padding: 0 1.5rem;
          opacity:0;
          bottom:-5rem;
          p {
            color: #fff;
            font-size: 1.4rem;
            line-height: 1.5;
            padding-top: 1.5rem;
          }
        }
        &:hover {
          > img {
            filter: none;
          }
          .desc {
              opacity:1;
              height:fit-content;
              padding-bottom:1rem;
              bottom:0rem;
          }
          .border {
            opacity: 1;
            i:first-child {
              height: 25%;
              transition: all 0.2s 0.4s linear;
            }
            i:nth-child(2) {
              width: 100%;
              transition: all 0.2s 0.2s linear;
            }
            i:nth-child(3) {
              height: 100%;
              transition: all 0.2s linear;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .arrows {
    img {
      &:first-child {
      }
      &:last-child {
      }
    }
  }
  .p3,
  .p4,
  .p5 {
    margin-bottom:11.25rem;
    margin-top:0;
    > p {
      left: 3.5rem;
      top: 86vw;
    }
    > div {
      flex-direction: column;
      .left {
        transform: initial;
        width: 100%;
        position: relative;
        padding-bottom: 8rem;
        .border {
        }
        > p {
          width: 85%;
          margin-left:3.5rem;

          &:first-child {
            margin-top: -15rem;
            margin-bottom: 31.5rem;
          }
          &:nth-child(3) {
            font-size: 2rem;
            width: 96%;
          }
          &:nth-child(2) {
            font-size: 2rem;
            bottom: auto;
            top: 0;
            position: relative;
            >i{
              margin-bottom:3.5rem;
            }
          }
        }
        > i {
        }
      }
      .right {
        width: auto;
        margin-left: auto;
        > img {
          display: block;;
        }
      }
    }
  }
  .p4 {
    > p {
      left: 3.5rem;
    }
    > div {
      .left {
      }
      .right {
        margin-right: 0;
      }
    }
  }
  .p6 {
    .arrows {
    }
    > p {
      padding-left: 1rem;
    }
    .con {
      margin-top:2rem;
      .menu {
        width: 100%;
        > p {
          padding-left: 1rem;
          > span {
            font-size: 2.7rem;
            &:after {
            }
            &.active {
              &:after {
              }
            }
          }
        }
        > span {
          display: none;
        }
      }
      .list {
        width: 96%;
        margin: auto;
        margin-top:5rem;
        .item {
          width: 100%;
          height:35rem;
          overflow:hidden;
          > img {
            transform: scale(0.5);
          }
          .desc {
             opacity:0;
              bottom:-5rem;
            p {
              font-size: 2rem;
            }
          }
          &:hover {
            > img {
            }
            .desc {
            }
            .border {
            }
          }
        }
      }
    }
  }
  .pagePoint{
    margin-left:2%
  }
}
</style>
