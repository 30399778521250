const EN = {
    more: 'Read More',
    moreAbout:'More About CBC',
    header1: 'Home',
    header2: 'About CBC',
    header2_1:'Our Fund Strategies',
    header2_2:'Our People',
    header2_3:'Recent Awards',
    header2_4:'Our Impact',
    header3: 'Our Ecosystem',
    header3_1: 'Joint-Value Creation',
    header3_2: 'Our Healthcare Ecosystem',
    header4: 'What’s New',
    header5: 'Contact Us',
    header5_1: 'Our Locations',
    header5_2: 'Contact Us',
    slogan: 'In Asia, <br>For Global',
    kv1: 'Beyond Boundaries<br>Better Healthcare<br>for All',
    kv2: 'We are a steadfast investor-operator and a serial entrepreneur',
    kv3: 'Strategies for the evolving global<br>healthcare landscape',
    kv4: 'Build a sustainable global<br>healthcare ecosystem',
    kv5: 'NEWS',
    videoLeft: 'Global healthcare<br>knows<br>no boundarie',
    p1tit2: 'We are a shaper and builder of the next generation global healthcare companies',
    team: 'Our Team',
    p1tit3: 'Our Key Metrics',
    p1tit4: 'Global Network',
    worldTit1: 'Global Headquarters',
    worldTit2: 'Core Offices',
    worldTit3: 'Portfolio Companies',
    // 首页地图地名
    homeMapAdd_Beijing:'Beijing',
    homeMapAdd_Seoul:'Seoul',
    homeMapAdd_Tokyo:'Tokyo',
    homeMapAdd_Shanghai:'Shanghai',
    homeMapAdd_HongKong:'Hong Kong',
    homeMapAdd_Singapore:'Singapore',
    homeMapAdd_Boston:'Boston',
    homeMapAdd_NewYork:'New York',
    homeMapAdd_SanDiego:'San Diego',
    homeMapAdd_SanFrancisco:'San Francisco',
    homeMapAdd_AbuDhabi:'Abu Dhabi',
    mapBack:'Back to world map',

    
    worldTip: 'Drag freely to explore our footprints',
    p1tit5: 'Our News',
    p1tit51: 'All News',
    toTop: 'Go Back',
    footContactUs:'Contact Us',
    scrollTip: 'Scroll down and explore more',

    p2Tit1: 'Our Leadership',
    p2Tit2: 'Our Values',
    p2ValueSub: 'At CBC, we believe we are fundamentally driven by our values. It shapes who we are, how we invest and how we interact with the communities we operate in. We encourage our employees to embrace CBC’s values so that they understand the principles that underpin our business. <br/> Our core values underpin our culture at CBC. ',
    p2ValueSub1: '',
    p2ValueTit1: 'We are Entrepreneurs',
    p2ValueTit2: 'We are Doers',
    p2ValueTit3: 'We are Passionate about the radical truth',
    p2ValueTit4: 'We Put Integrity First',
    p2ValueTit5: 'We are Inclusive',
    p2ValueDetail1: 'We are ambitious and tenacious. We challenge the status quo. We roll up our sleeves and tackle the toughest problems to improve and seek breakthroughs. We never give in to difficulties, we grow through them.',
    p2ValueDetail2: 'We take actions. We encourage each other to do more and talk less. We believe that value creation is driven by actions, and ideas are nothing without iterations. ',
    p2ValueDetail3: 'We are merit-driven, competitive and we play to win.Everyone at our firm with skill and imagination may aspire to reach the highest level. We seek the radical truth and we value transparency. We reward ourselves on truth and merits. ',
    p2ValueDetail4: 'Our fiduciary mindset guides every step of the way. We hold ourselves to the highest standards of excellence. We are a firm of integrity and we give unbiased advice to our investors, our ecosystem partners and our communities.',
    p2ValueDetail5: 'A diverse and complementary workforce is the bedrock of our creativity, innovations and success. While we value individuality, we are emotionally invested in the common success of our firm and our colleagues. We are creating a workplace called home by global talents from all walks – of industries, of markets, of the world. ',
    p2Tit3: 'Recent Awards',
    awardsTit1: 'Private Equity<br>International\'s list ',
    awardsTit2: 'Asian Private Equity<br>and Venture Capital Awards',
    awardsTit3: 'PERE',
    awardsTit4: 'Zero2IPO',
    awardsSub1: 'The world\'s largest private equity firms,<br>based on capital raised over the past five years',
    awardsSub2: 'Asian PE/VC',
    awardsSub3: '2021 Awards',
    awardsSub4: 'Venture Capital and Private Equity Ranking',
    award11: '# 1<br>Asia-focused global healthcare equity firm in 2019,2020,2021 and 2022',
    award12: '# 1<br>Private Equity Firm in Singapore',
    award13: '# 127<br>PEI 300 global private equity firm in 2022',
    award21: '2019 Exit of the Year - IPO',
    award22: '2019 Deal of the Year - Small Cap',
    award31: '# 2 <br>Alternative Investor of the Year Asia Pacific 2021 for Healthcare Infrastructure Platform',
    award41: 'Top 50<br>2021 Private Equity Firms in China the only healthcare-dedicated PE firms on the list',
    award42: 'Top 30<br>2019 & 2020 Healthcare-dedicated investment firms in China',
    p2Tit4: 'Our Locations',

    p3Tit1: 'Our Investment Strategies',
    p3Info1: 'As Asia’s largest healthcare investor-operator, we have harnessed our ecosystem of expertise to build and shape healthcare champions not only in Asia, but globally. CBC’s unique investment model combines entrepreneurship, scientific creativity, deep operational management and a vast network of experts to create transformative companies addressing the world’s unmet and underserved medical needs. ',
    p3Info2: 'Asia’s healthcare sector is at an inflection point. Beyond evolving demographics and constructive policy reforms that have increased access to better products and services, we’ve also seen a rapidly rising level of innovation, fueled by a growing level of talent, and technology breakthroughs. All these changes are creating investment opportunities for innovative solutions.',
    p3Info3: 'In the rapidly evolving healthcare market, we excel by being nimble, providing fit-for-purpose solutions to our valued investors through a wide range of strategies, including buyout, venture capital, private credit and infrastructure. We help our portfolio companies grow substantially and efficiently across their lifecycles.',
    p3Tit2: 'Our Fund Strategies',
    p3Tit21: 'Flagship Fund',
    p3Tit22: 'Venture Fund',
    p3Tit23: 'R-Bridge Healthcare Fund',
    p3Tit24: 'Healthcare Infrastructure Platform',
    p3Tit25: 'Flagship Fund',
    p3Tit26: 'Venture Fund',
    p3Tit3: 'Selected projects conceptual design and renderings',
    p4Tit1: 'Joint-Value Creation at CBC',
    p4Tit2: 'A dedicated portfolio<br>management team',
    p4Tit3: 'Accelerate<br>global expansion',
    p4Tit4: 'A serial<br>entrepreneur',
    p4Tit5: 'Our Healthcare Ecosystem',
    p4Tit6: 'Biopharma',
    p4Tit7: 'MedTech',
    p4Tit8: 'Medical Services',
    p4Info1: 'At CBC we understand that healthcare is a global business and have built our investment, business development and operational capabilities to match. ' ,
    p4Info2: 'Our team and our partners draw on a wealth of experience and expertise, many of them hailing from world-renowned biomedical research institutions, and multinational big pharma and healthcare companies, all singularly focused on driving innovation and growing successful, long-lasting companies. ',
    p4Sub1: 'Our stellar portfolio management team brings experience in various operational area,ranging from capital solutions, finance, recruiting, incentive scheme design and project management.',
    p4Sub2: 'Strong collaboration with the investment teams, combined with an in-depth understanding of the portfolios, play a critical role in accelerating portfolio companies\' global expansion.  We connect our portfolios with global capital, talent and partners.',
    p4Sub3: 'Entrepreneurship is foundational at CBC and we have incubated 12 life sciences companies since its inception in 2014. We provide our portfolios with tailored incubation and operation model to thrive',
    p5SearchInfo1:'By year',
    p5SearchInfo2:'search text',
    p5TypeText:'ALL',
    back:'back',
    loadMore: 'Load More',
    // 首页中英文补充
    videoBottom: 'Founded in 2014, CBC is Asia’s largest healthcare-dedicated investment firm. We are a shaper and builder of global healthcare platforms. We invest, we incubate, and we operate.',
    personLeft: ' We are committed, above all else, to being trusted partners to our companies and investors and meaningful contributors to our community. Our mission is to increase access, affordability and quality of treatments and services for our <br>patients globally. In connecting innovation, experience and entrepreneurial <br>drive.',
    job1: 'Chief Executive Officer',
    job2: 'Managing Director',
    name1: 'Wei Fu',
    name2: 'Matthew Chang',
    name3: 'Meng Jiang',
    name4: 'Hans Kang',
    name5: 'Lan Kang',
    name6: 'Michael Keyoung',
    name7: 'Annie Lee',
    name8: 'Sean Lu',
    name9: 'Fay Xing',
    p1p4tit1: 'US$ 5 billion',
    p1p4tit2: 'Asset Classes',
    p1p4tit3: 'Locations',
    p1p4tit4: 'Professionals',
    p1p4bottom1: 'US$ 5 billion AUM',
    p1p4bottom2: 'to support the full lifecycles<br>of our portfolio companies',
    p1p4bottom3: '9 locations globally ',
    p1p4bottom4: 'to support CBC’s healthcare ecosystem',
    // 关于我们
    aboutInfoP1: 'CBC Group is committed to creating value and integrating global resources to provide better healthcare for all. Partnering with the world’s top scientists and entrepreneurs, its unique investor-operator approach has empowered global leading healthcare companies to widen access to affordable medical care, catalyze innovations, and improve efficiency in fulfilling unmet medical needs worldwide. ',
    aboutInfoP2: 'Founded in 2014, CBC has a leading team of investment, industry and portfolio management professionals headquartered in Singapore with offices in Shanghai, Beijing, Hong Kong and New York, and presence in Boston, San Diego, San Francisco, and Tokyo. CBC focuses on platform-building, buyout, credit and growth-focused opportunities across multiple core areas within the healthcare sector, including pharmaceutical and biotech, medical technology, healthcare infrastructure and healthcare services. ',
    locationShanghai:'Shanghai',
    locationShanghaiAdd:'62/F, Tower I, Plaza 66 <br> 1266 West Nanjing Road <br>Shanghai, China ',
    locationBeijing:'Beijing',
    locationBeijingAdd:'8/F, Fortune Financial Center<br>5 Dongsanhuan Middle Road, <br>Chaoyang District, Beijing, China',
    locationXianggang:'Hong Kong',
    locationXianggangAdd:'Suites 3306-07, Two Exchange Square<br>8 Connaught Place, Central, Hong Kong',
    ourPeopleTitle:'Meet our team',
    ourPeopleContent:'As Asia’s largest healthcare investor-operator, we excel by bringing together a group of nimble and creative professionals who are entrepreneurial and passionate to build and shape healthcare champions not only in Asia but globally. We incorporate inclusion and diversity into all levels of our business. ',
    impact:'Our Impact',
    impactTitle:'We are committed to transparency and accountability as responsible investor-operators in creating long-lasting value by integrating sustainability ',
    impactContent:'Our commitment at CBC to ESG is unwavering, with our efforts anchored in reducing climate impact and creating sustainable value for the long-term benefit of society, our stakeholders, and our employees. We continually strive to implement eco-friendly practices and foster a corporate culture that prioritizes environmental stewardship. ',
    impactInfoTitle:'Our Approach to ESG',
    impactInfo1:'We integrate relevant ESG procedures, targets, and metrics into every part of our operations.',
    impactInfo2:'We are committed to continuing to advance our ESG journey and staying true to our mission of delivering affordable access to medical innovation across Asia and beyond.',
    impactInfo3:'We play heavy emphasis on corporate citizenship and philanthropic activities, supporting our local and global communities in a multitude of ways.',
    // 新闻
    time1: 'April 19, 2022',
    time2: 'April 19, 2022',
    time3: 'April 19, 2022',

    newsType1: 'CBC',
    newsType2: 'Portfolio',
    newsType3: 'In the Spotlight',

    newsTit1: 'CBC successfully completes acquisition of five cardiovascular and metabolism drugs from Takeda',
    newsTit2: 'ENSEM Therapeutics Raises $67 Million in Series A Financing to Develop Small Molecule Medicines',
    newsTit3: 'CBC, which made its name in Chinese healthcare, will open two international offices this year amid plans to become a \'one-stop\' healthcare asset management shop, CEO Wei ...',
    // investment
    investment1_info:'Focuses on buyout and platform investments in leading businesses across target healthcare sub-sectors of pharma/ biotech, medical device/ IVD and healthcare services.',
    investment1_info2:'CBC China Life Science Infrastructure Venture',
    investment1_1_title:'Partnering with the best',
    investment1_1:'Partnering with leading entrepreneurs and scientists, CBC’s flagship fund leverages a unique investor-operator approach to combine innovation and commercial expertise to broaden access to affordable and efficient medical care',
    investemnt1_2_title:'Guided by our domain expertise',
    investemnt1_2:'Domain expertise guides a differentiated investment approach, targeting an impactful and diversified value investment methodology',
    investemnt1_3_title:'Our investor-operator approach',
    investemnt1_3:'Comprehensive investment and portfolio management ecosystem imbue CBC as a trusted partner for exceptional entrepreneurs, upstart industry stakeholders and experienced management',

    investment2_info:'focuses on minority investment strategy to fully access universe of private investment opportunities in the healthcare industry. It targets early and growth-stage innovation and disruptive technology-driven modalities. ',
    investment2_1_title:'Who we are',
    investment2_1:'We are entrepreneur’s best partners, as they build great life science companies <br/>We are a dedicated team with experience from both investment and industry research ',
    investment2_2_title:'Our investment philosophy',
    investemnt2_2:'Effective and early thematic identification of attractive sub-sectors within China’s healthcare industry <br/>Emerging leaders with highly proprietary sourcing methodology which leverages the platform’s extensive network<br/> Flexible approach to deploying capital in the biotech/pharmaceutical, medtech/ IVD/innovative services and digital health sectors with an emphasis on backing serial entrepreneurs   <br/>In China for global: identify companies with a China nexus that have capability to expand their commercialization to global markets<br/>Diversified portfolio construction across healthcare sub-sectors ',


    investment3_info:'<p>We provide leading biotech / biopharma, medical technology and healthcare services companies with bespoke, non-dilutive financing that are backed by Asia-sourced healthcare royalty / revenue interests or through a range of other alternative financing structures.</p><p>For our target companies, we aim to help them unlock the current value of their future royalties, revenues or other cash flows to fund current corporate priorities (e.g. R&D, business development, capital expenditures), as an alternative or complement to equity issuance.</p><p>For our investors, we aim to assemble a diversified portfolio of Asian healthcare cash flows and credit assets that offer risk-adjusted returns uncorrelated to the public capital markets.</p>',
    investment3_1_title:'First Mover',
    investment3_1:'We are the first and only firm focusing on Asian-sourced healthcare royalty / structured financing opportunities',
    investment3_2_title:'Uncorrelated Strategy',
    investment3_2:'Our strategy takes advantage of sustainable market fundamentals (aging demographic, expanding middle class, and innovation-friendly policy reforms) that offer growth<b> sheltered from public market volatility and geopolitical uncertainties</b>',
    investment3_3_title:'Vast Unmet Demand',
    investment3_3:'We utilize<b> a validated and scalable strategy </b>that is mature in the developed markets but uniquely adopted by us for Asian healthcare that takes full advantage of the <b>large unmet capital needs </b>of innovators / licensors',


    investment4_info:'<p>Aims to become the largest independent one-stop best-in-class life science asset provider, and deliver attractive risk-adjusted returns by investing in a portfolio of life science assets leased to leading and emerging life science companies in top life science cities in China and across Asia.</p><p>With our “Capital + Professional Service” offering, we can provide ready to use best-in-class R&D and manufacturing spaces, fund and develop build-to-suit facilities for life science companies throughout their life cycles, or sale & leaseback arrangements to improve their capital structure and operation efficiency.</p><p>Our solution has been well received by life science companies as they can streamline capital and management resources to focus on research and technology development, business establishment and expansion, creating more long-lasting value for their businesses and society.</p>',
    investment4_1_title:'Who we are',
    investment4_1:'We are not only a space provider, but an ecosystem builder<br/>We have a fully-integrated team to empower our life science partners',
    investment4_2_title:'What we do',
    investment4_2:'We provide one-stop real asset solutions for best-in-class life science companies<br/>We create value through improving capital structure and operation efficiency <br/>We provide much-needed and sustainable space where life science innovations and breakthroughs are reimagined to benefit tens of millions',
    investment4_3_title:'Join our ecosystem',
    investment4_3:'Explore our full life-cycle real asset solutions from biotechnology incubators, R&D centers, to large scale commercialization manufacturing facilities',

    // 联系我们
    contactUsP1Type:'Global Headquarters',
    contactUsP2Title:'Contact Us',
    contactUsP2Email_1:"info{'@'}cbridgecap.com",
    contactUsP2Info_1:'For more information on CBC, please contact us at',
    contactUsP2Email_2:"cbchr{'@'}cbridgecap.com",
    contactUsP2Info_2:'If you would like to join us, please contact us at',
    // 成员管理
    teamPageName:'Meet Our Team',
    teamSearch:'Search',
    teamName:'Name',
    teamTitle:'Title',
    teamUnit:'Business unit',
    teamLocation:'Location',
}

export default EN
