<template>
  <div class="p5" id="index-p5">
    <div>
      <p class="c-tit tit white wow animate__animated animate__slideInUps">
        {{ $t("p1tit4") }}
      </p>
      <div class="map-outer">
        <div id="mapParent">
          <svg width="100%" height="100%" style="
                position: absolute;
                top: 0;
                left: 0;
                z-index:4;">
            <template v-for="(item, index) in lineDataActive" v-bind:key="index">
              <line
                :x1="lineActiveData.x"
                :y1="lineActiveData.y"
                :x2="item.x"
                :y2="item.y"
                style="
                  stroke: #04dccb;
                  stroke-width: 2px;
                  box-shadow: 0 0 14px 1px #A74C64, 0 0 70px 10px #91A1F7, 0 0 126px 23px #45B19B;
                " filter="url(#filter1)"
                :class="{lineActive:addLineClass}"
            ></line>
            </template>
            <filter id="filter1" x="0" y="0">
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>

          </svg>
          <img src="../../assets/map.svg" alt="" class="maps" />
          <div class="maps map2">
            <img src="../../assets/map-02.svg" alt="" />
            <span @mouseenter="enter(index, item)" @mouseleave="leave(index)"
              v-for="(item,index) in lineDataActive" v-bind:key="index"
                  :style="
                    {
                      left:item.x,
                      top:item.y
                    }"
                    :class="{active:item.active}"
            ></span>
          </div>
          <div class="maps map1">
            <img src="../../assets/map-01.svg" alt="" />
            <span @mouseenter="enter(index, item)" @mouseleave="leave(index)"
              v-for="(item,index) in lineDataActive" v-bind:key="index"
                  :style="
                    {
                      left:item.x,
                      top:item.y
                    }"
                    :class="{active:item.active}"
            ></span>
          </div>

          <div class="city">
            <div>
              <p class="c1" @click="toCity(1,0)">{{ $t("homeMapAdd_Singapore") }}</p>
            </div>
            <div>
              <p class="c2" @click="toCity(2,2)">{{ $t("homeMapAdd_HongKong") }}</p>
            </div>
            <div>
              <p class="c2" @click="toCity(2,0)">{{ $t("homeMapAdd_Shanghai") }}</p>
            </div>
            <div>
              <p class="c2" @click="toCity(2,1)">{{ $t("homeMapAdd_Beijing") }}</p>
            </div>
            <div>
              <p class="c2" @click="toCity(2,3)">{{ $t("homeMapAdd_NewYork") }}</p>
            </div>
            <div>
              <p class="c2" @click="toCity(2,4)">{{ $t("homeMapAdd_Seoul") }}</p>
            </div>
            <div>
              <p class="c3" @click="toCity(3,0)">{{ $t("homeMapAdd_Tokyo") }}</p>
            </div>
            <div>
              <p class="c3" @click="toCity(3,2)">{{ $t("homeMapAdd_SanDiego") }}</p>
            </div>
            <div>
              <p class="c3" @click="toCity(3,1)">{{ $t("homeMapAdd_Boston") }}</p>
            </div>
            <div>
              <p class="c3" @click="toCity(3,4)">{{ $t("homeMapAdd_SanFrancisco") }}</p>
            </div>
            <div>
              <p class="c3" @click="toCity(3,5)">{{ $t("homeMapAdd_AbuDhabi") }}</p>
            </div>
          </div>
          <div class="singapore">
            <span></span>
          </div>

          <div class="floating" v-if="!$store.state.isWap">
            <h4 class="tit">{{ domData.name }}</h4>
            <div class="foot">
              <span>{{domData.a}}<br />{{ domData.n }}</span>
              <span>{{domData.b}}<br />{{ domData.w }}</span>
            </div>
            <div class="border"><i></i><i></i><i></i></div>
          </div>
        </div>
        <div class="floating" v-if="$store.state.isWap">
          <h4 class="tit">{{ domData.name }}</h4>
          <div class="foot">
            <span>{{ domData.a }}<br />{{ domData.n }}</span>
            <span>{{ domData.b }}<br />{{ domData.w }}</span>
          </div>
          <div class="border border-left"><i></i><i></i><i></i></div>
        </div>
      </div>
      <ul class="tab  wow animate__animated animate__slideInUps">
        <li :class="{ active: mapIndex == 1 }" @click="mapSwitch(1)">
          {{ $t("worldTit1") }}
        </li>
        <li :class="{ active: mapIndex == 2 }" @click="mapSwitch(2)">
          {{ $t("worldTit2") }}
        </li>
        <li :class="{ active: mapIndex == 3 }" @click="mapSwitch(3)">
          {{ $t("worldTit3") }}
        </li>
      </ul>
      <div class="mark">
        <ul>
          <li>
            {{ $t("worldTit1") }}
          </li>
          <li>
            {{ $t("worldTit2") }}
          </li>
          <li>
            {{ $t("worldTit3") }}
          </li>
        </ul>
      </div>
      <div class="back" @click="back">
       <img src="../../assets/p1/back.svg"> {{$t('mapBack')}}
      </div>
      <div class="tip" v-if="mapIndex!=0">
        <img src="../../assets/to-bottom-arrow.svg" alt="" />{{
          $t("worldTip")
        }}
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import $ from "jquery";
import { animate } from "popmotion";
import Hammer from "hammerjs";
//this.animate(this.toMapSize, this.toMapSize2);
export default defineComponent({
  name: "map",
  data() {
    return {
      bodyWidth: 0,
      bodyHeight: 0,
      mapStyle: { width: 0, height: 0, left: 0, top: 0 },
      toMapStyle: { width: 0, height: 0, left: 0, top: 0 },
      mapParent: null,
      mapIndex: 0,
      domData: { name: "", n: "", w: "" },
      lineActiveData:{
        x:0,y:0,
      },
      lineData:{
        global:[
          { x:'75.6%', y:'55.1%',local:'right',active:false,
            data:{ name: this.$t("homeMapAdd_Singapore"), n: "1°18′", w: "103°51’", a:"N",b:"E"}},
        ],
        coreOffices:[
          { x:'79.6%', y:'46.1%',local:'right',active:false,
            data:{ name: this.$t("homeMapAdd_Shanghai"), n: "34°5′", w: "121°4’", a:"N",b:"E"}},
          { x:'78.6%', y:'40.7%',local:'right',active:false,
            data:{ name: this.$t("homeMapAdd_Beijing"), n: "39°9′", w: "116°3’",a:"N",b:"E" }},
          { x:'77.7%', y:'50.8%',local:'right',active:false,
            data:{ name: this.$t("homeMapAdd_HongKong"), n: "22°15′", w: "114°15’", a:"N",b:"E"}},
          {x:'26.7%',y:'38.2%',local:'left',active:false,
            data:{ name: this.$t("homeMapAdd_NewYork"), n: "40°43′", w: "74°51’",  a:"N",b:"S"}},
        ],
        portfollo:[
          {x:'85.2%',y:'42.8%',local:'right',active:false,
            data:{ name: this.$t("homeMapAdd_Tokyo"), n: "35°44′", w: "140°50’", a:"N",b:"E" }},
          { x:'27.7%', y:'36.3%',local:'left',active:false,
            data:{ name: this.$t("homeMapAdd_Boston"), n: "42°36′", w: "71°06’", a:"N",b:"S"}},
          {x:'13.95%',y:'43.99%',local:'left',active:false,
            data:{ name: this.$t("homeMapAdd_SanDiego"), n: "32°42′", w: "117°9’",  a:"N",b:"S"}},
          {x:'81.6%',y:'41.5%',local:'right',active:false,
            data:{ name: this.$t("homeMapAdd_Seoul"), n: "37°33′", w: "126°58’", a:"N",b:"S" }},
          {x:'12.95%',y:'42%',local:'left',active:false,
            data:{ name: this.$t("homeMapAdd_SanFrancisco"), n: "37°48′", w: "122°25’",  a:"N",b:"W"}},
          {x:'60.95%',y:'51%',local:'right',active:false,
            data:{ name: this.$t("homeMapAdd_AbuDhabi"), n: "54°22′", w: "24°28’",  a:"N",b:"W"}},
        ]
      },
      lineDataActive:null,
      addLineClass:false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // map 父级
      this.mapParent = $("#mapParent");
      this.mapStyle.width = this.bodyWidth = window.innerWidth;
      this.mapStyle.height = this.bodyHeight = window.innerHeight;

      this.resize()
      window.addEventListener("resize", () => {
        this.resize();
      });
      let hammerDom = document.querySelector("#mapParent");
      let hammertime = new Hammer(hammerDom);
      hammertime.on("pan panend swipe", (ev) => {
        this.touch(ev);
      });
    },
    // 返回到全球地图
    back(){
      // this.mapSwitch(0)
      $(".p5 .mark").show();
      $(".p5 .back").hide();
      $(".singapore,.floating").hide();
      $(".map2,.map1").hide();
      this.mapStyle = this.mapLocation(0)
      this.mapIndex = 0;
      this.addLineClass = false;
      animate({
        from:this.toMapStyle,
        to:  this.mapStyle,
        duration: 500,
        onUpdate: (latest) => {
          this.mapParent.css(latest);
        },
        onComplete: () => {
          $(".city").fadeIn();
          //this.arrayData(this.mapStyle, this.toMapStyle);
        },
      });
    },
    // 鼠标hover地点
    enter(e,item) {
      this.addLineClass = false;
      this.domData = this.lineDataActive[e].data
      $(".floating").show();
      setTimeout(()=>{
        this.addLineClass = true;
      },100)
      this.lineDataActive.forEach((item) => {
        item.active = false;
      })
      if(item){
        this.lineActiveData = this.lineDataActive[e]
        if(window.innerWidth > 960 ) $(".floating").css({left:item.x, top:item.y})
        item.active = true;
      }
    },
    // 鼠标离开地点
    leave(e) {
      this.domData = this.lineDataActive[e].data
      // $(".floating").hide();
    },
    // 点击全球地点 跳转至指定城市
    toCity(type,id){
      this.mapSwitch(type,id)
      this.enter(id,this.lineDataActive[id])
    },
    // 拖动地图
    touch(ev) {
      if(this.mapIndex==0)return;
      let left = this.mapStyle.left + ev.deltaX * 2;
      left =
        left > 0
          ? 0
          : left < -this.mapStyle.width + this.bodyWidth
          ? -this.mapStyle.width + this.bodyWidth
          : left;
      let top = this.mapStyle.top + ev.deltaY * 2;
      top =
        top > 0
          ? 0
          : top < -this.mapStyle.height + this.bodyHeight
          ? -this.mapStyle.height + this.bodyHeight
          : top;
      if(window.innerWidth<960){
        this.mapParent.css({ left: left});

      }else{
        this.mapParent.css({ left: left, top: top });
      }

      if (ev.type == "panend") {
        this.mapStyle.left = left;
        this.mapStyle.top = top;
      }
    },
    // 地图导航切换
    mapSwitch(index, id) {
      this.mapIndex = index;
      this.lineDataActive = index == 1 ? this.lineData.global : 
                            index == 2 ? this.lineData.coreOffices : 
                            index == 3 ? this.lineData.portfollo : null;
      index == 2 || index == 3 ? this.enter(0,this.lineDataActive[0]) : null;
      this.uiSwitch(index);
      let ind;
      if(id){
        if(id == 5){
          ind = 5;
        }else{
          ind = this.lineDataActive[id].local == 'left' ? 4 : index
        }
      }else{
        ind = index;
      }
      animate({
        from: this.mapStyle,
        to: this.mapLocation(ind),
        duration: 500,
        onUpdate: (latest) => {
          this.mapParent.css(latest);
        },
        onComplete: () => {
          this.arrayData(this.mapStyle, this.toMapStyle);
        },
      });
    },
    // 地图导航切换时变更UI
    uiSwitch(index) {
      $(".city").fadeOut();
      $(".singapore,.floating").hide();
      $(".map2,.map1, .p5 .mark").hide();
      $("html,body").animate({ scrollTop: $("#mapParentTop").offset().top }, 500);
      $(".floating").fadeIn();
      $(".p5 .back").show();
      this.domData = this.lineDataActive[0].data;
      if (index == 1) {
        if(window.innerWidth > 960){
          $(".floating").css({ left: "76%", top: "calc(62% - 24rem)" });
        }
        this.addLineClass = false;
        $(".singapore").fadeIn();
      } else if (index == 2) {
        $(".map2").fadeIn();
        if(window.innerWidth > 960){
          $(".floating").css({ left: this.lineDataActive[0].x, top: "calc("+this.lineDataActive[0].y+" + 20px)" });
        }
      } else if (index == 3) {
        $(".map1").fadeIn();
        if(window.innerWidth > 960){
          $(".floating").css({ left: this.lineDataActive[0].x, top: "calc("+this.lineDataActive[0].y+" + 20px)" });
        }
      }
    },
    // 地图切换时定位
    mapLocation(index) {
      // 放大倍数
      let scales;
      if(window.innerWidth < 960){
        scales = 6;
      }else{
        scales = 4;
      }
      this.toMapStyle.width = this.bodyWidth * scales;
      this.toMapStyle.height = this.toMapStyle.width * 0.494;
      console.log(index)
      if (index == 1) {
        this.toMapStyle.left =
          -this.toMapStyle.width * 0.75 + this.bodyWidth * 0.5;
        this.toMapStyle.top =
          -this.toMapStyle.height * 0.59 + this.bodyHeight * 0.5;
        return this.toMapStyle;
      } else if (index == 2) {
        this.toMapStyle.left =
          -this.toMapStyle.width * 0.79 + this.bodyWidth * 0.5;
        this.toMapStyle.top =
          -this.toMapStyle.height * 0.45 + this.bodyHeight * 0.5;
        return this.toMapStyle;
      } else if (index == 3) {
        this.toMapStyle.left =
          -this.toMapStyle.width * 0.85 + this.bodyWidth * 0.5;
        this.toMapStyle.top =
          -this.toMapStyle.height * 0.42 + this.bodyHeight * 0.5;
        return this.toMapStyle;
      } else if (index == 4) {
        this.toMapStyle.left =
          -this.toMapStyle.width * 0.25 + this.bodyWidth * 0.5;
        this.toMapStyle.top =
          -this.toMapStyle.height * 0.42 + this.bodyHeight * 0.5;
        return this.toMapStyle;
      } else if (index == 5) {
        this.toMapStyle.left =
          -this.toMapStyle.width *0.6+ this.bodyWidth * 0.5;
        this.toMapStyle.top =
          -this.toMapStyle.height * 0.52 + this.bodyHeight * 0.5;
        return this.toMapStyle;
      }  else {
        if(window.innerWidth < 960){
          this.mapStyle.width = this.bodyWidth * 4;
          this.mapStyle.height = this.toMapStyle.width * 0.494;
          this.mapStyle.top = (this.bodyHeight - this.bodyWidth * 0.494) / 15;
          this.mapStyle.left =
            -this.mapStyle.width * 0.77 + this.bodyWidth * 0.5;
          $(".p5 .city").css({height:this.bodyWidth * 0.494 * 4})
        }else{
          this.mapStyle.width = this.bodyWidth;
          this.mapStyle.height = this.bodyHeight;
          this.mapStyle.top = (this.bodyHeight - this.bodyWidth * 0.494) / 2;
          this.mapStyle.left = 0;
          $(".p5 .city").css({height:this.bodyWidth * 0.494})
        }
        return this.mapStyle;
      }
    },
    // 数组详细赋值到另一个数组
    arrayData(e, b) {
      e.width = b.width;
      e.height = b.height;
      e.left = b.left;
      e.top = b.top;
    },
    // 改变地图大小
    resize() {
      this.bodyWidth = window.innerWidth;
      this.bodyHeight = window.innerHeight;
      this.mapParent.css(this.mapLocation(this.mapIndex));
    },
    maxMin(x, max, min) {
      return (x - min) / (max - min);
    },
  },
  getters: {},
});
</script>
<style scoped lang='less'>
.c-tit {
  font-size: 4.5rem;
  color: #001c42;
  margin-bottom: 95px;
  &.white {
    color: #fff;
  }
}
.p5 {
  background: #183763;
  margin-top: 10rem;
  position: relative;
  color: #fff;
  height: calc(100vh - 10rem);
  overflow:hidden;
  > div {
    width: 100%;
    height: calc(100vh - 100px);
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .c-tit {
    position: absolute;
    left: 40px;
    top: 9rem;
    z-index: 1;
    font-size: 7.2rem;
  }
  > img {
    width: 100%;
  }
  .back{
    cursor:pointer;
    font-size:1.8rem;
    display:none;
    position:absolute;
    bottom:7rem;
    >img{
      width:7rem;
      margin-bottom:-2.6rem;
      margin-right:2rem;
      margin-left:3rem;
    }
  }
  .map-outer {
    width: 100%;
    height: calc(100vh);
    overflow: hidden;
    position: relative;
    bottom: 0;
    left: 0;
    svg{
      stroke-dasharray: 100%;  stroke-dashoffset: 100%;
    }
    .lineActive{
      stroke-dashoffset: 0%;
      transition:all 3s;
    }
    > div {
      width: 1650px;
      height: 830px;
      position: absolute;
      z-index: 0;
      > img {
        pointer-events: none;
      }
      .maps {
        position: absolute;
        top: 0;
        left: 0;
        > img {
          pointer-events: none;
        }
        span {
          display: inline-block;
          position: absolute;
          width: 20px;
          height: 20px;
          background: rgba(255, 255, 255, 0);
          cursor: pointer;
        }
        >span{
          border:2px solid #ffffff;
          border-radius:100%;
          margin-left:-10px;
          margin-top:-12px;
          background:#15447a;
          &:after{
            content:'';
            display:block;
            width:8px;
            height:8px;
            border:2px solid #ffffff;
            border-radius: 100%;
            margin-top:4px;
            margin-left:4px;
          }
        }
      }

      .map2 {
        width: 100%;
        display: none;
        z-index: 5;
        >img{
          opacity: 0;
        }
        span.active{
          &:after{
           background:#04dccb;
          }
        }
      }
      .map1 {
        width: 100%;
        display: none;
        z-index: 5;
        >img{
          opacity: 0;
        }
        span{
          width:10px;
          height:10px;
          margin-left:-5px;
          margin-top:-6px;
          &:after{
            display:none;
          }
          &.active{
            background:#ffffff;
            border-color:#04dccb;
          }
        }
      }
      .singapore {
        width: 28px;
        height: 28px;
        border-radius: 100%;
        position: absolute;
        left: 74.9%;
        top: 61.5%;
        display: none;
        background: url(../../assets/p1/c-1-active.svg);
      }
      
    }.floating {
        width: 24rem;
        height: 24rem;
        background: rgba(15, 98, 176, 0.8);
        position: absolute;
        top: calc(62% - 24rem);
        left: 76%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        display: none;
        z-index: 6;
        pointer-events:none;
        > div.foot {
          display: flex;
          span {
            width: 100%;
            display: block;
            padding: 2.5rem;
            font-size: 2rem;
          }
        }
        h4 {
          font-size: 3.6rem;
          margin: 2rem;
          &after {
            width: 6rem;
            border-top: 2px solid #ffffff;
          }
        }
        .border {
          opacity: 0;
          i {
            transition: all 0.4s;
          }
        }
        .border {
          opacity: 1;
          i:first-child {
            height: 4rem;
            transition: all 0.2s 0.4s linear;
          }
          i:nth-child(2) {
            width: 100%;
            transition: all 0.2s 0.2s linear;
          }
          i:nth-child(3) {
            height: 100%;
            transition: all 0.2s linear;
            height: 100%;
          }
        }
      }
  }
  ul.tab {
    width: fit-content;
    position: absolute;
    top: 10rem;
    left: 35%;
    display: flex;
    justify-content: space-between;
    li {
      position: relative;
      font-size: 1.7rem;
      margin:0 4rem;
      cursor:pointer;
      transition:all 0.5s;
      &:hover{
        color:#04dccb;
      }
      &.active {
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: #04dccb;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.2s;
        }
      }
    }
  }
  .mark {
    position: absolute;
    width: 20%;
    height: 18rem;
    background: #132847;
    bottom: 0;
    left: 0;
    ul {
      li {
        line-height: 5rem;
        padding-left: 8rem;
        font-size: 1.4em;
        color: rgba(255, 255, 255, 0.5);
        background: url("../../assets/p1/c-1.svg") no-repeat 4rem;
        background-size: 2.3rem;
        &:nth-child(2) {
          background-image: url("../../assets/p1/c-2.png");
        }
        &:last-child {
          background-image: url("../../assets/p1/c-3.png");
        }
      }
    }
  }
  .tip {
    color: #fff;
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    img {
      display: block;
      margin: auto;
      margin-bottom: 2rem;
      width:5rem;
    }
  }
  .city {
    // TODO 城市和城市滑动
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("../../assets/p1/map-line.png");
    background-size: 100%;
    z-index: 5;
    > div {
      position: absolute;
      cursor:pointer;
      > p {
        padding-left: 30px;
        background: url("../../assets/p1/c-1.svg") no-repeat;
        background-position: 0 0;
        background-size: 20px;
        font-size: 2rem;
        height: 4rem;
        transition:all 0.5s;
        &:hover{
          color:#04dccb;
        }
        &.c2 {
          background-image: url("../../assets/p1/c-2.png");
          font-size: 2rem;
          background-size: 2rem;
          background-position: 0.5rem 0.5rem;
          padding-left: 3rem;
          height: 3rem;
        }
        &.c3 {
          background-image: url("../../assets/p1/c-3.png");
          font-size: 1.7rem;
          background-size: 1rem;
          background-position: 0.5rem 0.7rem;
          height: 3rem;
          padding-left: 2rem;
        }
      }
      &:first-child {
        top: 62%;
        left: 74%;
      }
      &:nth-child(2) {
        top: 56%;
        left: 75%;
      }
      &:nth-child(3) {
        top: 45.5%;
        left: 79%;
      }
      &:nth-child(4) {
        top: 37.5%;
        left: 77.5%;
        p {
          background-position: 0.5rem 1rem;
        }
      }
      &:nth-child(5) {
        top: 37.5%;
        left: 25.5%;
      }
      &:nth-child(6) {
        top: 40%;
        left: 81.4%;
      }
      &:nth-child(7) {
        top: 42%;
        left: 84.5%;
      }
      &:nth-child(8) {
        top: 42%;
        left: 14.5%;
      }
      &:nth-child(9) {
        top: 34.5%;
        left: 28.5%;
      }
      &:nth-child(10) {
        top: 39%;
        left: 11.95%;
      }
      &:nth-child(11) {
        top: 49%;
        left: 60.95%;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .p5{
    .c-tit{
      left:3.5rem;
    }
    ul.tab{
      left:3.5rem;
      top:22%;
        flex-direction:column;
        width:50%;
      li{
        width: fit-content;
        font-size:3rem;
        padding-top:2rem;
      }
    }
    .mark{
      width:50%;
      height:18rem;
    }
    .tip{
      width:100%;
      display: none;;
      >img{
        width:3rem;
      }
    }
    .map-outer{
      >div{
      }
      .floating{
        bottom: 10rem;
        right: 3rem;
        left: auto;
        position: absolute;
        top: auto;
        width:28rem;
        height:33rem;
        h4{
          margin-bottom:11rem;
          border-bottom:2px solid #ffffff;
          width:fit-content;
          padding-bottom:1rem;
          font-size:5rem;
        }
        div.foot{
          span{
            font-size:3rem;
          }
        }
      }
    }
  }
}
</style>