<template>
  <div class="new-detaill">
    <div class="back">
      <span @click="back"
        ><img src="../assets/p5/back-icon.png" alt="" /> {{ $t("back") }}</span
      >
    </div>
    <article class="news-content">
      <div class="left">
        <h1 class="tit">
          {{ content.news_title }}
        </h1>
        <div class="tag">
          <span>
            {{
              content.news_type
            }}</span
          >
        </div>
        <img
          :src="content.news_image_url"
          alt=""
        />
        <time class="tit">{{
          content.release_time
        }}</time>
        <span class="author">{{
          content.news_author
        }}</span>
      </div>
      <div class="right">
        <div
          id="content_id"
          v-html="
            content.news_content
          "
        ></div>
      </div>
    </article>
    <div class="list">
      <div class="swich-page" v-if="!$store.state.isWap">
        <div class="pre">
          <img
            src="../assets/p5/back-icon.png"
            alt=""
            :style="swiper1PrevDisabled ? disabledStyle : ''"
            @click="pcPrev"
          />
        </div>
        <div class="next">
          <img
            src="../assets/p5/back-icon.png"
            alt=""
            :style="swiper1NextDisabled ? disabledStyle : ''"
            @click="pcNext"
          />
        </div>
      </div>
      <div class="news-list pc wow animate__animated animate__slideInUps">
        <swiper
          :slides-per-view="$store.state.isWap ? 1 : 4"
          :space-between="50"
          @swiper="pcSwiper"
          @slide-change-transition-end="swiperPcChange"
        >
          <template
            v-for="(item, index) in list"
            v-bind:key="index"
          >
            <swiper-slide>
              <div class="news-item">
                <div @click="getContent(item.id)">
                  <img :src="item.news_image_url" alt="" />
                  <div class="hover">
                    <img src="../assets/news-arrow.png" alt="" />
                  </div>
                  <div class="border fixed"><i></i><i></i><i></i></div>
                </div>
                <p>{{ item.release_time }} / {{ item.news_type }}</p>
                <p>{{ item.news_title }}</p>
                <a  @click="getContent(item.id)" class="read-more">{{
                  $t("more")
                }}</a>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>

      <div
        class="news-list wap wow animate__animated animate__slideInUps"
        :class="{
          'swiper-grid swiper-grid-column mySwiper': $store.state.isWap,
        }"
      >
        <swiper :slides-per-view="1" :space-between="50" @swiper="wapSwiper">
          <template
            v-for="(item, index) in list"
            v-bind:key="index"
          >
            <swiper-slide v-if="index % 2 == 0 && list[index + 1]">
              <div class="news-item">
                <div @click="getContent(item.id)">
                  <img :src="item.news_image_url" alt="" />
                  <div class="hover">
                    <img src="../assets/news-arrow.png" alt="" />
                  </div>
                  <div class="border fixed"><i></i><i></i><i></i></div>
                </div>
                <p>{{ item.release_time }} / {{ item.news_type }}</p>
                <p>{{ item.news_title }}</p>
                <a  @click="getContent(item.id)" class="read-more">{{
                  $t("more")
                }}</a>
              </div>

              <div class="news-item">
                <div
                  @click="getContent(list[index + 1].id)"
                >
                  <img :src="list[index + 1].news_image_url" alt="" />
                  <div class="hover">
                    <img src="../assets/news-arrow.png" alt="" />
                  </div>
                  <div class="border fixed"><i></i><i></i><i></i></div>
                </div>
                <p>
                  {{ list[index + 1].release_time }} / {{ list[index + 1].news_type }}
                </p>
                <p>{{ list[index + 1].news_title }}</p>
                <a
                  @click="getContent(item.id + 1)"
                  class="read-more"
                  >{{ $t("more") }}</a>
              </div>
            </swiper-slide>
          </template>
        </swiper>

        <div class="pagePoint" v-if="$store.state.isWap" style="width: 100%">
          <template
            v-for="(item, index) in list"
            v-bind:key="index"
          >
            <li
              :class="{ active: swiperWap.activeIndex == index }"
              v-if="index < parseInt(list.length / 2)"
            ></li>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.less";

import {getNewContent, getNewList} from "../api/new"
export default defineComponent({
  name: "NewsDetail",
  components: {
    Swiper,
    SwiperSlide,
    // maps,
  },
  data() {
    return {
      timeRange: [],
      swiperPc: null,
      swiperWap: { activeIndex: 0 },
      currentId: 0,
      swiper1PrevDisabled: true,
      swiper1NextDisabled: false,
      newsData: this.$store.state.newsContent[this.$store.state.lang],
      disabledStyle: { opacity: 0.5, "pointer-events": "none" },
      content:{},
      list:[],
    };
  },
  watch: {
    $route: function (val) {
      this.currentId = val.params.id;
    },
  },
  mounted() {
    this.currentId = this.$route.params.id;
    this.getContent(this.currentId);
    this.getList();
  },
  methods: {
    pcSwiper(swiper) {
      this.swiperPc = swiper;
    },
    swiperPcChange(e) {
      console.log(e.activeIndex);
      this.swiper1NextDisabled = false;
      this.swiper1PrevDisabled = false;
      if (e.activeIndex === 0) this.swiper1PrevDisabled = true;
      if (e.activeIndex === 1) this.swiper1NextDisabled = true;
    },
    wapSwiper(swiper) {
      this.swiperWap = swiper;
    },
    pcPrev() {
      this.swiperPc.slidePrev();
    },
    pcNext() {
      this.swiperPc.slideNext();
    },
    back() {
      this.$router.back();
    },
    getContent(e){
      // 回到顶部
      window.scrollTo(0, 0);
      window.document.getElementById("content_id").scrollTo(0,0)
      getNewContent({id: e}).then(res => {
        res.info.release_time = this.$store.state.timeFormat(res.info.release_time)
        this.content = res.info;
      })
    },
    getList(){
      getNewList({
        page:0,
        page_num:8,
      }).then(res =>{
        this.list = res.info.news
        this.list.forEach(item => {
          item.release_time = this.$store.state.timeFormat(item.release_time)
        })
      })
    }
  },
});
</script>

<style scoped lang='less'>
.new-detaill {
  width: 100%;
  margin: auto;
  .back {
    background: #e7e7e7;
    font-weight: bold;
    padding: 0 34px;
    padding-top: 80px;
    box-sizing: border-box;
    font-size: 2rem;
    span {
      cursor: pointer;
    }
    img {
      width: 25px;
      margin-right: 0.3rem;
    }
  }
  .news-content {
    display: flex;
    justify-content: space-between;
    background: #e7e7e7;
    padding: 0 34px;
    box-sizing: border-box;
    .left {
      width: 30%;
      padding-bottom: 130px;
      > img {
        width: 100%;
      }
      h1 {
        margin-top: 30px;
        font-size: 4.8rem;
        font-weight: normal;
        margin-bottom: 1.6rem;
      }
      .tag {
        border-bottom: 2px solid #183763;
        padding-bottom: 2rem;
        margin-bottom: 60px;
        font-size: 1.4rem;
        span {
          background: #183763;
          color: #ffffff;
          line-height: 20px;
          height: 20px;
          border-radius: 20px;
          padding: 0 10px;
          margin-right: 10px;
        }
      }
      time {
        font-size: 3.6rem;
        font-weight: bold;
        display: block;
        margin: 1rem 0;
        margin-top: 5rem;
      }
      span.author {
        display: block;
        font-weight: bold;
        font-size: 1.4rem;
      }
    }
    .right {
      width: 50%;
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 600;
      margin-top: 38px;
      > div {
        height: 68rem;
        overflow-y: auto;
        overflow-x: hidden;
        color: #183763;
        padding-right: 7rem;
        sup{
          font-size:1.2rem;
        }
        > p {
          padding-top: 3rem;
        }
        &::-webkit-scrollbar {
          width: 2px;
          background: hsla(0, 0%, 84.7%, 0.5);
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #04dccb;
        }
      }
      img,
      video {
        max-width: 100%;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
  .swich-page {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
    background: #ededed;
    padding-top: 3rem;
    div {
      cursor: pointer;
      img {
        width: 30px;
      }
      padding: 30px;
    }
    .next {
      img {
        transform: scale(-1);
      }
    }
  }
  .list {
    background: #ededed;
    padding-bottom: 10rem;
  }
  .news-list {
    margin: auto;
    padding: 0 3.5rem;
  }
  .news-item {
    > div {
      cursor: pointer;
      position: relative;
      > img {
        width: 100%;
        display: block;
      }
      .hover {
        transition: all 0.5s;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        opacity: 0;
        > img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.2rem;
          height: 7.2rem;
        }
      }
      .border {
        max-width: 100%;
        i:nth-child(1):first-child {
          height: 0;
          width: 4px;
          top: 0;
          left: 0;
        }
        i:nth-child(2) {
          width: 0;
          height: 4px;
          top: 0;
          right: 0;
        }
        i:nth-child(3) {
          height: 0;
          width: 4px;
          right: 0;
          bottom: 0;
        }
      }
      &:hover {
        .hover {
          opacity: 1;
        }
        .border {
          i:first-child {
            height: 25%;
            transition: all 0.2s 0.4s linear;
          }
          i:nth-child(2) {
            width: 100%;
            transition: all 0.2s 0.2s linear;
          }
          i:nth-child(3) {
            height: 100%;
            transition: all 0.2s linear;
            height: 100%;
          }
        }
      }
    }
    > p {
      &:nth-child(2) {
        font-size: 1.4rem;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      &:nth-child(3) {
        font-size: 2rem;
        line-height: 1.5;
        margin-bottom: 50px;
        height: 9rem;
        overflow: hidden;
      }
      &.read-more {
        border-bottom: 2px solid #04dccb;
        display: inline-block;
        cursor:pointer;
      }
    }
  }
  .arrows {
    width: 100%;
    margin-top: 100px;
    padding-bottom: 100px;
    text-align: right;
    img {
      width: 40px;
      cursor: pointer;
      &:first-child {
        margin-right: 56px;
      }
      &:last-child {
        margin-right: 240px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .new-detaill {
    .back {
      font-size: 2.6rem;
      font-weight: normal;
      padding-left: 3.5rem;
      > img {
      }
    }
    .news-content {
      flex-direction: column;
      padding: 0;
      .left {
        width: auto;
        padding-bottom: 0;
        > img {
        }
        h1 {
          font-size: 4rem;
          font-weight: normal;
          padding: 0 3.5rem;
        }
        .tag {
          font-size: 2rem;
          padding: 4rem 0;
          padding-top: 2rem;
          margin-bottom: 3rem;
          margin-left: 3.5rem;
          margin-right: 3.5rem;
          span {
            padding: 0.4rem 1rem;
          }
        }
        time {
          margin-left: 3.5rem;
          margin-right: 3.5rem;
          margin-top: 5rem;
        }
        span.author {
          margin-left: 3.5rem;
          margin-right: 3.5rem;
          font-size: 2rem;
        }
      }
      .right {
        width: auto;
        overflow: auto;
        padding-right: 1rem;
        font-size: 2.3rem;
        font-weight: normal;
        padding: 0 3.5rem;
        background: #ededed;
        padding-top: 10rem;
        margin-top: 13rem;
        padding-bottom: 10rem;
        > div {
          -webkit-overflow-scrolling: auto;
          & ::-webkit-scrollbar {
            width: 2px;
            background: hsla(0, 0%, 84.7%, 0.5);
          }
          & ::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #04dccb;
          }
          & ::-webkit-scrollbar-thumb,
          & ::-webkit-scrollbar-thumb:hover,
          & ::-webkit-scrollbar-thumb:active{
          background-color: red;
          border-radius: 2px;
          border: 4px solid transparent;
          background-clip: content-box;
          }
        }
        img,
        video {
        }
        p {
        }
      }
    }
    .swich-page {
      padding: 0;
      justify-content: center;
      div {
        img {
        }
      }
      .next {
        img {
        }
      }
    }
    .list {
      > div {
        width: 91%;
        margin-left: auto;
        margin-right: auto;
      }
      padding: 0;
      background: #e7e7e7;
      padding-bottom: 13rem;
      padding-top: 1rem;
      ul {
        width: 92%;
        margin: auto;
        padding-bottom: 5rem;
        li {
          p {
            font-size: 2rem;
            margin-top: 1rem;
          }
          width: auto;
          img {
          }
          h4 {
          }
          a {
            font-size: 2rem;
          }
        }
      }
    }
    .news-item {
      position: relative;
      padding-bottom: 0.1rem;
      margin-top: 12rem;
      p {
        &:nth-child(2) {
          font-size: 2rem;
          margin-bottom: 1.5rem;
        }
        &:nth-child(3) {
          font-size: 3.2rem;
        }
      }
    }
  }
}
</style>
