<template>
  <div class="kv" :style="'background-image:url('+this.imgUrl+')'">
    <p class="left wow animate__animated animate__slideInUp">
      <span class="border border-left wow animate__animated animate__line">
        <i class="animate__animated"></i>
        <i class="animate__animated"></i>
        <i class="animate__animated"></i>
      </span>
      <span
        v-html="kvText"
        class="tit tit-h1"
      ></span>
    </p>
    <img :src="imgUrl" alt="" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "kv",
  props: ["slogan"],
  data() {
    return {
      timeRange: [],
      imgUrl:'',
      kvText:'',
    };
  },
  mounted(){
    this.kvpic()
  },
  methods:{
    kvpic(){
      this.kvText = this.$store.state.kvData[this.slogan].slogan_content
      let random = Math.floor(Math.random() * (4) + 1)
      if(window.innerWidth < 960){
        this.imgUrl = this.$store.state.kvData[random].media_wap_image_url
      }else{
        this.imgUrl = this.$store.state.kvData[random].media_image_url
      }
    }
  }
});
</script>

<style lang="less">
.kv {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  img {
    width: 100%;
    opacity: 0;
    min-height: 700px;
    z-index: 0;
    pointer-events: none;
  }
  .border {
    height: 25rem;
  }
  > p:first-child {
    transition: all 0.5s;
    color: #fff;
    position: absolute;
    top: 25%;
    left: 22.5%;
    z-index: 1;
    white-space: nowrap;
    width: 50%;
    word-wrap: break-word;
    span {
      word-break: break-word;
      word-wrap: break-word;
      display: block;
      white-space: normal;
    }
    //&:after {
    //  content: "";
    //  display: none;
    //  width: 100%;
    //  height: 100%;
    //  background: url("../../assets/line.png") no-repeat;
    //  position: absolute;
    //  top: -30%;
    //  left: 30%;
    //}
    &.left {
      &:after {
        background-image: url("../../assets/line-left.png");
        left: -5%;
      }
    }
  }
}

.news-content {
  .kv {
    > p:first-child {
      width: 30rem;
      height: 23rem;
      &.left {
        &:after {
          left: -20%;
        }
      }
      .border {
        i {
          // &:first-child {
          //   min-height: auto;
          // }
          // &:nth-child(2) {
          //   width: 100%;
          // }
          // &:nth-child(3) {
          //   right: 0;
          // }
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .kv {
    > p:first-child {
      top: 20%;
      left: 12%;
      white-space: normal;
      width: 83%;
      max-width: 100%;
      span {
        font-size: 7.2rem;
        &:first-child {
          left: -5rem;
        }
      }
      .border {
        &.border-left {
          i {
            &:first-child {
              min-height: 5rem;
            }
            &:nth-child(2) {
              //max-width: 300px;
            }
          }
        }
      }
    }
  }
  .about-page {
    .kv {
      background-image: url("../../assets/p2/p2-kv-wap.png");
    }
  }
  .invest-page {
    .kv {
      background-image: url("../../assets/p3/p3-kv-wap.png");
    }
  }
  .value-page {
    .kv {
      background-image: url("../../assets/p4/p4-kv-wap.jpg");
    }
  }
  .news-content {
    .kv {
      background-image: url("../../assets/p5/p5-kv-wap.png");
    }
  }
  .index-page {
      background-image: url("../../assets/p1/p1-kv-wap.jpg");
    .kv {
      > p:first-child {
        .border {
          top: -17%;
          left: -8%;
          i {
            &:first-child {
              // height: 100%;
            }

            &:nth-child(2) {
              // width: 50%;
            }

            &:nth-child(3) {
              right: 50%;
              // height: 25%;
            }
          }
        }
      }
    }
  }
}
</style>
