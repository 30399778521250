<template>
  <div class="com-content news-content">
    <div class="hot">
      <label>Hot Tag</label> <span>new</span> <span>good</span>
      <span>happy</span> <span>old</span> <span>happy</span>
      <div class="explain">We found 4 news for "new"</div>
    </div>
    <div class="search_list">
      <ul>
        <li class="">
          <div  @click="this.$router.push('/news/1')">
            <img src="../assets/p5/1.png" alt="" />
            <div class="border"><i></i><i></i><i></i></div>
          </div>
          <span>
            <p>April 19, 2022 / CBC</p>
            <h4>
              CBC successfully completes acquisition of five cardiovascular and
              metabolism drugs from Takeda
            </h4>
            <router-link :to="'/news/' + 1">Read More</router-link>
          </span>
        </li>
        <li class="">
          <div @click="this.$router.push('/news/1')">
            <img src="../assets/p5/1.png" alt="" />
            <div class="border"><i></i><i></i><i></i></div>
          </div>
          <span>
            <p>April 19, 2022 / CBC</p>
            <h4>
              CBC successfully completes acquisition of five cardiovascular and
              metabolism drugs from Takeda
            </h4>
            <router-link :to="'/news/' + 1">Read More</router-link>
          </span>
        </li>
        <li class="">
          <div @click="this.$router.push('/news/1')">
            <img src="../assets/p5/1.png" alt="" />
            <div class="border"><i></i><i></i><i></i></div>
          </div>
          <span>
            <p>April 19, 2022 / CBC</p>
            <h4>
              CBC successfully completes acquisition of five cardiovascular and
              metabolism drugs from Takeda
            </h4>
            <router-link :to="'/news/' + 1">Read More</router-link>
          </span>
        </li>
        <li class="">
          <div @click="this.$router.push('/news/1')">
            <img src="../assets/p5/1.png" alt="" />
            <div class="border"><i></i><i></i><i></i></div>
          </div>
          <span>
            <p>April 19, 2022 / CBC</p>
            <h4>
              CBC successfully completes acquisition of five cardiovascular and
              metabolism drugs from Takeda
            </h4>
            <router-link :to="'/news/' + 1">Read More</router-link>
          </span>
        </li>
        <li class="">
          <div @click="this.$router.push('/news/1')">
            <img src="../assets/p5/1.png" alt="" />
            <div class="border"><i></i><i></i><i></i></div>
          </div>
          <span>
            <p>April 19, 2022 / CBC</p>
            <h4>
              CBC successfully completes acquisition of five cardiovascular and
              metabolism drugs from Takeda
            </h4>
            <router-link :to="'/news/' + 1">Read More</router-link>
          </span>
        </li>
      </ul>
    </div>
    <!-- 加载更多 -->
    <div class="loading-more">
      <div class="circle">
        <div></div>
      </div>
      <br />{{ $t("loadMore") }}
    </div>
    <!-- <div class="page">
      <a href="#">1</a>
      <a href="#">2</a>
      <a href="#">3</a>
      <a href="#">4</a>
    </div> -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import wow from "wowjs";
export default defineComponent({
  name: "NewsDetail",
  data() {
    return {
      timeRange: [],
    };
  },
  mounted() {
    new wow.WOW({
      boxClass: "wow", //需要执行动画的元素的 class
      animateClass: "animated", //animation.css 动画的 class
      offset: 200, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备上执行动画
      live: true, //异步加载的内容是否有效
    }).init();
  },
});
</script>

<style scoped lang='less'>
.com-content {
  .hot {
    width: 96%;
    margin: auto;
    font-size: 1.4rem;
    margin-top: 6rem;
    span {
      background: #002742;
      color: #ffffff;
      height: 20px;
      line-height: 20px;
      border-radius: 20px;
      padding: 0 10px;
      margin-left: 1.5rem;
    }
    .explain {
      float: right;
    }
  }
  .search_list {
    width: 96%;
    margin: auto;
    ul {
      li {
        display: flex;
        justify-content: start;
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid #002742;
        position: relative;
        > div {
          margin-right: 30px;
          width: 20%;
          position: relative;
          cursor:pointer;
          &:after{
            content:'';
            position:absolute;
            top:0;
            width:100%;
            height:100%;
            background:rgba(0,0,0,0.3);
            opacity:0;
          }
          img {
            width: 100%;
            display: block;
          }
          .border {
            max-width: 100%;
            left: 0;
            top: 0;
          }
          &:hover {
            &:after{
            opacity:1;
              
            }
            .hover {
              opacity: 1;
            }
            .border {
              i:first-child {
                height: 25%;
                transition: all 0.2s 0.4s linear;
              }
              i:nth-child(2) {
                width: 100%;
                transition: all 0.2s 0.2s linear;
              }
              i:nth-child(3) {
                height: 100%;
                transition: all 0.2s linear;
                height: 100%;
              }
            }
          }
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 70px;
          height: 70px;
          background: #ffffff;
          border-radius: 70px;
          background-image: url(../assets/p5/newSearch-icon.png);
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: center center;
          display: none;
        }
        &:hover {
          &:after {
            display: block;
          }
        }
        span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            font-size: 1.4rem;
          }
          h4 {
            font-size: 23px;
            max-width: 830px;
          }
          a {
            color: #002742;
            border-bottom: 2px solid #24ddd2;
            width: fit-content;
            font-size: 1.7rem;
          }
        }
      }
    }
  }
  .page {
    text-align: center;
    margin-top: 8rem;
    padding-bottom: 8rem;
    a {
      padding: 0 8px;
    }
  }

  .loading-more {
    height: 230px;
    padding-top: 80px;
    text-align: center;
    box-sizing: border-box;
    font-weight: bold;
    line-height: 30px;
    font-size: 1.7rem;
    width: fit-content;
    margin: auto;
    cursor: pointer;
    .circle,
    .circle div {
      border: 2px solid #04dccb;
      border-top: 2px solid #ededed;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      display: inline-block;
      box-sizing: border-box;
    }
    .circle {
      transform: rotate(-30deg);
      div {
        width: 20px;
        height: 20px;
        margin-top: 3px;
        transform: rotate(160deg);
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .com-content {
    .hot {
      font-size: 2rem;
      margin-top: 10rem;
      label {
        display: block;
        line-height: 10rem;
      }
      span {
      }
    }
    .list {
      ul {
        li {
          flex-direction: column;
          &:after {
          }
          &:hover {
            &:after {
              display: none;
            }
          }
          img {
            width: 100%;
          }
          span {
            p {
              font-size: 2rem;
              margin-top: 2rem;
            }
            h4 {
              font-size: 3rem;
            }
            a {
              font-size: 2rem;
            }
          }
        }
      }
    }
    .page {
      a {
      }
    }
  }
}
</style>
