<template>
  <div class="com-content invest-page">
    <kv :slogan="4"></kv>
    <div class="p6">
      <p class="common-tit tit">{{ $t("p2Tit4") }}</p>

      <div class="top">
        <div class="left">
          <p class="wow animate__animated animate__slideInUps">
            {{ $t("contactUsP1Type") }}
          </p>
          <p class="tit wow animate__animated animate__slideInUps">{{data.locationsList[0].cityName}}</p>
          <i class="wow animate__animated animate__slideInUps"></i>
          <p class="wow animate__animated animate__slideInUps" v-html="data.locationsList[0].add">
          </p>
        </div>
        <div class="right">
          <img src="../assets/p2/p6-pic1.png" class="pc" alt="" />
          <img src="../assets/p2/p6-pic1-wap.jpg" class="wap" alt="" />
        </div>

      </div>

      <div class="bottom wow animate__animated animate__slideInUps">
        <template v-for="(item,index) in data.locationsList">
            <div class="item"  v-bind:key="index" v-if="index != 0">
              <img :src="item.imgUrl" alt="">
              <div class="hover">
                <p class="tit">{{ item.cityName}}</p>
                <i></i>
                <div class="border fixed"><i></i><i></i><i></i></div>
                <p v-html="item.add"></p>
              </div>
            </div>
        </template>
      </div>

    </div>
    
    <div class="p6 contact-us">
      <p class="common-tit tit">{{ $t("contactUsP2Title") }}</p>
      <div class="top">
        <div class="left" v-html="data.contentUs.title">
        </div>
        <div class="right">
          <img :src="data.contentUs.imgUrl" alt="" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import $ from "jquery";
import Kv from "@/components/element/Kv";
import wow from 'wowjs'

import {getContact} from "../api/common"
export default defineComponent({
  name: "InvestmentStrategy",
  components: { Kv},
  data() {
    return {
      data:{
        locationsList:[{}],
        contentUs:{}
      },
      timeRange: [],
      invesMenuActive:0,
      disabledStyle: {'opacity': .5},
    };
  },
  watch: {
    $route() {
      // 回到顶部
      if(this.$route.query.anchorpoint && $("."+this.$route.query.anchorpoint).length != 0 )
        $("html,body").animate({scrollTop:$("."+this.$route.query.anchorpoint).offset().top - 50}, 800);
    },
  },
  mounted() {
    this.handleClick();
    new wow.WOW({
      boxClass: "wow", //需要执行动画的元素的 class
      animateClass: "animated", //animation.css 动画的 class
      offset: 200, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备上执行动画
      live: true, //异步加载的内容是否有效
    }).init();
    setTimeout(() =>{
      if(this.$route.query.anchorpoint && $("."+this.$route.query.anchorpoint).offset().top )
        $("html,body").animate({scrollTop:$("."+this.$route.query.anchorpoint).offset().top - 50}, 800); 
    },400)
    this.getContact()
  },
  methods: {
    handleClick() {
      $(".open-carl,.carl .tit").mouseenter(function () {
        $(this)
          .parents(".carl")
          .addClass("open")
          .siblings()
          .removeClass("open");
      });
      $(".close-carl").click(function () {
        $(this).parents(".carl").removeClass("open");
      });
    },
    invesMenu(e){
      this.invesMenuActive = e;
      $(".carl").removeClass("open");
      setTimeout(function(){
        $(".open-carl,.carl .tit").mouseenter(function () {
          $(this)
            .parents(".carl")
            .addClass("open")
            .siblings()
            .removeClass("open");
        });
      })
    },
    getContact(){
      getContact().then(res => {
        this.data = res.info
      })
    },
  },
});
</script>

<style scoped lang="less">
@import "../style";
.p6 {
  .common-tit {
    margin-top: 9rem;
    margin-bottom:5rem;
    padding-left: 35px;
  }
  .top {
    display: flex;
    color: #fff;
    .left {
      width: 45%;
      background: @baseBgColor url("../assets/p2/p6-icon.png") no-repeat;
      background-position: -20% 110%;
      background-size:40%;
      line-height:4rem;
      p {
        margin-left: 3.25rem;
        &:first-child {
          margin-top: 4.5rem;
        }
        &:nth-child(2) {
          font-size: 4.8rem;
          margin-bottom: 2.75rem;
        }
        &:last-child {
          margin-left: 58%;
          margin-top: 35%;
          line-height: 2;
          font-size:2rem;
        }
      }
      i {
        display: block;
        width: 4rem;
        height: 2px;
        background: #fff;
        margin-left: 3.25rem;
      }
    }
    .right {
      width: 55%;
      > img {
        width: 100%;
        vertical-align: text-bottom;
      }
    }
  }
   .bottom {
    margin: 2rem auto;
    width: 97%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top:3rem;
    .item {
      position: relative;
      width:32%;
      margin-bottom:3rem;
      > img {
        width: 100%;
        display: block;
      }
      .hover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #0f62b0;
        color: #fff;
        transition: all 0.5s;
        opacity: 0;
        > i {
          display: block;
          width: 4rem;
          height: 2px;
          background: #fff;
          margin-left: 6rem;
          margin-top: 2.25rem;
          margin-bottom: 6rem;
        }
        > p {
          margin-left: 6rem;
          &:first-child {
            font-size:3.6rem;
            margin-top: 2.5rem;
          }
          &:last-child {
            font-size:1.7rem;
            position:absolute;
            bottom:3rem;
          }
        }
        .border {
          i {
            &:first-child {
              height: 0%;
              left: 4px;
              top: calc(20% - 4px);
              bottom: auto;
            }
            &:nth-child(2) {
              width: 0;
              top: auto;
              left: 4px;
              bottom: 4px;
            }
            &:last-child {
              height:0%;
              right: 4px;
              top: auto;
              bottom: 4px;
            }
          }
        }
      }
      &:hover {
        .hover {
          opacity: 1;
          .border {
            i:first-child {
              height: 80%;
              transition: all 0.2s linear;
            }
            i:nth-child(2) {
              width: calc(100% - 8px);
              transition: all 0.2s 0.2s linear;
            }
            i:nth-child(3) {
              height: 20%;
              transition: all 0.2s 0.4s linear;
            }
          }
        }
      }
    }
  }
}
.p6.contact-us{
  background:#e7e7e7;
  padding-top:1px;
  margin-top:8rem;
  padding-bottom:10rem;
  .left{
    padding-left:4rem;
    padding-top:3rem;
    background: @baseBgColor url("../assets/p2/contact_us.png") no-repeat !important;
    background-position:right bottom !important;
    font-size:1.7rem;
    h6{
      font-size:1.8rem;
      line-height:2rem;
      margin:2rem 0;
      margin-top:5rem;
      font-weight:bold;
      box-sizing: border-box;
    }
  }
}
@media screen and (max-width: 960px) {

.p6 {
    .common-tit {
      padding-left: 3.5rem;
      margin-top:10rem;
    }
    .top {
      flex-direction: column;
      .left {
        width: 100%;
        background-size:35rem;
        height: 73rem;
        position: relative;
        line-height:6rem;
        box-sizing:border-box;
        p {
          &:first-child {
            font-size: 2rem;
            margin-top:8rem;
          }
          &:nth-child(2) {
          }
          &:last-child {
            font-size: 2.5rem;
            position: absolute;
            bottom: 7rem;
            right:3.5rem;
          }
        }
        i {
        }
      }
      .right {
        width: 100%;
        > img {
        }
      }
    }
    .bottom {
      flex-direction: column;
      margin-top:12rem;
      margin-bottom:0;
      padding-bottom:12rem;
      .item {
        width: 100%;
        margin-top: 4rem;
        > img {
        }
        .hover {
          > i {
            margin-top:4rem;
          }
          > p {
            font-size: 2rem;
            &:first-child {
              margin-top:6rem;
            }
            &:last-child {
              bottom:5rem;
              font-size:2.6rem;
              width:60%;
            }
          }
          .border {
            i {
              &:first-child {
              }
              &:nth-child(2) {
              }
              &:last-child {
              }
            }
          }
        }
        &:hover {
          .hover {
            .border {
            }
          }
        }
      }
    }
  }

}
</style>
