<template>
  <div class="new-detaill">
    <div class="back" @click="back">
      <span>
      <img src="../assets/p5/back-icon.png" alt="" /> {{$t('back')}}
      </span>
    </div>
    <article class="news-content" v-if="currentPerson">
      <div class="left">
        <h1 class="tit tit-h2">{{currentPerson.name}}</h1>
        <h3 class="tit tit-h3">{{currentPerson.job}}</h3>
        <div class="tag">
        </div>
        <img :src="currentPerson.img" alt="" />
      </div>
      <div class="right">
        <div>
          <p  v-html="currentPerson.detail"></p>
        </div>
      </div>
    </article>
    <div class="list">
      <div class="swich-page" v-if="!$store.state.isWap">
        <div class="pre">
          <img src="../assets/p5/back-icon.png" :style="swiperPrevDisabled ? disabledStyle : ''" alt="" @click="prevs" />
        </div>
        <div class="next">
          <img src="../assets/p5/back-icon.png" :style="swiperNextDisabled ? disabledStyle : ''"  alt="" @click="nexts" />
        </div>
      </div>
      <div class="news-list wow animate__animated animate__slideInUps">
        <swiper :slides-per-view="$store.state.isWap ? 2 : 4" :space-between="$store.state.isWap ? 20 : 50" @swiper="onSwiper"
          @slide-change-transition-end="swiperPcChange"
        >
          <swiper-slide v-for="item in personDataEn" v-bind:key="item.id">
            <div class="news-item">
              <div>
                <img :src="item.img" alt="" />
                <div class="hover">
                  <img src="../assets/news-arrow.png" alt="" />
                </div>
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <p>{{item.name}}</p>
              <p>{{item.job}}</p>
              <router-link :to="'/person/' + item.id" class="read-more">{{
                $t("more")
              }}</router-link>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="pagePoint" v-if="$store.state.isWap">
        <template v-for="(item,index) in [1,2,3,4,5,6,6,6]" v-bind:key="index">
          <li :class="{active:swiper.activeIndex==index}"></li>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.less";
export default defineComponent({
  name: "NewsDetail",
  components: {
    Swiper,
    SwiperSlide,
    // maps,
  },
  data() {
    return {
      timeRange: [],
      currentPerson: {},
      personDataZh: [
        {
          id: 1,
          name: '傅唯',
          job: '首席执行官',
          img: require('../assets/p2/p3-p1.png'),
          detail: '傅唯先生拥有超过18年的国际顶级私募股权投资基金实践经验。在2014年创办康桥资本前，他曾担任远东宏信投资部总经理、甲子股权私募基金合伙人及北京办公室首席代表等关键职位。在此之前，傅唯先生曾在淡马锡中国私募股权投资团队任职，是最早进入中国私募股权领域的专业人士之一。此外，他还曾在麦格理银行投资部、渣打私募股权基金及高盛任职，积累了丰富的投资及行业运营经验。<br>傅唯先生拥有新加坡南洋理工大学电子工程和工商管理一等荣誉学士学位，他也是新加坡教育部学者、世界青年总裁协会成员。'
        }, {
          id: 2,
          name: '张宏',
          job: '董事总经理兼人民币事业群首席执行官',
          img: require('../assets/p2/p3-p2.jpg'),
          detail: '张宏先生拥有超过20年的医疗健康领域投资、管理咨询和商务拓展经验。在加入康桥资本前，张宏先生在2013年至2018年担任KKR集团的董事总经理兼KKR Capstone亚太区联席主管，负责KKR集团在医疗健康领域的投资、交易执行与投资组合管理。2002年至2013年期间，张宏先生分别在罗兰·贝格咨询公司担任高级全球合伙人兼亚洲医疗保健和运营主管，并在麦肯锡公司担任医疗健康产业业务联席负责人。张宏先生在美国蔻伊学院（Coe College）获得物理学学士学位，并于2001年获得瑞士国际管理学院（IMD Business School）的工商管理硕士学位（MBA）。'
        },{
          id: 3,
          name: '蒋梦娇',
          job: '董事总经理兼风险投资负责人',
          img: require('../assets/p2/p3-p3.jpg'),
          detail: '蒋孟矫女士拥有超过18年的投资、M&A及投资银行经验。在2014年初作为共同创始人创立康桥资本之前，她曾任远东宏信投资部总监及COO，负责部门运营、风险管理以及预算的制定和执行。在此期间，她曾带领团队进行M&A和少数医疗服务方面的投资。在加入远东宏信前，她曾任ARC中国的董事总经理，并为公司设立了中国办公室。在ARC工作期间，她负责多个领域的各种投资，并负责领导ARC中国的政府关系工作。在加入ARC中国之前，她曾在上海的亚洲业务发展公司担任助理总监，在中国和日本的化工、消费品和制造业领域进行了大量的合资企业、收购和资产剥离交易。此前，她曾就职于纽约瑞银投资银行，从事M&A、IPO、债务和股票发行的发起和执行工作。她以优异的成绩从韦尔斯利学院（Wellesley College）毕业，主修经济和政治学。'
        },{
          id: 4,
          name: '康海华',
          job: '董事总经理兼康桥医疗基础设施产业建设平台首席执行官',
          img: require('../assets/p2/p3-p4.jpg'),
          detail: '康海华先生在房地产和私募股权房地产基金管理方面拥有超过17年的经验。在加入康桥资本之前，他是汇贯南丰投资管理有限公司的首席投资官，这是一家专注于大中华区的房地产投资管理平台，负责在中国大陆和香港寻找和收购新投资标的。在此之前，康总担任安祖高顿（亚洲）的董事，专注于中国和新加坡业务。他拥有复旦大学市场营销学学士学位和香港大学房地产专业的硕士学位。'
        },{
          id: 5,
          name: '康岚',
          job: '董事总经理兼投后管理负责人',
          img: require('../assets/p2/p3-p5.jpg'),
          detail: '康岚女士在医疗保健，运营，业务领导和管理咨询方面拥有20多年的经验。加入康桥资本之前，她是复星国际的执行董事兼高级副总裁，复星保险业务的董事长兼总裁，领导了八家保险公司的全球战略，并购以及投资后管理的制定和实施。她还曾担任过中国复星保德信人寿保险公司董事会主席以及美国Ameritrust保险集团董事长。她还是复星医药和复星联合健康保险等多家公司的非执行董事。在复星，她首先担任首席人力资源官，负责公司的人力资源战略制定，人力资源政策和流程改进以及绩效管理和激励计划的制定。她在帮助公司全球扩张，推动“全球本土化”人才战略（致力于发展企业精神和合伙企业文化）方面发挥了重要作用。在加入复星之前，康岚是Korn / Ferry International（KF）的高级客户合伙人。她成功为KF在中国大陆开发了生命科学行业的业务，为跨国公司和中国本地客户提供了猎头和领导力评估，人力资源咨询服务。在此之前，她在麦肯锡公司（McKinsey＆Company）中国担任了近五年的管理顾问。在她的早期职业生涯中，她还担任过肿瘤学研究科学家。康岚拥有宾夕法尼亚大学沃顿商学院医疗管理专业的MBA学位。她在中国浙江大学获得了生物科学与技术学士学位，并在美国路易斯安那州的杜兰大学获得了生物化学硕士学位。'
        },{
          id: 6,
          name: 'Michael Keyoung',
          job: '董事总经理兼信贷基金负责人',
          img: require('../assets/p2/p3-p6.jpg'),
          detail: 'Michael作为一名医生、投资人和企业高管，拥有20多年医疗领域经验。在加入康桥资本之前，Michael曾任Genexine Inc.总裁兼首席执行官。Genexine Inc.是在KOSDAQ上市的生物技术公司，开发免疫肿瘤学、代谢和自身免疫性疾病方面的创新生物制剂。他也是Portola Capital Partners的创始管理合伙人。Portola Capital Partners是一家专注于医疗的投资公司，被投企业包括Kite Pharma和Juno。他曾任Catalyst Biosciences（一家临床阶段生物技术公司）的总裁，以及美国风险投资公司Burrill & Company的亚洲区董事总经理和总裁，负责全球私有和上市公司的投资。在整个职业生涯中，他为礼来、博士伦、UCB、三星电子/三星生物制剂、绿十字（韩国）等领先的医疗公司在全球药物开发、商业策略、伙伴关系和向亚洲的区域扩张方面提供了建议。他在康奈尔大学威尔医学院（Cornell University Weill Medical College）和纪念斯隆凯特琳癌症中心（Memorial Sloan Kettering Cancer Center）获得了医学博士以及神经科学和神经病学博士学位，是霍华德休斯医学研究所（Howard Hughes Medical Institute）研究员，并在加州大学旧金山分校接受了临床外科培训。他在纪念斯隆凯特琳研究所和纽约洛克菲勒大学（Rockefeller University）担任生物医学研究员。他还曾在哈佛商学院高层管理教育课程上学习了私募股权投资和风险投资。'
        },{
          id: 7,
          name: '李安妮 ',
          job: '董事总经理兼生物医药投资负责人（并购）',
          img: require('../assets/p2/p3-p7.jpg'),
          detail: '李安妮女士是一位经验丰富的国际商务主管，在美国、中国和东南亚地区的制药行业拥有超过15年的经验。她擅于商业战略、产品发布（尤其是肿瘤学和专科护理）和产品组合转型。2019年，她曾任新加坡AsiaLabs的投资人和高级顾问。AsiaLabs是一家专注于东南亚IVD实验室整合的机构。在此之前，她在百时美施贵宝（BMS）工作了4年，曾任中国Opdivo部门负责人、商业卓越负责人和BMS台湾总经理(GM)。2012年-2015年，她担任罗氏前沿市场（缅甸、柬埔寨和老挝）GM，也是亚太区领导团队成员。2004年-2011年，她曾在诺华和强生担任过多种职务，其中包括神经科学和眼科领域的业务部门总监，以及专业护理特许经营的全球战略营销和亚太区域负责人。她在杜克大学（Duke University）获得了卫生领域管理专业的工商管理学硕士（MBA）学位（2004年）；在圣路易斯华盛顿大学（Washington University in St. Louis）获得了生物化学专业的BA学位（2002年）。'
        },{
          id: 8,
          name: '吕延翔',
          job: '董事总经理兼医疗器械及服务投资负责人',
          img: require('../assets/p2/p3-p8.jpg'),
          detail: '吕延翔先生拥有超过21年的私募股权投资管理、投资银行及医疗产业经验。在加入康桥资本之前，他曾任复星私募股权的总裁，管理30多名投资专业人士和15亿美元的私募股权投资，侧重医疗保健、消费和跨境交易。在加入复星私募之前，他曾任凯雷亚洲成长基金（一个私募股权投资平台）大中华地区主管和董事总经理，管理超过20亿美元资金，带领一个10人的专业团队，投资标的主要为大中华区中等规模的增长和收购交易。在凯雷任职之前，他曾是凯晨资产的联合创始人和合伙人，主要投资于成长期人民币交易。在凯晨任职之前，他曾任雷曼兄弟ASSG中国主管、淡马锡中国董事总经理等私募股权职务。他以优异的成绩获得芝加哥大学布斯商学院（Chicago Booth School of Business）工商管理学硕士（MBA）学位，犹他大学（University of Utah）生物化学硕士学位，中国科学技术大学生物学学士学位。'
        },{
          id: 9,
          name: '邢菲',
          job: '董事总经理兼生物医药投资负责人（孵化）',
          img: require('../assets/p2/p3-p2.jpg'),
          detail: '邢菲博士是一名经验丰富的企业家和投资家。她曾担任药明康德设立的股权投资平台WuXi healthcare Ventures（后更名为通和毓成）的合伙人，负责医疗器械、治疗学和数字医疗的投资，孵化了数字医疗初创公司云鹊医并担任该公司的首席执行官。在此之前，她曾担任德诚资本的执行董事以及在美敦力负责市场营销和新的商业机会。她还曾是纽约长老会医院的内科医生。邢菲拥有斯坦福大学的医学博士学位和哈佛大学的工商管理硕士学位。'
        },
      ],
      personDataEn: [
        {
          id: 1,
          name: 'Wei Fu',
          job: 'Chief Executive Officer',
          img: require('../assets/p2/p3-p1.png'),
          detail: 'Fu Wei has over 17 years of private equity experience. Before founding CBC in early 2014, he was most recently the General Manager of Far East Horizon’s Investments Group, where he recruited and managed over 12 investment professionals and invested over US$120m in various sectors. Prior to joining Far East Horizon, he was the head of Beijing office and partner at Themes Investment, managing over $200mm. At Themes, Fu Wei sourced and successfully led the execution of US$110m buyout of an environmental company, one of the largest buyouts in China environmental sector. Beijing Enterprise recently announced the acquisition of this company. He was one of the earliest private equity professionals in China when he joined Temasek in 2004, and participated in over US$2b private equity investments ranging from financial institutions, real estate, consumer to TMT. He later joined Macquarie Group, Standard Chartered Bank and Goldman Sachs with a focus on infrastructure, environment and utility sectors. Fu Wei graduated from Singapore’s Nanyang Technological University with a first class honors degree in Electrical Engineering and a minor in Business Administration. He is a scholar of Singapore\'s Ministry of Education. Fu Wei is a holder of the prestigious YPO membership (only available to individuals holding the top position of a qualifying company).'
        }, {
          id: 2,
          name: 'Matthew Chang',
          job: 'Managing Director and CEO of RMB Fund Cluster ',
          img: require('../assets/p2/p3-p2.jpg'),
          detail: 'Matthew has over 20 years of healthcare investing, management consulting and business development experience. Prior to joining CBC, Matthew was a Managing Director, Head of Shanghai Office at KKR, where he co-led the firm’s healthcare investment activities (formerly also Head of Greater China and Co-leader of KKR Capstone Asia-Pacific). He was mainly responsible for sourcing, leading deal execution and portfolio management in China. Prior to KKR, Matthew was a Senior Global Partner and Head of Asia Healthcare and Operations at Roland Berger Strategy Consultants. Prior to that, he held positions as a Managing Director of Restructuring at Alix Partners and a Strategy Director at Diageo (China) Limited. Matthew also spent seven years at McKinsey & Company and left the company in 2008 as an Associate Principal. During his time at McKinsey, he was responsible for leading the firm’s Greater China healthcare practice and helped build the healthcare business from scratch into a multimillion dollar business. Prior to that, Matthew served as a Managing Director of Business Development at Portalhands China Corporation in Shanghai, and began his career as a Co-Founder and Managing Partner of Dynason Company, a consulting firm. Matthew graduated with a Bachelors Degree of Mathematics and Physics from Coe College and obtained an MBA from IMD Business School.'
        },{
          id: 3,
          name: 'Meng Jiang',
          job: 'Managing Director and Head of Venture',
          img: require('../assets/p2/p3-p3.jpg'),
          detail: 'Meng has 18 years of investment, M&A and investment banking experience. Prior to co-founding CBC in early 2014, Meng was most recently Director/COO at Far East Horizon’s Investments Group where she was responsible for department operations, risk management, and establishing and implementing budgets. In the past 2 years, Meng has led teams on M&A and minority healthcare services investments. Prior to joining Far East Horizon, Meng was Managing Director at ARC China, where she established its China operations. While at ARC, Meng headed various investments across several sectors and also led ARC China’s government relations effort. Prior to joining ARC China, Meng was an Associate Director at Business Development Asia in Shanghai where she executed numerous JV, acquisition and divestiture transactions in the chemical, consumer, and manufacturing sectors in China and Japan. Meng started her career at UBS Investment Bank in New York, where she worked on origination and execution of M&A, IPO, Debt and Equity issuances. Meng graduated with honors from Wellesley College, majoring in Economics and Political Science.'
        },{
          id: 4,
          name: 'Hans Kang',
          job: 'Managing Director and CEO of Healthcare Infrastructure Platform',
          img: require('../assets/p2/p3-p4.jpg'),
          detail: 'Hans has over 18 years experience in real estate and private equity real estate fund management. Prior to CBC, Hans was CIO of InfraRed NF Investment Advisors, a Greater China focused real estate investment management platform, oversees the investments and portfolio management. Before that, Hans was a Director at Angelo Gordon (Asia) with a focus on business origination in China and Singapore. Before Angelo Gordon, he performed various investment focused roles for Jones Lang LaSalle in China and Australia having joined them as an Asia Pacific graduate trainee. Hans holds a Bachelor’s Degree with honor in Marketing from Fudan University and a Master’s Degree in Real Estate Investment & Finance from the University of Hong Kong.'
        },{
          id: 5,
          name: 'Lan Kang',
          job: 'Managing Director and Head of Portfolio Management ',
          img: require('../assets/p2/p3-p5.jpg'),
          detail: 'Lan Kang has over 20 years of experience in healthcare, operations, business leadership and management consulting. At CBC, Lan heads the portfolio management function and currently serves of the boards of CBC portfolio companies such as I-MAB Biopharma (NASDAQ: IMAB) and Avantor (NYSE: AVTR). Prior to joining CBC in 2020, she she was Executive Board Director and Senior Vice President of Fosun International (HKEX: 0656). She also held the role of non-executive Board Director at a number of healthcare related companies, including Fosun Pharma (HKEX: 2196) and Fosun United Health Insurance. Ms. Kang started her career as an oncology research scientist in the US, and later worked as a Senior Client Partner with Korn Ferry and a Management Consultant at McKinsey & Company in China. She also worked as a research scientist in oncology in her early career. Lan obtained her MBA degree in Healthcare Management with honors from The Wharton School of the University of Pennsylvania. She obtained a Bachelor’s degree in Biological Science and Technology from Zhejiang University in China, and a Master’s degree in Biochemistry from Tulane University, Louisiana, USA.'
        },{
          id: 6,
          name: 'Michael Keyoung',
          job: ' Managing Director and Head of Royalty-Bridge',
          img: require('../assets/p2/p3-p6.jpg'),
          detail: 'Michael has over 20 years of experience as a physician, investor and a healthcare executive. At CBC, Michael has led investments in biopharma companies and currently serves on the boards of CBC portfolio companies such as AffaMed Therapeutics, InxMed and Hugel Inc.  Michael launched R-Bridge Fund strategy at CBC and opened US Headquarter in NYC.  Prior to joining CBC, Michael was President and CEO of Genexine Inc., KOSDAQ listed biotech company developing innovative biologics in immuno-oncology, metabolic and autoimmune diseases. He is Founding Managing Partner of Portola Capital Partners, a healthcare-dedicated investment firm. He was previously also President of Catalyst Biosciences, a clinical stage biotech company and Managing Director and head of Pan-Asia of US venture capital firm Burrill & Company where he oversaw investments in private and public companies globally. Throughout his career, he has advised leading healthcare companies such as Eli Lilly, Bausch & Lomb, UCB, Samsung Electronics/ Samsung Biologics, and Green Cross in global drug development, commercial strategies and partnerships or their regional expansion to Asia. Michael received his Medical Doctorate and Ph.D. in Neuroscience and Neurology from Cornell University Weill Medical College and Memorial Sloan Kettering Cancer Center. He was a Howard Hughes Medical Institute Research fellow and received his clinical surgical training at the University of California, San Francisco. He did his biomedical fellowship at the Memorial Sloan Kettering Institute and at Rockefeller University in New York and also studied Private Equity and Venture Capital at Harvard Business School Executive Education.'
        },{
          id: 7,
          name: 'Annie Lee ',
          job: 'Managing Director and Co-head of Pharma',
          img: require('../assets/p2/p3-p7.jpg'),
          detail: 'Annie is a seasoned International Commercial executive with over 19 years experience in the Pharmaceutical industry spanning the US, China and SE Asia. Annie’s specializes in commercial strategy, product launch (especially in Oncology and Specialty care) and portfolio transformation. Most recently in 2019, Annie became an investor and senior advisor to AsiaLabs in Singapore, a SE Asian focused IVD Lab consolidation vehicle. Prior to this, Annie spent 4 years with BMS where she was China Opdivo BU head, Commercial Excellence Lead and BMS Taiwan GM. Between 2012-2015, Annie was the Roche GM for frontier markets (Myanmar, Cambodia and Laos) and a member of the Asia Pacific Leadership Team. During 2004-2011, Annie worked for Novartis and J&J in various roles culminating as BU Director for the Neuroscience and Ophthalmology and including Global Strategic Marketing and Asia Pacific Regional Lead roles for specialty care franchises. Annie earned her MBA specializing in Health Sector Management from Duke University (2004); BA in Biochemistry from Washington University in St. Louis (2002).'
        },{
          id: 8,
          name: 'Sean Lu',
          job: 'Managing Director and Head of Medtech and Services',
          img: require('../assets/p2/p3-p8.jpg'),
          detail: 'Sean has over 20 years of private equity, investment banking and healthcare industry experience. Prior to joining CBC, he was most recently the President of Fosun Private Equity, where he managed over 30 investment professionals and US$1.5b private equity investments, with a focus on healthcare, consumer and cross-border transactions.  Prior to Fosun PE, he was Head of China and Managing Director for Carlyle Asia Growth Fund, a private equity platform managing over US$2b, leading a team of 10 professionals, investing in mid-sized growth and buyout transactions in Greater China.  Prior to Carlyle, Sean was a co-founder and partner of Kaisen Capital, primarily investing in growth stage RMB transactions.  Prior to Kaisen, he was Head of China for Lehman Brothers Asia Special Sits Group, Managing Director of Temasek China, among other private equity roles. Sean holds an MBA from University of Chicago Booth School of Business with High Honors, a Master’s Degree in Biochemistry from University of Utah, and a Bachelor’s Degree in Biology from University of Science and Technology of China.'
        },{
          id: 9,
          name: 'Fay Xing',
          job: 'Managing Director and Co-head of Pharma',
          img: require('../assets/p2/p3-p9.jpg'),
          detail: 'Fay has over 15 years experience as n healthcare investor and entrepreneur. Prior to CBC, Fay was the Partner at WuXi healthcare Ventures (later 6 Dimensions Capital) where she both invested in medtech, therapeutics and digital health startups, and incubated new companies including digital health startup PICA Health, where she most recently served as CEO. Prior to that, Fay was principal at US-China cross border VC firm Decheng Capital and worked in marketing and new ventures at Medtronic. Fay is a trained physician and did her residency at New York Presbyterian Hospital. Fay received a MD from Stanford Medical School and an MBA from Harvard Business School. '
        },
      ],
      lang: '',
      currentId: '1',
      swiper: '',
      disabledStyle: {'opacity': .5,'pointer-events':'none'},
      swiperPrevDisabled: true,
      swiperNextDisabled: false,
    };
  },
  computed: {
    listenComponentState() {
      return this.$store.state.lang
    }
  },
  watch: {
    listenComponentState: function (val) {
      if(val !== this.lang) {
        this.lang = this.$store.state.lang;
        this.handleInfo()
      }
    },
    $route: function(val) {
      this.currentId = val.params.id;
      this.handleInfo()
    }
  },
  mounted() {
    this.currentId = this.$route.params.id;
    this.lang = this.$store.state.lang
    this.handleInfo()
  },
  methods:{
    prev(){
      let id = Number(this.currentId) - 1;
      if(id < 1) id = 1;
      this.$router.push({
        path: `/person/${id}`
      })
    },
    next(){
      let id = Number(this.currentId) + 1;
      if(id > 9) id = 9;
      this.$router.push({
        path: `/person/${id}`
      })
    },
    back(){
      this.$router.back()
    },
    onSwiper(swiper) {
      // console.log(swiper);
      this.swiper = swiper;
    },
    swiperPcChange(e){

      this.swiperNextDisabled = false;
      this.swiperPrevDisabled = false;
      if(e.activeIndex === 0)this.swiperPrevDisabled = true
      if(e.activeIndex === 5)this.swiperNextDisabled = true
    },
    prevs(){
      this.swiper.slidePrev();
    },
    nexts(){
      this.swiper.slideNext();
    },
    handleInfo() {
      let data = [];
      if(this.lang === 'EN') {
        data = this.personDataEn
      } else if(this.lang === 'CN') {
        data = this.personDataZh
      }
      data.forEach( val => {
        if(val.id+'' === this.currentId) {
          this.currentPerson = val;
        }
      })
      // console.log(this.currentPerson);
    }
  }
});
</script>

<style scoped lang='less'>
.new-detaill {
  width: 100%;
  margin: auto;
  .back {
    background: #e7e7e7;
    font-weight: bold;
    padding: 0 34px;
    padding-top: 80px;
    box-sizing: border-box;
    font-size: 2rem;
    span {
      cursor: pointer;
    }
    img {
      width: 25px;
      margin-right: 0.3rem;
    }
  }
  .news-content {
    display: flex;
    justify-content: space-between;
    background: #e7e7e7;
    padding: 0 34px;
    box-sizing: border-box;
    .left {
      width: 25%;
      padding-bottom: 130px;
      > img {
        width: 100%;
      }
      h1 {
        margin-top: 30px;
        font-weight: normal;
        margin-bottom:1rem;
      }
      h3{
        margin:0;
        font-weight: normal;
      }
      .tag {
        border-bottom: 1px solid #183763;
        padding-bottom: 20px;
        margin-bottom: 3rem;;
        font-size: 1.4rem;
        span {
          background: #183763;
          color: #ffffff;
          line-height: 20px;
          height: 20px;
          border-radius: 20px;
          padding: 0 10px;
          margin-right: 10px;
        }
      }
      time {
        font-size: 3.6rem;
        font-weight: bold;
        display: block;
        margin: 1rem 0;
      }
      span.author {
        display: block;
        font-weight: bold;
        font-size: 1.4rem;
      }
    }
    .right {
      width: 50%;
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 600;
      margin-top:38px;
      >div{
        height: 68rem;
        overflow-y: auto;
        overflow-x: hidden;
        color: #183763;
        padding-right: 7rem;
        &::-webkit-scrollbar {
          width: 2px;
          background: hsla(0, 0%, 84.7%, 0.5);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #04dccb;
        }
        img,
        video {
          max-width: 100%;
        }
        p {
          margin-bottom: 20px;
        }
      }
    }
  }
  .swich-page {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
    background: #ededed;
    padding-top: 3rem;
    div {
      cursor:pointer;
      img {
        width: 30px;
      }
      padding: 30px;
    }
    .next {
      img {
        transform: scale(-1);
      }
    }
  }
  .list{
    background:#ededed;
    padding-bottom:10rem;
  }
  .news-list{
    width:96%;
    margin:auto;
  }
  .news-item {
    > div {
      cursor:pointer;
      position: relative;
      > img {
        width: 100%;
        display: block;
      }
      .hover {
        transition: all 0.5s;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        opacity: 0;
        > img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width:7.2rem;
          height:7.2rem;
        }
      }
      .border{
        max-width:100%;
        i:nth-child(1):first-child {
          height: 0;
          width: 4px;
          top: 0;
          left: 0;
        }
        i:nth-child(2) {
          width: 0;
          height: 4px;
          top: 0;
          right: 0;
        }
        i:nth-child(3) {
          height: 0;
          width: 4px;
          right: 0;
          bottom: 0;
        }
      }
      &:hover{
        .hover {
          opacity: 1;
        }
        .border{
          i:first-child {
            height: 25%;
            transition:all 0.2s 0.4s linear;
          }
          i:nth-child(2) {
            width: 100%;
            transition:all 0.2s 0.2s linear;
          }
          i:nth-child(3) {
            height: 100%;
            transition:all 0.2s linear;
            height:100%;
          }
        }
      }
    }
    > p {
      &:nth-child(2) {
        font-size: 2rem;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      &:nth-child(3) {
        font-size: 1.4rem;
        line-height: 1.5;
        margin-bottom: 50px;
        height: 4rem;
        overflow: hidden;
      }
      &.read-more {
        border-bottom: 2px solid #04dccb;
        display: inline-block;
      }
    }
  }
  .arrows {
    width: 100%;
    margin-top: 100px;
    padding-bottom: 100px;
    text-align: right;
    img {
      width: 40px;
      cursor:pointer;
      &:first-child {
        margin-right: 56px;
      }
      &:last-child {
        margin-right: 240px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .new-detaill {
    .back {
      font-size: 2.6rem;
      font-weight: normal;
      padding-left: 3.5rem;
      > img {
      }
    }
    .news-content {
      flex-direction: column;
      padding:0;
      .left {
        width: auto;
        padding:0 4rem;
        > img {
        }
        h1 {
          font-size: 4rem;
          font-weight: normal;
        }
        .tag {
          font-size: 2rem;
          span {
            padding: 0.4rem 1rem;
          }
        }
        time {
        }
        span.author {
          font-size: 2rem;
        }
      }
      .right {
        width: auto;
        overflow: auto;
        padding-right: 1rem;
        font-size: 2.3rem;
        font-weight: normal;
        background:#ededed;
        padding-top:10rem;
        margin-top:10rem;
        padding-left:4rem;
        padding-right:4rem;
        margin-bottom:10rem;
        padding-bottom:10rem;
        >div{

        }
        &::-webkit-scrollbar {
        }

        &::-webkit-scrollbar-thumb {
        }
        img,
        video {
        }
        p {
        }
      }
    }
    .swich-page {
      padding: 0;
      justify-content: center;
      div {
        img {
        }
      }
      .next {
        img {
        }
      }
    }
    .list {
      padding: 0;
      padding-bottom:10rem;
      >div{
        width:91%;
      }
      ul {
        width: 92%;
        margin: auto;
        padding-bottom: 5rem;
        li {
          p {
            font-size: 2rem;
            margin-top: 1rem;
          }
          width: auto;
          img {
          }
          h4 {
          }
          a {
            font-size: 2rem;
          }
        }
      }
      .news-item{
        p:nth-child(2){
          font-size:3rem;
          margin-bottom:1rem;
    margin-top: 1rem;
        }
      }
    }
  }
    .new-detaill .news-item > p:nth-child(3){
      font-size:2rem;
    height: 6rem;
    margin-bottom: 6rem;
    }
}
</style>
