<template>
  <div class="com-content news-content">
    <div class="newPage">
      <kv :slogan="3"></kv>
      
      <!-- 执行 || 操作 -->
      <div class="operation">
        <div class="tag-type">
          <ul>
            <li
              :class="{ active: tagTypeActive == 0 }"
              @click="typeClick(0)"
            >
              {{ $t("p5TypeText") }}
            </li>
            <li
              v-for="item in typeList.news_type"
              v-bind:key="item.id"
              :class="{ active: tagTypeActive == item.id }"
              @click="typeClick(item.id)"
            >
              {{ item.type_name }}
            </li>
          </ul>
        </div>
        <div class="search">
          <div class="year" @click="clickSearchInput">
            <input type="text" :class="{'active':yearActive}" @blur='yearInputBlur()' @focus='yearInputFocus()' v-model="yearText" :placeholder="$t('p5SearchInfo1')"  />
            <span class="icon" :class="{active:yearActive}"></span>
            <ul v-if="yearActive">
              <li
              v-for="item in reversedPdfList(typeList.news_date)"
              v-bind:key="item" @click="yearSelect(item)">{{item}}</li>
            </ul>
          </div>
          <div class="text">
            <input type="text" :class="{active:textActive}" @blur='textInputBlur()' @focus='textInputFocus()' v-model="text" :placeholder="$t('p5SearchInfo2')" />
            <span class="icon" @click="searchClick()"></span>
          </div>
        </div>
      </div>



      <!-- 新闻列表 -->
      <div class="list" v-if="!searchDom">
        <ul>
          <template v-for="(item, index) in nowList" v-bind:key="index">
            <li
              :class="{ recommend: index == 0 }"
              class="wow animate__animated animate__slideInUps"
            >
              <div @click="this.$router.push('/news/' + item.id)">
                <img :src="item.news_image_url" alt="" />
                <div class="hover">
                  <img src="../assets/news-arrow.png" alt="" />
                </div>
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <span>
                <p>{{ item.release_time }} / {{ item.news_type }}</p>
                <h4>{{ item.news_title }}</h4>
                <router-link :to="'/news/' + item.id">{{
                  $t("more")
                }}</router-link>
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div class="com-content news-content" v-if="searchDom">
        <div class="hot">

          <label>Hot Tag</label> 
          
          <span v-for="item in typeList.news_tag"
              v-bind:key="item.id" @click="tagClick(item.id)">{{item.tag_name}}</span>


          <div class="explain">We found 4 news for "new"</div>
        </div>
        <div class="search_list">
          <ul>
            <li class="" v-for="(item, index) in nowList" v-bind:key="index">
              <div  @click="this.$router.push('/news/' + item.id)">
                <img :src="item.news_image_url" alt="" />
                <div class="border"><i></i><i></i><i></i></div>
              </div>
              <span>
                <p>{{ item.release_time }} / {{ item.news_type }}</p>
                <h4>
                  {{ item.news_title }}
                </h4>
                <router-link :to="'/news/' +  + item.id">Read More</router-link>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 加载更多 -->
    <div class="loading-more">
      <!-- <div class="circle">
        <div></div>
      </div>
      <br />{{ $t("loadMore") }} -->
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import kv from "./element/Kv.vue";
import wow from "wowjs";

import $ from "jquery";
import {getNewList, getNewTypeList} from '../api/new'
export default defineComponent({
  name: "News",
  components: {
    kv,
  },
  data() {
    return {
      timeRange: [],
      newsList: this.$store.state.newsContent[this.$store.state.lang],
      nowList: [],
      newsTypeId: 0,
      news_type:0,
      news_tag:0,
      runScroll:true,
      page:0,
      page_num:6,
      searchDom:false,
      searchList:[],
      searchText:'',
      searchYear:'',

      tagText:'',
      yearText: "",
      yearActive: false,
      text: "",
      textActive: false,
      tagTypeActive: 0,
      typeList: [],
    };
  },
  mounted() {
    this.getList();
    this.getTypeList();
    new wow.WOW({
      boxClass: "wow", //需要执行动画的元素的 class
      animateClass: "animated", //animation.css 动画的 class
      offset: 200, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备上执行动画
      live: true, //异步加载的内容是否有效
    }).init();
    // 滚动检测
    //window.addEventListener("scroll", this.handleScroll, true);
  },
  watch: {
    $route() {
      // 回到顶部
      if(this.$route.query.anchorpoint && $("."+this.$route.query.anchorpoint).length != 0 )
        $("html,body").animate({scrollTop:$("."+this.$route.query.anchorpoint).offset().top - 100}, 800); 
      else{
        this.searchDom = false;
        this.text='';
        this.yearText=''
      }
    },
  },
  methods: {
    reversedPdfList(data) {
      return data?.slice().reverse();
    },
    toDetaill() {
      window.scrollTo(0, 0);
    },
    typeClick(e) {
      this.tagTypeActive = e;
      this.nowList = [];
      this.news_type = e;
      this.getList()
    },
    tagClick(id){
      this.tagText = id;
      this.getList("tag")
    },
    searchClick(){
      this.searchDom = true;
      this.$router.push("/news?anchorpoint=operation");
      this.getList("search")
    },
    getList(type) {
      if(type == "search") this.nowList = [],this.tagText = '';
      if(type == 'tag')this.nowList = [];
      getNewList({
        news_type:this.news_type,
        news_tag:this.tagText,
        page:this.page,
        page_num:this.page == 0 ? this.page_num + 1 : this.page_num,
        title:this.text,
        year:this.yearText == 'All' ? '' : this.yearText,
      }).then(res=>{
        res.info.news.forEach(item =>{
          this.nowList.push(item)
        })
        //this.runScroll = true;
      })
    },
    handleScroll() {
          if(!this.runScroll) return;
          let scrollTop = document.documentElement.scrollTop;//滚动高度
            let clientHeight = document.documentElement.clientHeight;//可视高度
            let scrollHeight = document.documentElement.scrollHeight;//内容高度
            let footerHeight = document.getElementsByClassName('footer')[0].offsetHeight
          if(scrollTop + clientHeight >= scrollHeight-footerHeight){
            console.log('到底')
            this.runScroll = false;
            this.page++;
            this.getList();
          }
    },

    clickSearchInput() {
      //this.yearActive = true;
    },
    yearInputBlur() {
      setTimeout(() => {
        this.yearActive = false;
      }, 200);
    },
    yearInputFocus() {
      this.yearActive = true;
    },
    textInputBlur() {
      setTimeout(() => {
        this.textActive = false;
      }, 200);
    },
    textInputFocus() {
      this.textActive = true;
    },
    yearSelect(e) {
      this.yearText = e;
    },
    keyupSubmit() {
      document.onkeydown = () => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          this.searchValue();
        }
      };
    },
    getTypeList() {
      getNewTypeList().then((res) => {
        res.info.news_date.unshift("All")
        this.typeList = res.info;
      });
    },
    
  },
});
</script>

<style scoped lang="less">
.newPage {
  background: #ededed;
}
.container {
  .list {
    width: 96%;
    margin: auto;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      li {
        width: 31.3%;
        margin:0 1%;
        margin-top: 5rem;
        > div {
          position: relative;
          cursor: pointer;
          &:hover {
            .hover {
              opacity: 1;
            }
            .border {
              i:first-child {
                height: 25%;
                transition: all 0.2s 0.4s linear;
              }
              i:nth-child(2) {
                width: 100%;
                transition: all 0.2s 0.2s linear;
              }
              i:nth-child(3) {
                height: 100%;
                transition: all 0.2s linear;
                height: 100%;
              }
            }
          }
        }
        .hover {
          transition: all 0.5s;
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          top: 0;
          left: 0;
          opacity: 0;
          > img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 7.2rem;
            height: 7.2rem;
          }
        }
        .border {
          max-width: 100%;
          i:nth-child(1):first-child {
            height: 0;
            width: 4px;
            top: 0;
            left: 0;
          }
          i:nth-child(2) {
            width: 0;
            height: 4px;
            top: 0;
            right: 0;
          }
          i:nth-child(3) {
            height: 0;
            width: 4px;
            right: 0;
            bottom: 0;
          }
        }
        &.recommend {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          > div{
            margin-right: 3rem;
            max-width:50%;
            > img {
            width: 100%;
          }
          } 
          h4 {
            font-size: 3rem;
            margin: 10px 0;
          }
          span {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          a {
            width: fit-content;
            margin-top: 20px;
          }
        }
        p {
          font-size: 1.4rem;
          margin-top: 2rem;
        }
        img {
          width: 100%;
        }
        h4 {
          font-size: 2rem;
          margin: 10px 0;
          margin-bottom: 10px;
          height: 8rem;
        }
        a {
          font-size: 1.7rem;
          text-decoration: none;
          border-bottom: 2px solid #04dccb;
          color: #000000;
          margin-top: 4rem;
          display: block;
          width: fit-content;
        }
      }
    }
  }
  .loading-more {
    height: 230px;
    padding-top: 80px;
    text-align: center;
    box-sizing: border-box;
    font-weight: bold;
    line-height: 30px;
    font-size: 1.7rem;
    width: fit-content;
    margin: auto;
    cursor: pointer;
    .circle,
    .circle div {
      border: 2px solid #04dccb;
      border-top: 2px solid #ededed;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      display: inline-block;
      box-sizing: border-box;
    }
    .circle {
      transform: rotate(-30deg);
      div {
        width: 20px;
        height: 20px;
        margin-top: 3px;
        transform: rotate(160deg);
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .container {
    font-size: 2rem;
    .list {
      width: 92%;
      ul {
        flex-direction: column;
        li {
          width: 100%;
          margin-top: 10rem;
          &.recommend {
            flex-direction: column;
            >div{
              max-width:100%;
            }
            >img {
              width: 100%;
            }
            h4 {
            }
            span {
            }
            a {
              margin-top: 0rem;
            }
          }
          p {
            margin-top: 5rem;
            font-size: 2rem;
          }
          img {
          }
          h4 {
            font-size: 3.3rem;
            height: 15rem;
            overflow: hidden;
          }
          a {
            font-size: 2.5rem;
            margin-top: 1rem;
          }
        }
      }
    }
    .loading-more {
      .circle,
      .circle div {
      }
      .circle {
        div {
        }
      }
    }
  }
}
</style>

<style scoped lang='less'>
.com-content {
  .hot {
    width: 96%;
    margin: auto;
    font-size: 1.4rem;
    margin-top: 6rem;
    span {
      background: #002742;
      color: #ffffff;
      height: 20px;
      line-height: 20px;
      border-radius: 20px;
      padding: 0 10px;
      margin-left: 1.5rem;
      cursor:pointer;
    }
    .explain {
      float: right;
    }
  }
  .search_list {
    width: 96%;
    margin: auto;
    ul {
      li {
        display: flex;
        justify-content: start;
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid #002742;
        position: relative;
        > div {
          margin-right: 30px;
          width: 20%;
          position: relative;
          cursor:pointer;
          &:after{
            content:'';
            position:absolute;
            top:0;
            width:100%;
            height:100%;
            background:rgba(0,0,0,0.3);
            opacity:0;
          }
          img {
            width: 100%;
            display: block;
          }
          .border {
            max-width: 100%;
            left: 0;
            top: 0;
          }
          &:hover {
            &:after{
            opacity:1;
              
            }
            .hover {
              opacity: 1;
            }
            .border {
              i:first-child {
                height: 25%;
                transition: all 0.2s 0.4s linear;
              }
              i:nth-child(2) {
                width: 100%;
                transition: all 0.2s 0.2s linear;
              }
              i:nth-child(3) {
                height: 100%;
                transition: all 0.2s linear;
                height: 100%;
              }
            }
          }
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 70px;
          height: 70px;
          background: #ffffff;
          border-radius: 70px;
          background-image: url(../assets/p5/newSearch-icon.png);
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: center center;
          display: none;
        }
        &:hover {
          &:after {
            display: block;
          }
        }
        span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            font-size: 1.4rem;
          }
          h4 {
            font-size: 23px;
            max-width: 830px;
          }
          a {
            color: #002742;
            border-bottom: 2px solid #24ddd2;
            width: fit-content;
            font-size: 1.7rem;
          }
        }
      }
    }
  }
  .page {
    text-align: center;
    margin-top: 8rem;
    padding-bottom: 8rem;
    a {
      padding: 0 8px;
    }
  }

  .loading-more {
    height: 230px;
    padding-top: 80px;
    text-align: center;
    box-sizing: border-box;
    font-weight: bold;
    line-height: 30px;
    font-size: 1.7rem;
    width: fit-content;
    margin: auto;
    cursor: pointer;
    .circle,
    .circle div {
      border: 2px solid #04dccb;
      border-top: 2px solid #ededed;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      display: inline-block;
      box-sizing: border-box;
    }
    .circle {
      transform: rotate(-30deg);
      div {
        width: 20px;
        height: 20px;
        margin-top: 3px;
        transform: rotate(160deg);
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .com-content {
    .hot {
      font-size: 2rem;
      margin-top: 10rem;
      label {
        display: block;
        line-height: 10rem;
      }
      span {
      }
    }
    .list {
      ul {
        li {
          flex-direction: column;
          &:after {
          }
          &:hover {
            &:after {
              display: none;
            }
          }
          img {
            width: 100%;
          }
          span {
            p {
              font-size: 2rem;
              margin-top: 2rem;
            }
            h4 {
              font-size: 3rem;
            }
            a {
              font-size: 2rem;
            }
          }
        }
      }
    }
    .page {
      a {
      }
    }
  }
}
</style>

<style scoped lang="less">
.operation {
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin: auto;
  margin-top: 6rem;
  .tag-type {
    ul {
      display: flex;
      li {
        margin: 10px;
        margin-left: 0;
        margin-right: 3rem;
        padding-bottom: 2px;
        font-size: 2rem;
        cursor: pointer;
        &.active {
          border-bottom: 2px solid #04dccb;
        }
      }
    }
  }
  .search {
    display: flex;
    .year {
      position: relative;
      ul {
        position: absolute;
        top: 4rem;
        left: 3rem;
        background: #ffffff;
        width: 34rem;
        line-height: 5rem;
        font-size: 1.7rem;
        padding: 1rem 0;
        z-index: 11;
        li {
          padding: 0 2rem;
          cursor: pointer;
          &:hover {
            background: #dedede;
          }
        }
      }
      .icon {
        background-image: url("../assets/p5/drop.png");
        transition: transform 0.4s;
        &.active {
          transform: rotate(180deg);
        }
      }
      input {
        cursor: pointer;
      }
    }
    .text {
      .icon {
        background-image: url("../assets/p5/search.png");
        cursor:pointer;
      }
    }
    input {
      border: 0;
      border-bottom: 2px solid #002742;
      outline: none;
      background: none;
      margin-left: 3rem;
      width: 34rem;
      height: 4rem;

      &.active {
        border-bottom: 2px solid #04dccb;
      }
    }
    > div {
      position: relative;
    }
    .icon {
      width: 16px;
      height: 16px;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1.4rem;
      right: 2rem;
      top: 1.3rem;
    }
  }
}
@media screen and (max-width: 960px) {
  .operation {
    flex-direction: column;
    width: 92%;
    .tag-type {
      ul {
        flex-direction: column;
        font-size: 2.8rem;
        li {
          margin-left: 0;
          width: fit-content;
          font-size: 3rem;
          &.active {
          }
        }
      }
    }
    .search {
      justify-content: space-between;
      margin-top: 4rem;
      .year {
        ul {
          left: 0;
          li {
            font-size: 2.5rem;
          }
        }
      }
      > div {
        width: 46%;
      }
      .icon {
        background-size: 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
      }
      input {
        margin-left: 0;
        width: 100%;
        font-size: 2.8rem;
        padding-bottom: 3rem;
        &.active {
        }
      }
    }
  }
}
</style>