const ZH = {
    more: '查看更多',
    moreAbout:'关于康桥资本',
    header1: '首页',
    header2: '关于康桥',
    header2_1:'基金策略',
    header2_2:'我们的团队',
    header2_3:'我们的奖项',
    header2_4:'false',
    header3: '我们的⽣态',
    header3_1: '创造价值',
    header3_2: '医疗健康⽣态',
    header4: '新闻',
    header5: '联系我们',
    header5_1: '办公室',
    header5_2: '联系我们',
    slogan: '立足亚洲<br>放眼世界',
    kv1: '超越界限，<br>为所有人提供更好的医疗健康服务',
    kv2: '我们是坚定的<br/>投资-运营者同时也是持续创业者',
    kv3: '以超前的战略应对多变的全球医疗健康服务格局',
    kv4: '建设可持续的<br/>全球医疗健康生态系统',
    kv5: '新闻',
    videoLeft: '全球视野<br>医疗本无边界',
    p1tit2: '我们是新一代<br>医疗健康公司的<br>塑造者和孵化者',
    team: '我们的团队',
    p1tit3: '概览',
    p1tit4: '足迹',
    worldTit1: '全球总部',
    worldTit2: '办公室',
    worldTit3: '投资组合公司',
    // 首页地图地名
    homeMapAdd_Beijing:'北京',
    homeMapAdd_Seoul:'首尔',
    homeMapAdd_Tokyo:'东京',
    homeMapAdd_Shanghai:'上海',
    homeMapAdd_HongKong:'香港',
    homeMapAdd_Singapore:'新加坡',
    homeMapAdd_Boston:'波士顿',
    homeMapAdd_NewYork:'纽约',
    homeMapAdd_SanDiego:'圣地亚哥',
    homeMapAdd_SanFrancisco:'旧金山',
    homeMapAdd_AbuDhabi:'阿布扎比',
    mapBack:'返回世界地图',
    
    worldTip: '自由拖动，探索我们的足迹',
    p1tit5: '新闻',
    p1tit51: '全部新闻',
    toTop: '返回顶部',
    footContactUs:'联系我们',
    scrollTip: '向下滚动浏览更多内容',

    p2Tit1: '团队',
    p2Tit2: '超越界限，为所有人提供更好的医疗健康服务',
    p2ValueSub: '我们是新一代医疗健康公司的塑造者和孵化者。作为一家全球领先的医疗健康投资机构，我们与顶尖 科学家和企业家保持密切合作。我们保持对前沿技术的热情和敏锐，持续发现具有健康意义和市场潜 力的投资机会，汇集多方力量打造创新解决方案，只为推动全球健康事业高效率、高质量发展。',
    p2ValueSub1: '',
    p2ValueTit1: '开拓',
    p2ValueTit2: '实干',
    p2ValueTit3: '诚信',
    p2ValueTit4: '求真',
    p2ValueTit5: '包容',
    p2ValueDetail1: '我们以奋斗为本，做最 有企业家精神的创业 者。我们执着于对创新 的不懈追求，敢于突破 边界、挑战常规。我们 保持谦卑、持续学习， 不断进行自我挑战和自 我批判，持续提高效 率，坚持做难且对的事 情。因此，我们拥抱困 难，从困境中积累经验 并逆势成长。',
    p2ValueDetail2: '我们推崇行胜于言，勇 于尝试，敢为人先，用 行动实现创造价值。对 于有价值的创意和策 略，我们亲自上阵，推 动落地实施，并在实践 中观察、总结、反思， 以真实的市场洞察和投 资成果为导向，推进创 意的持续迭代。',
    p2ValueDetail3: '诚信是我们在日常工作 中的首要原则，也是我 们与客户和合作伙伴之 间互相信赖的基础。作 为产业创新的孵化者， 我们的责任远超投资收 益的获取。对于所有的 投资者、合作伙伴和团 队，我们始终遵守信 诺、保持公开透明。',
    p2ValueDetail4: '我们以对真理的持续追 求为荣。医疗健康产业 庞大复杂且快速变化， 因此我们不断增强能力 和认知，强调以理性、 客观、求真的态度研究 市场，全力预测市场变 化趋势，力求实现对市 场真相的深刻把握，并 以此指导我们的投资决 策。',
    p2ValueDetail5: '多元互补的团队是创新 和成功的基石。我们尊 重个性，珍视每一位员 工的价值，助力每一位 员工的成功。我们致力 于打造一个兼容并包的 工作家园，以吸引全球 不同背景的顶尖人才和 行业领袖。我们相信世 界顶级精英的互补协 作，将为我们带来最好 的想法和创新。 ',
    p2Tit3: '荣誉与奖项',
    awardsTit1: 'PEI 全球私募股权基金榜单',
    awardsTit2: 'AVCJ亚洲PE / VC 大奖',
    awardsTit3: 'PERE',
    awardsTit4: '清科',
    awardsSub1: '榜单中基于过去五年募资金额全球最大的私募股权基金之一',
    awardsSub2: '亚洲PE / VC',
    awardsSub3: '2021 大奖',
    awardsSub4: '中国私募股权机构排名',
    award11: '# 1<br>2022年全球医疗私募基金排行',
    award12: '# 1<br>2019/2020/2021/2022年专注亚洲的全球医疗健康私募股权基金',
    award13: '# 155<br>PEI 300 2022年全球私募股权基金排行',
    award21: '2019 年度最佳IPO项目',
    award22: '2019 年度最佳投资',
    award31: '# 2 <br>亚太年度另类投资者—康桥资本医疗设施平台',
    award41: 'Top 50 – 2021年 中国私募股权机构',
    award42: 'Top 30 - 2019 / 20 中国医疗健康领域投资机构',
    p2Tit4: '我们的办公室',

    p3Tit1: '我们的投资策略',
    p3Info1: '作为亚洲最大的医疗健康服务投资运营者，我们利用专业知识在亚洲乃至全球建立属于康桥资本自己的医疗健康生态系统。康桥资本独有的投资逻辑将企业家精神、科学创造力、运营管理和专家网络结合起来，以创建具有划时代意义的公司，满足全球未满足临床需求。',
    p3Info2: '亚洲医疗保健行业正处于历史拐点，人口结构逐渐改变，监管政策改革使越来越多的优质医疗药品和服务变得可及，创新人才和技术也越来越多的涌现。这些变化都为创新解决方案提供了投资的机会。',
    p3Info3: '在快速发展的医疗健康行业中，我们的优势在于足够灵活，通过多产品组合，我们运用收购、风险投资、信贷和基础设施服务帮助我们的投资组合在其全生命周期中实现平稳高效增长。',
    p3Tit2: '我们的投资策略',
    p3Tit21: '私募股权基金',
    p3Tit22: '风险投资基金',
    p3Tit23: '瑞桥信贷基金',
    p3Tit24: '医疗健康产业设施平台',
    p3Tit25: '旗舰基金',
    p3Tit26: '私募股权基金',
    p3Tit3: '部分项目概念设计和效果图',
    p4Tit1: '康桥资本— <br>共同价值创造',
    p4Tit2: '专注于投资组合<br>管理的团队',
    p4Tit3: '加速<br>全球扩张',
    p4Tit4: '连续<br>创业者',
    p4Tit5: '医疗健康生态系统',
    p4Tit6: '生物医药',
    p4Tit7: '医疗器械',
    p4Tit8: '医疗服务',
    p4Info1: '康桥资本充分了解医疗健康行业是一个全球性的业务，并建立了与之匹配的投资、商务发展和运营能力。' ,
    p4Info2: '我们的团队和我们的合作伙伴拥有丰富的相关经验和专业知识，他们中很多来自世界知名的生命科学研究机构、大型跨国制药和医疗保健公司，专注于推动创新和培育成功、长期运营的公司。',
    p4Sub1: '我们拥有一支顶尖的投资组合管理团队，在资本解决方案、财务、人力资源、激励方案设计和项目管理等多个领域积累了丰富的经验。',
    p4Sub2: '与投资团队的密切合作，结合对投资组合的深入了解，在加速投资组合公司的全球扩张中发挥了关键作用，将我们的投资组合与全球资本、人才和合作伙伴联系起来。',
    p4Sub3: '企业家精神是康桥资本的核心精神，自2014年成立以来，我们已经成功孵化了12家生命科学公司。康桥资本为我们的投资组合公司提供量身定制的产业孵化和运营模式，使其茁壮成长。',
    p5SearchInfo1:'年份',
    p5SearchInfo2:'请输入搜索的内容',
    p5TypeText:'全部',
    back:'返回',
    loadMore: '加载更多',
    // 首页中英文
    videoBottom: '打破全球医疗健康服务界限，康桥资本成立于2014年，是亚洲最大的专注于医疗健康领域的投资公司。我们是全球医疗健康平台的塑造者和建设者，我们专注于投资，孵化和运营。',
    personLeft: '“康桥资本与全球顶尖的科学家和企业家通力合作，我们独有的投资加运营模式可以赋能全球领先的医药健康公司提升对可支付医疗健康服务的可及性，促进创新，提高效率，以满足全球未满足临床需求。”',
    job1: '首席执行官',
    job2: '董事总经理',
    name1: '傅唯',
    name2: '张宏',
    name3: '将梦娇',
    name4: '康海华',
    name5: '康岚',
    name6: 'Michael Keyoung',
    name7: '李安妮',
    name8: '吕延翔',
    name9: '邢菲',
    p1p4tit1: '资产管理规模',
    p1p4tit2: '孵化公司',
    p1p4tit3: '全球办公点',
    p1p4tit4: '顶尖专业人才',
    p1p4bottom1: '资金管理规模',
    p1p4bottom2: '4家康桥自主孵化的<br>医疗健康公司',
    p1p4bottom3: '9个全球办公点',
    p1p4bottom4: '助力康桥资本打造生命健康生态系统',
    // 关于我们
    aboutInfoP1: '康桥资本致力于整合全球资源，创造价值，为所有人提供更好的医疗健康服务。通过与全球顶尖的科学家和企业家合作，康桥资本独有的投资加运营模式可以赋能全球领先的医药健康公司提升对可支付医疗健康服务的可及性，促进创新，提高效率，以满足全球未满足临床需求。',
    aboutInfoP2: '提升对可支付医疗健康服务的可及性，促进创新，提高效率，以满足全球未满足临床需求。康桥资本成立于2014年，总部位于新加坡，在上海、北京、香港和纽约都设有办公室，同时在波士顿、圣地亚戈、旧金山和东京均设有办事处。康桥资本拥有一支全球顶尖的投资、运营和管理团队，专注于平台建设、收并购、信贷和成长期股权投资机会。投资领域覆盖多个医疗健康相关领域，包括生物制药、医疗器械、医疗服务和医疗基础设置服务。',
    locationShanghai:'上海',
    locationShanghaiAdd:'中国上海市南京西路1266号恒隆广场1期62层, 200040 ',
    locationBeijing:'北京',
    locationBeijingAdd:'中国北京市朝阳区东三环中路5号财富金融中心8层，100020',
    locationXianggang:'香港',
    locationXianggangAdd:'香港中环干诺道中8号交易广场2期3306-3307室',
    ourPeopleTitle:'团队成员',
    ourPeopleContent:'As Asia’s largest healthcare investor-operator, we excel by bringing together a group of nimble and creative professionals who are entrepreneurial and passionate to build and shape healthcare champions not only in Asia but globally. We incorporate inclusion and diversity into all levels of our business. ',
    impact:'false',
    // 新闻
    time1: '2022-4-19',
    time2: '2022-4-18',
    time3: '2022-4-18',
    newsType1: '',
    newsType2: '康桥新闻 ',
    newsType3: '生态与合作伙伴',
    newsTit1: '康桥资本宣布完成对武田制药五个心血管及代谢领域药品的收购',
    newsTit2: '康桥资本旗下峨巍医药完成1.4亿美元融资，致力于创新型mRNA疫苗和药物的研发及产业化',
    newsTit3: '对话ENSEM Therapeutics总裁、首席科学家金圣芳： 给予女性多方面支持，充分发挥女性领导力。越来越多女性活跃在投资领域和生物医药行业，凭借她们的聪明才智、坚韧不拔以及敏锐的洞察力，为...',
    // investment
    investment1_info:'聚焦于药企/生物医药、医疗器械和医疗服务领域的控股型收购和平台投资机会',
    investment1_info2:'医疗健康产业设施平台',
    investment1_1_title:'优中选优合作',
    investment1_1:'通过与全球顶级的企业家和科学家合作，康桥资本私募股权基金通过独有的控股型投资运营模式，将技术创新和商业化的专业知识融于一体，为所有人提供高效和可支付的医疗解决方案',
    investemnt1_2_title:'专业技术驱动',
    investemnt1_2:'借助在医疗领域的专业知识形成差异化投资方法，从而建立有影响力和多样化的价值投资策略',
    investemnt1_3_title:'投资运营模式',
    investemnt1_3:'全面的投资案例和投资组合管理生态系统使康桥资本成为了优秀企业家、新贵行业利益相关者和经验丰富的管理者的可信赖伙伴',

    investment2_info:'private investment opportunities in the healthcare industry.聚焦与康桥资本主基金主打控股、孵化、并购战略差异化的少数股权投资，以充分覆盖医疗健康领域的投资机会瞄准早期和成长期阶段的创新和划时代技术平台',
    investment2_1_title:'关于我们',
    investment2_1:'我们是企业家在创业道路上的优质伙伴我们的团队来自投资结构和行业研究背景，具有互补的经验、专业知识和子行业覆盖,此外，团队利用康桥资本平台级的人脉网络和资源，拥有广泛的医疗健康子行业的专业知识',
    investment2_2_title:'投资逻辑',
    investemnt2_2:'高效迅速地识别中国医疗健康行业中有吸引力的子领域利用康桥资本的平台，识别具有高度独有的技术平台的新兴技术领导者,在生物技术/制药、医疗技术/ IVD/创新服务和数字卫生领域灵活部署资金，重点支持连续创业者在中国面向全球:寻找与中国有组织、有能力将商业化扩展到全球市场的公司医疗保健子部门多元化投资组合的构建 ',


    investment3_info:'<p>我们专注为领先的生物制药，医疗器械，以及医疗服务类公司提供定制化的非稀释债权融资；我们的融资可以以源自亚洲的授权特许费（royalty）或其它现金流为支持，也可以通过一系列灵活的另类融资结构实现。</p><p>对我们的标的公司而言，作为股权资金的替代或补充，我们着眼于为企业实现未来收入及现金流变现所将带来的战略价值，以满足一系列的近期需求（包括但不限于，研发，商务拓展，或是资本支出等）。</p><p>对我们的投资人而言，我们致力于网罗和构建一个以丰富的源自亚洲医疗健康行业的现金流及信贷资产为核心的差异化投资组合，创造经风险调整且与资本市场波动无关的具吸引力回报。</p>',
    investment3_1_title:'先行者',
    investment3_1:'我们是第一家，也是迄今为止唯一一家专注于提供以源自亚洲的授权特许费（Royalty）和其它现金流为支持的非稀释性战略资金，或其它结构性信贷融资方案的基金',
    investment3_2_title:'不受资本市场波动影响的投资战略',
    investment3_2:'我们的投资战略利用了持续发展的市场基本面因素（例如人口老龄化，扩张的中产阶级，以及有利创新的政策端改革等），使得为我们提供回报的增长<b>不受资本市场波动以及地缘政治不确定性的干扰</b>',
    investment3_3_title:'巨大的未满足需求',
    investment3_3:'我们改进了该种在成熟市场已被<b>广泛验证及量化拓展</b>的投资策略，以满足来自亚洲医疗健康市场的创新企业及授权方的<b>巨大且未被满足的资金需求</b>',


    investment4_info:'<p>致力于成为最大的独立一站式、一流的生命科学资产提供商，并通过向中国和亚洲顶尖生命科学城市和新兴生命科学企业租赁生命科学资产组合，提供具有吸引力的风险调整回报。</p><p>通过我们的”资本+专业”服务，我们可以为生命科学公司提供一流的研发和生产场地，资助和开发适合其整个发展生命周期的基础设施，或者提供售后回租安排，以改善其资本结构和运营效率。</p><p>我们的解决方案得到了广大生命科学企业的好评，这使他们可以精简资本、合理安排资源，专注于基础研究和技术开发，建立和拓展业务，为企业和社会提供更持久的价值</p>',
    investment4_1_title:'我们是谁',
    investment4_1:'我们不仅是场地提供者， 也是生态系统建设者<br/>我们拥有一支全面整合的团队，为我们的生命科学伙伴持续赋能',
    investment4_2_title:'我们做什么',
    investment4_2:'我们为一流的生命科学公司提供一站式的固定资产解决方案<br/>我们通过改善资本结构和运营效率来创造价值<br/>我们提供急需和可持续的场地，让生命科学创新和突破得以重新设想，造福数千万人',
    investment4_3_title:'加入我们的生态系统',
    investment4_3:'探索我们从生物技术孵化器、研发中心到大规模商业化生产设施的全生命周期固定资产解决方案',

    // 联系我们
    contactUsP1Type:'全球总部',
    contactUsP2Title:'联系我们',
    contactUsP2Email_1:"info{'@'}cbridgecap.com",
    contactUsP2Info_1:'For more information on CBC, please contact us at',
    contactUsP2Email_2:"cbchr{'@'}cbridgecap.com",
    contactUsP2Info_2:'If you would like to join us, please contact us at',

    // 成员管理
    teamPageName:'团队成员',
    teamSearch:'请输入搜索名称',
    teamName:'名字',
    teamTitle:'职位',
    teamUnit:'单位',
    teamLocation:'地址',
}

export default ZH
