import request from './index'

export function getNewTypeList(){
    return request({
        url:'newstypeapi/index',
    })
}
export function getNewList(_data){
    return request({
        url:'newsapi/index',
        data:_data,
    })
}
export function getNewContent(_data){
    return request({
        url:'newsapi/getinfo',
        data:_data,
    })
}