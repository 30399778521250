import request from './index'

export function getKv(){
    return request({
        url:'kvapi/index',
    })
}
// 首页内容
export function getHome(){
    return request({
        url:'homeapi/index',
    })
}
// about CBC
export function getAboutCbc(){
    return request({
        url:'aboutapi/index',
    })
}
// 生态系统
export function getEcology(){
    return request({
        url:'ecologyapi/index',
    })
}
// 联系我们
export function getContact(){
    return request({
        url:'contactapi/index',
    })
}
// 团队列表
export function getTeam(_data){
    return request({
        url:'teamapi/index',
        data:_data,
    })
}
// 团队分类
export function getTeamSelect(){
    return request({
        url:'teamselapi/index',
    })
}