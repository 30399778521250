import axios from 'axios'
import qs from 'qs'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'

// create an axios instance
const service = axios.create({
//   baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // baseURL:'http://api.eastwill.com/api/',
    baseURL:process.env.NODE_ENV === 'development'?'/apis/api/dataapi/':'/api/dataapi/',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if(!config.data) config.data = {}
    config.data.language = window.localStorage.getItem('language') ? window.localStorage.getItem('language').toLowerCase() : 'en';

    config.method = 'post'
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    config.data = qs.stringify(config.data);
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.

    if (res.code !== 200) {
    //   Message({
    //     message: res.msg || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
      console.log('错误')
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === -3) {
        // to re-login

        // MessageBox.confirm('登录已超时,请重新登录', '系统提示', {
        //   confirmButtonText: '重新登录',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })

        console.log('接口获取超时')

      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    console.log(error.message)
    return Promise.reject(error)
  }
)

export default service
