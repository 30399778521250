<template>
  <div class="com-content our-team">
    <div class="page-nav">
      {{ $t('header2') }} / {{$t('teamPageName')}}
    </div>

    <div class="search">
      <div class="search-name">
        <input type="text" :placeholder="$t('teamSearch')"  v-model="searchName">
        <span @click="getTeam()"></span>
      </div>
      <div class="search-title">
        <div class="select">
            <p>{{selectTitle.name || $t('teamTitle')}}</p>
            <ul>
              <li @click="setSelect(selectTitle,item)" v-for="(item,index) in selectData.titleList" v-bind:key="index">{{item.name}}</li>
            </ul>
        </div>
      </div>
      <div class="search-title">
        <div class="select">
            <p>{{selectUnit.name || $t('teamUnit')}}</p>
            <ul>
              <li @click="setSelect(selectUnit,item)" v-for="(item,index) in selectData.unitList" v-bind:key="index">{{item.unit}}</li>
            </ul>
        </div>
      </div>
      <div class="search-title">
        <div class="select">
            <p>{{selectLocation.name || $t('teamLocation')}}</p>
            <ul>
              <li @click="setSelect(selectLocation,item)" v-for="(item,index) in selectData.location" v-bind:key="index">{{item.location}}</li>
            </ul>
        </div>
      </div>
    </div>

    <div class="list">
      <div>
        <div class="th">
          <div>{{ $t('teamName')}}</div>
          <div>{{ $t('teamTitle')}}</div>
          <div>{{ $t('teamUnit')}}</div>
          <div>{{ $t('teamLocation')}}</div>
          <div></div>
        </div>

        <div class="td" v-for="(item,index) in listData" v-bind:key="index" @click='onClickOpenPop(item.id)'>
          <div>{{item.name}}</div>
          <div>{{item.title}}</div>
          <div>{{item.unit}}</div>
          <div>{{item.location}}</div>
          <div><img src="../assets/news-arrow.svg" alt=""></div>
          <div class="border"><i></i><i></i><i></i></div>
        </div>

      </div>
    </div>
    <div class="pop-up" v-if="popUp"> 
      <div>
        <div class="left">
          <img :src="contentData.imgUrl" alt="">
        </div>
        <div class="right">
          <h1 class="tit">{{contentData.name}}</h1>
          <h3>{{contentData.title}}</h3>
          <div class="content" v-html="contentData.content">
          </div>
        </div>
        <div class="close" @click="onClickPopClose"></div>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from "vue";
import wow from "wowjs";
import $ from "jquery";
import { getTeam, getTeamSelect } from "../api/common"
export default defineComponent({
  name: "ourTeam",
  data() {
    return {
      popUp:false,
      searchName:'',
      selectData:[],
      selectTitle:{},
      selectUnit:{},
      selectLocation:{},
      listData:[],
      contentData:{},
    };
  },
  components: {
  },
  mounted() {
    new wow.WOW({
      boxClass: "wow", //需要执行动画的元素的 class
      animateClass: "animated", //animation.css 动画的 class
      offset: 200, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备上执行动画
      live: true, //异步加载的内容是否有效
    }).init();
    
    $(".search-title>.select>p").click(function(){
      $(".search-title>.select>ul").hide();
      setTimeout(()=>{
        $(this).siblings("ul").show();
      })
    })

    document.addEventListener("click", this.bodyCloseMenus);

    this.getTeam();
    this.getTeamSelect();
  },
  methods:{
    bodyCloseMenus() {
      $(".search-title>.select>ul").hide();
    },
    onClickOpenPop(id) {
      this.popUp = true;
      getTeam({id:id}).then(res => {
        console.log(res.info)
        this.contentData = res.info[0];
      })
    },
    onClickPopClose() {
      this.popUp = false;
    },
    getTeam(){
      getTeam({
        name:this.searchName,
        title:this.selectTitle.id,
        unit:this.selectUnit.id,
        location:this.selectLocation.id,
      }).then(res => {
        this.listData = res.info;
      })
    },
    getTeamSelect(){
      getTeamSelect().then(res => {
        res.info.titleList.unshift({id:'',name:"All"})
        res.info.unitList.unshift({id:'',unit:"All"})
        res.info.location.unshift({id:'',location:"All"})
        console.log(res.info)
        this.selectData = res.info;
      })
    },
    setSelect(data,item){
      data.name = item.name || item.location || item.unit;
      data.id = item.id;
      this.getTeam();
    },
  }
});
</script>

<style scoped lang='less'>
.our-team {
  padding:0 3.8rem;
  padding-top:1px;
  padding-bottom:1px;
  .page-nav{
   color:#183763;
   font-weight: bold;
   margin-top:8.8rem;
   font-size:2rem;
  }

  .search{
    display:flex;
    justify-content: space-between;
    width:100%;
    margin-top:6.3rem;
    >div{
      width:40rem;
      font-weight:bold;
      font-size:1.7rem;
      border-bottom:2px solid #183763;
      position:relative;
      &:after{
        content:'';
        display:block;
        width:1rem;
        height:1rem;
        border-right:2px solid #183763;
        border-top:2px solid #183763;
        position:absolute;
        right:0;
        transform:rotate(135deg);
        top:1rem;
        right:1rem;
      }
      ul{
      }
    }
    .search-name{
      input{
        background:none;
        border:0;
        width:100%;
        outline:none;
        line-height:3rem;
        font-weight:bold;
        font-size:1.7rem;
      }
      ::-webkit-input-placeholder{
        opacity:0.7;
      }
      &:after{
        border:0;
        background:url('../assets/p5/search.svg');
        width:2rem;
        height:2rem;
        background-size:cover;
        transform:rotate(0);
        cursor:pointer;
      }
      span{
        position:absolute;
        right:0;
        top:0;
        width:4rem;
        height:100%;
        cursor:pointer;
        z-index: 1;
      }
    }
    .select{
      position:relative;
      p{
        opacity:0.5;
        cursor:pointer;
      }
      ul{
        position: absolute;
        background:#ffffff;;
        box-shadow: 0 0 1rem #c8ced4;
        width:100%;
        font-size:1.7rem;
        max-height:31.6rem;
        overflow-y:auto;
        display: none;
        z-index: 1;
        &::-webkit-scrollbar {
          width: 3px;
          height: 10px;
          
        }
        &::-webkit-scrollbar-track {
          background: rgb(220, 220, 220);
          border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: #04dccb;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #333;
        }
        &::-webkit-scrollbar-corner {
          background: #179a16;
        }
        li{
          border-bottom:1px solid #c8ced4;
          padding:2rem 3rem;
          cursor:pointer;
          transition:background 0.4s;
          &:hover{
            background:#c8ced4;
          }
        }
      }
    }
  }
  .list{
    margin-top:5rem;
    margin-bottom:14rem;
    font-size:2.2rem;
    >div{
      width:100%;
      text-align: left;;
      border-collapse:collapse;
      >div{
        position:relative;
        width:100%;
        cursor: pointer;;
        display:flex;
        justify-content: space-between;
        .border{
          width:100%;
          position:absolute; 
          max-width: inherit;
          top: 0;
          right: 0;
        }
        &:hover{
          img{
            opacity:1;
          }
          .border {
            i:first-child {
              height: 20%;
              transition: all 0.2s 0.4s linear;
            }
            i:nth-child(2) {
              width: 100%;
              transition: all 0.2s 0.2s linear;
            }
            i:nth-child(3) {
              height: 100%;
              transition: all 0.2s linear;
            }
          }
        }
      }
      .th{
        padding:4rem 0;
        background:#001c42;
        color:#ffffff;
        text-indent:2rem;
        >div{
          
          &:nth-child(1),&:nth-child(2),&:nth-child(3){
            width:25%;
          }
          &:nth-child(2){
            text-indent:0rem;
          }
          &:nth-child(4){
            width:15%;
          }
          &:nth-child(5){
            width:10%;
          }
        }
      }
      .td{
        text-indent:2rem;
        padding:4rem 0;
        border-bottom:1px solid #001c42;
        font-weight:bold;
        >div{
          display: inline;
          &:nth-child(1),&:nth-child(2),&:nth-child(3){
            width:25%;
          }
          &:nth-child(2){
            text-indent:0rem;
          }
          &:nth-child(4){
            width:15%;
          }
          &:nth-child(5){
            width:10%;
          }
        }
        img{
          width:7.2rem;
          margin-bottom:-6rem;
          transform: translateY(-3rem);
          opacity: 0;
          transition:opacity 0.3s;
          margin-right:3rem;
        }
      }
    }
  }
  .pop-up{
    position: fixed;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.4);
    top:0;
    left:0;
    z-index: 200;
    display: flex;
    justify-content:center;
    align-items:center;
    >div{
      width:90%;
      height:80rem;
      background:#ffffff;
      display:flex;
      padding:12rem 10rem;
      box-sizing: border-box;
      margin-left:-7rem;
      position: relative;
      .left{
        margin-right:9rem;
        img{
          width:42.6rem;
        }

      }
      .right{
        h1{
          font-size:7.2rem;
          line-height:5rem;
          margin:0;
          margin-bottom:1rem;
        }
        h3{
          font-size:3rem;
          font-weight:bold;
          min-width:47rem;
          border-bottom:1px solid #001c42;
          display: inline-block;
          margin:0;
          padding-bottom:2rem;
        }
        .content{
          font-weight:bold;
          line-height:3rem;
          padding-right:5rem;
          height:40rem;
          overflow-Y:auto;
          margin-top:2.6rem;
          font-size:1.5rem;
          &::-webkit-scrollbar {
            width: 3px;
            height: 10px;
            
          }
          &::-webkit-scrollbar-track {
            background: rgb(220, 220, 220);
            border-radius: 3px;
          }
          &::-webkit-scrollbar-thumb {
            background: #04dccb;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #333;
          }
          &::-webkit-scrollbar-corner {
            background: #179a16;
          }
          p{
            margin-bottom:4rem;
          }
        }
      }
      .close{
        width:7rem;
        height:7rem;
        background:#04dccb;
        position:absolute;
        right:-7rem;
        top:0;
        cursor:pointer;
        &:after,&:before{
          content:'';
          display: block;
          position:absolute;
          width:4.4rem;
          height:1px;
          top:0;right:0;bottom:0;left:0;
          background:#ffffff;
          margin:auto;
          transform:rotate(45deg);
          transition:all 0.4s;
        }
        &:before{
          transform:rotate(135deg)
        }
        &:hover{
          
        &:after{
          transform:rotate(-45deg)
        }
        &:before{
          transform:rotate(45deg)
        }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .our-team {  
    .page-nav{
    }

    .search{
      flex-wrap: wrap;
      >div{
        border-bottom-width: 1px;
        &:after{
        }
        ul{
        }
      }
      .search-name{
        width:100%;
        input{
          line-height:5rem;
        }
        ::-webkit-input-placeholder{
          text-indent:-0rem;
        }
        &:after{
        }
      }
      .search-title{
        width:100%;
        line-height:9rem;
        .select{
          p{
            opacity: 1;
          }
          ul{
            &::-webkit-scrollbar {
              
            }
            &::-webkit-scrollbar-track {
            }
            &::-webkit-scrollbar-thumb {
            }
            &::-webkit-scrollbar-thumb:hover {
            }
            &::-webkit-scrollbar-corner {
            }
            li{
              line-height:5rem;
              &:hover{
              }
            }
          }
        }
        &:after{
          top:3.5rem;
        }
      }
    }
    .list{
      >div{
        >div{
          .border{
          }
          &:hover{
            img{
            }
            .border {
              i:first-child {
              }
              i:nth-child(2) {
              }
              i:nth-child(3) {
              }
            }
          }
        }
        .th{
          >div{
            display:none;
            &:nth-child(1){
              display:block;
            }
          }
        }
        .td{
          >div{
            display: none;
            &:nth-child(1),&:nth-child(5){
              display:block;
            }
            &:nth-child(5){
              position: relative;
              right:3rem;
            }
          }
          img{
            opacity:1;
          }
        }
      }
    }
    .pop-up{
      >div{
        margin-left:0;
        height:97rem;
        padding:5rem;
        flex-wrap:wrap;
        .left{
          img{
            width:27rem;
          }

        }
        .right{
          h1{
            font-size:4.8rem;
          }
          h3{
            font-size:2.4rem;
          }
          .content{
            line-height:4.6rem;
            height:32rem;
            &::-webkit-scrollbar {
              
            }
            &::-webkit-scrollbar-track {
            }
            &::-webkit-scrollbar-thumb {
            }
            &::-webkit-scrollbar-thumb:hover {
            }
            &::-webkit-scrollbar-corner {
            }
            p{
            }
          }
        }
        .close{
          right:0;
          &:after,&:before{
          }
          &:before{
          }
          &:hover{
            
          &:after{
          }
          &:before{
          }
          }
        }
      }
    }
  }
}
</style>
