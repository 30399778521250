<template>
  <div class="com-content about-page">
    <kv :slogan="1"></kv>
    <!-- about 介绍 -->
    <div class="p2 common-desc">
      <div class="inner">
        <div class="left">
          <div class="right-line img-line">
            <span class="border wow animate__animated animate__line">
              <i class="animate__animated"></i>
              <i class="animate__animated"></i>
              <i class="animate__animated"></i>
            </span>
            <img :src="aboutData.introduce.imgurl" class=" wow animate__animated animate__slideInUps animated" alt="" />
          </div>
        </div>
        <div class="right">
          <p class="tit wow animate__animated animate__slideInUps">
            {{ aboutData.introduce.title }}
          </p>
          <i class=" wow animate__animated animate__slideInUps" ></i>
          <div class="details wow animate__animated animate__slideInUps" v-html="aboutData.introduce.content">
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="p3">
      <p class="tit common-tit wow animate__animated animate__slideInUps">
        {{ $t("p2Tit1") }}
      </p>
      <div class="arrows pc">
        <img src="../assets/left-arrow.svg" alt="" :style="swiper2PrevDisabled ? disabledStyle : ''" @click="prev2"/>
        <img src="../assets/right-arrow.svg" alt="" :style="swiper2NextDisabled ? disabledStyle : ''" @click="next2"/>
      </div>
      <div class="pagePoint" v-if="$store.state.isWap">
        <li v-for="(item,index) in pagePointLength" v-bind:key="index" :class="{active:swiper2.activeIndex==index}"></li>
      </div>
      <div class="person-list wow animate__animated animate__slideInUps">
        <swiper :slides-per-view="swiper2_view" :space-between="0" @swiper="personSwiper"
          @slide-change-transition-end="swiper2PcChange">
          <swiper-slide>
            <div class="item" @click="toLink(1)">
              <div>
                <img src="../assets/p2/p3-p1.png" alt="" />
                <img src="../assets/p2/drag-arrow.png" alt="" />
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <div>
                <p>{{$t('name1')}}</p>
                <p>{{$t('job1')}}</p>
                <img src="../assets/common-grren-arrow.png" alt="">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item" @click="toLink(2)">
              <div>
                <img src="../assets/p2/p3-p2.jpg" alt="">
                <img src="../assets/p2/drag-arrow.png" alt="">
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <div>
                <p>{{$t('name2')}}</p>
                <p>{{$t('job2')}}</p>
                <img src="../assets/common-grren-arrow.png" alt="">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item" @click="toLink(3)">
              <div>
                <img src="../assets/p2/p3-p3.jpg" alt="">
                <img src="../assets/p2/drag-arrow.png" alt="">
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <div>
                <p>{{$t('name3')}}</p>
                <p>{{$t('job2')}}</p>
                <img src="../assets/common-grren-arrow.png" alt="">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item" @click="toLink(4)">
              <div>
                <img src="../assets/p2/p3-p4.jpg" alt="">
                <img src="../assets/p2/drag-arrow.png" alt="">
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <div>
                <p>{{$t('name4')}}</p>
                <p>{{$t('job2')}}</p>
                <img src="../assets/common-grren-arrow.png" alt="">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item" @click="toLink(5)">
              <div>
                <img src="../assets/p2/p3-p5.jpg" alt="">
                <img src="../assets/p2/drag-arrow.png" alt="">
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <div>
                <p>{{$t('name5')}}</p>
                <p>{{$t('job2')}}</p>
                <img src="../assets/common-grren-arrow.png" alt="">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item" @click="toLink(6)">
              <div>
                <img src="../assets/p2/p3-p6.jpg" alt="">
                <img src="../assets/p2/drag-arrow.png" alt="">
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <div>
                <p>{{$t('name6')}}</p>
                <p>{{$t('job2')}}</p>
                <img src="../assets/common-grren-arrow.png" alt="">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item" @click="toLink(7)">
              <div>
                <img src="../assets/p2/p3-p7.jpg" alt="">
                <img src="../assets/p2/drag-arrow.png" alt="">
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <div>
                <p>{{$t('name7')}}</p>
                <p>{{$t('job2')}}</p>
                <img src="../assets/common-grren-arrow.png" alt="">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item" @click="toLink(8)">
              <div>
                <img src="../assets/p2/p3-p8.jpg" alt="">
                <img src="../assets/p2/drag-arrow.png" alt="">
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <div>
                <p>{{$t('name8')}}</p>
                <p>{{$t('job2')}}</p>
                <img src="../assets/common-grren-arrow.png" alt="">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="item" @click="toLink(9)">
              <div>
                <img src="../assets/p2/p3-p9.jpg" alt="">
                <img src="../assets/p2/drag-arrow.png" alt="">
                <div class="border fixed"><i></i><i></i><i></i></div>
              </div>
              <div>
                <p>{{$t('name9')}}</p>
                <p>{{$t('job2')}}</p>
                <img src="../assets/common-grren-arrow.png" alt="">
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div> -->
    <!-- 我们的产品 -->
    <div class="p3">
      <div class="top">
        <div class="left">
          <p class="common-tit tit">{{ $t("p3Tit2") }}</p>

          <div class="top-tit wap">
            <p class=" wow animate__animated animate__slideInUps">
              <span v-for="(item,index) in aboutData.strategies" v-bind:key="index" :class="{active:invesMenuActive == index}" @click='invesMenu(index)'>{{ item.name }}</span>
            </p>
          </div>
          <p class="tit wow animate__animated animate__slideInUps" >{{ aboutData.strategies[invesMenuActive].name }}</p>
           <p v-html="aboutData.strategies[invesMenuActive].content" class="wow animate__animated animate__slideInUps"></p>

        </div>
        <div class="right">
          <div class="top-tit pc">
            <p class=" wow animate__animated animate__slideInUps">
              <span v-for="(item,index) in aboutData.strategies" v-bind:key="index" :class="{active:invesMenuActive == index}" @click='invesMenu(index)'>{{ item.name }}</span>
            </p>
          </div>

          <div class=" wow animate__animated animate__slideInUps">
            <div class="carl"  v-for="(item,index) in aboutData.strategies[invesMenuActive].list" v-bind:key="index">
              <img class="open-carl" src="../assets/p2/plus.png" alt="" />
              <img class="close-carl" src="../assets/p2/des.png" alt="" />
              <p><span class="tit">{{item.title}}</span> </p>
              <p v-html="item.info">
              </p>
              <div class="border fixed"><i></i><i></i><i></i></div>
            </div>
          </div>

        </div>
      </div>
      <div class="bottom" v-if="aboutData.strategies[invesMenuActive].image[0]">
        <p class=" wow animate__animated animate__slideInUps">{{ aboutData.strategies[invesMenuActive].image[0].imgInfo}}</p>
        <div class="list wow animate__animated animate__slideInUps">
          <swiper :slides-per-view="3" :space-between="50"  @swiper="onInvesSwiper">

            <swiper-slide v-for="(item,index) in aboutData.strategies[invesMenuActive].image[0].imgList" v-bind:key="index">
              <div class="img-lists"><img :src="item.imgUrl" alt="" /></div>
            </swiper-slide>
            
          </swiper>
        </div>
        <div class="arrows wow animate__animated animate__slideInUps" v-if="aboutData.strategies[invesMenuActive].image[0].length >= 3">
          <img src="../assets/left-arrow.svg" :style="swiperPrevDisabled ? disabledStyle : ''"  alt="" @click="invesPrev"/>
          <img src="../assets/right-arrow.svg" :style="swiperNextDisabled ? disabledStyle : ''" alt="" @click="invesNext"/>
        </div>
      </div>
    </div>
    <!-- 新增 -->
    <div class="our-people">
      <div class="left">
        <p class="tit common-tit wow animate__animated animate__slideInUps">{{ aboutData.ourPeople.title}}</p>
        <p class="wow animate__animated animate__slideInUps" v-html=" aboutData.ourPeople.content"></p>
      </div>
      <div class="right">
        <router-link to="/team">{{ $t('ourPeopleTitle')}}</router-link>
        <img :src="aboutData.ourPeople.imgUrl" alt="">
      </div>
    </div>
    <!-- values -->
    <div class="p4">
      <div class="left">
        <p class="tit common-tit wow animate__animated animate__slideInUps">{{ aboutData.values.title}}</p>
        <p class="wow animate__animated animate__slideInUps" v-html="aboutData.values.content"></p>
        <!-- <p class="wow animate__animated animate__slideInUps">{{$t('p2ValueSub1')}}</p> -->
        <img :src="aboutData.values.imgUrl" alt="">
      </div>
      <div class="right">

        <div class="carl wow animate__animated animate__slideInUps" v-for="(item,index) in aboutData.values.list" v-bind:key="index">
          <img class="open-carl" src="../assets/p2/plus.png" alt="">
          <img class="close-carl" src="../assets/p2/des.png" alt="">
          <p class="tit">{{ item.title }}</p>
          <p>{{ item.info }}</p>
          <div class="border fixed"><i></i><i></i><i></i></div>
        </div>
        
      </div>
    </div>
    <div class="p7" v-show="aboutData?.content?.content1">
      
      <h2 class="wap tit">{{ aboutData?.content?.content1 }}</h2>
      <div class="wap content">
        {{ aboutData?.content?.content3 }}
      </div>
      <div class="model-1">
        <h2 class="tit">{{ aboutData?.content?.content1 }}</h2>
        <div class="left-line tit title">
          
          <span class="border border-left wow animate__animated animate__line">
              <i class="animate__animated"></i>
              <i class="animate__animated"></i>
              <i class="animate__animated"></i>
          </span>
          {{ aboutData?.content?.content2 }}
        </div>
        <div class="content">
          {{ aboutData?.content?.content3 }} 
        </div>
        <div class="logo">
          <h5>Alignment with Global Standards</h5>
          <div class="logo-list">
            <img src="../assets/p6/model-logo_1.png" alt="">
            <img src="../assets/p6/model-logo_2.png" alt="">
            <img src="../assets/p6/model-logo_3.png" alt="">
          </div>
        </div>
      </div>
      <div class="model-2">
        <div class="left">
          <div class="title tit">{{ aboutData?.content?.content4 }}</div>
          <div class="content" v-html="aboutData?.content?.content5">
          </div>
        </div>
        <div class="right">

          <template v-for="(item, index) in reversedPdfList(aboutData?.pdfList)" :key="index">
            <div class="wow animate__animated animate__slideInUps" @click="downLoad(item)">
              <!-- <img src="../assets/pdf.svg" class="icon" alt=""> -->
              <span class="tit">{{ item.file_name }}</span>
              <div class="button-down"><img src="../assets/download.svg" class="down-icon" alt=""></div>
              <div class="border fixed"><i></i><i></i><i></i></div>
            </div>
          </template>


        </div>
      </div>
    </div>
    <!-- 奖项 -->
    <div class="p5">
      <p class="common-tit tit wow animate__animated animate__slideInUps">
        {{ $t("p2Tit3") }}
      </p>
      <div class="awards wow animate__animated animate__slideInUps">
        <div class="item"  v-for="(item,index) in aboutData.awardsLsit" v-bind:key="index">
          <div class="show">
            <div><img :src="item.imgUrl" alt=""></div>
            <p class="tit" v-html="item.title"></p>
            <i></i>
            <!-- <p v-html="$t('awardsSub2')"></p> -->
          </div>
          <div class="hover">
            <span v-html="item.content"></span>
            <p class="tit" v-html="item.info"></p>
            <div class="border fixed"><i></i><i></i><i></i></div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { defineComponent } from "vue";
import $ from "jquery";
import Kv from "@/components/element/Kv";
import { Swiper, SwiperSlide } from "swiper/vue";
import wow from 'wowjs'
import {getAboutCbc} from '../api/common'
// Import Swiper styles
import "swiper/swiper.less";
export default  defineComponent({
  name: 'AboutUs',
  components: {Kv, Swiper, SwiperSlide},
  data() {
    return {
      timeRange: [],
      swiper: '',
      swiper2: '',
      disabledStyle: {'opacity': .5,'pointer-events':'none'},
      swiper2PrevDisabled: true,
      swiper2NextDisabled: false,
      swiper1PrevDisabled: true,
      swiper1NextDisabled: false,
      swiper2_view: 3.5,
      pagePointLength:9,
      // 新
      invesSwiper:'',
      invesMenuActive:0,
      swiperPrevDisabled: true,
      swiperNextDisabled: false,
      aboutData:{
        introduce:{},
        strategies:[
          {
            lsit:[],
            image:[
              {
                imgList:[]
              }
            ]
          }
        ],
        ourPeople:{},
        values:{
          list:[]
        }
      },
      awardsLsit:[]
    }
  },
  mounted() {
    new wow.WOW({
      boxClass: "wow", //需要执行动画的元素的 class
      animateClass: "animated", //animation.css 动画的 class
      offset: 200, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备上执行动画
      live: true, //异步加载的内容是否有效
    }).init();

      setTimeout(() =>{
        if(this.$route.query.anchorpoint && $("."+this.$route.query.anchorpoint).offset().top )
        $("html,body").animate({scrollTop:$("."+this.$route.query.anchorpoint).offset().top - 50}, 800); 
      },400)
    if(window.innerWidth < 960){
      this.swiper2_view = 1.5
    }
    this.getAboutData()
  },
  watch: {
    $route() {
      // 回到顶部
      if(this.$route.query.anchorpoint && $("."+this.$route.query.anchorpoint).length != 0 )
        $("html,body").animate({scrollTop:$("."+this.$route.query.anchorpoint).offset().top - 50}, 800); 
    },
  },
  methods: {
    reversedPdfList(data) {
      return data?.slice().reverse();
    },
    personSwiper(swiper) {
      this.swiper2 = swiper;
    },
    swiperPcChange(e){
      console.log(e.activeIndex);
      this.swiper1NextDisabled = false;
      this.swiper1PrevDisabled = false;
      if(e.activeIndex === 0) this.swiper1PrevDisabled = true
      if(e.activeIndex === 1) this.swiper1NextDisabled = true
    },
    swiper2PcChange(e){
      this.swiper2NextDisabled = false;
      this.swiper2PrevDisabled = false;
      if(e.activeIndex === 0) this.swiper2PrevDisabled = true
      if(e.activeIndex === 6) this.swiper2NextDisabled = true
    },
    prev2(){
      this.swiper2.slidePrev();
    },
    next2(){
      this.swiper2.slideNext();
    },
    onSwiper(swiper) {
      console.log(swiper);
      this.swiper = swiper;
    },
    prev(){
      this.swiper.slidePrev();
      this.swiper1NextDisabled = false;
      if(this.swiper.activeIndex === 0) this.swiper1PrevDisabled = true
    },
    next(){
      this.swiper.slideNext();
      console.log(this.swiper.activeIndex);
      this.swiper1PrevDisabled = false;
      if(this.swiper.activeIndex === 2) this.swiper1NextDisabled = true
    },
    handleClick() {
      $(".carl .tit").mouseenter(function () {
        $(this)
          .parents(".carl")
          .addClass("open")
          .siblings()
          .removeClass("open");
      });
      $(".close-carl").click(function () {
        $(this).parents(".carl").removeClass("open");
      });
    },
    toLink(id){
      this.$router.push({
          path: `/person/${id}`
        })
    },
    onInvesSwiper(swiper){
      this.invesSwiper = swiper;
    },
    downLoad(item){
      const a = document.createElement('a');
      a.href = item.file_url;
      a.target = '_blank';
      // a.download = index == 1 ? '2024 CBC Group TCFD Report' : 'CBC_Annual_Report_2023-2024 CBC ESG Report_Building a Sustainable Future';
      // a.download = item.download_name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    // 新
    invesMenu(e){
      $(".carl").removeClass("open");
      this.invesMenuActive = e;
      setTimeout(() => {
        $(".open-carl,.carl .tit").mouseenter(function () {
          $(this)
            .parents(".carl")
            .addClass("open")
            .siblings()
            .removeClass("open");
        });
      },1000)
    },
    invesPrev(){
      this.invesSwiper.slidePrev();
      this.swiperNextDisabled = false;
      if(this.invesSwiper.activeIndex === 0)
        this.swiperPrevDisabled = true
    },
    invesNext(){
      this.invesSwiper.slideNext();
      this.swiperPrevDisabled = false;
      if(this.invesSwiper.activeIndex === 3)
        this.swiperNextDisabled = true
    },
    getAboutData(){
      getAboutCbc({}).then(res => {
        console.log(res)
        this.aboutData = res.info
        setTimeout(() => {
          this.invesSwiper.update()
          this.handleClick();
        },400)
      })
    }
  }
})
</script>

<style scoped lang="less">
@import "../style";
.p2 {
}
.arrows {
  display: inline-block;
  img {
    width: 40px;
  cursor: pointer;
    &:first-child {
      margin-right: 50px;
      //opacity: 0.5;
    }
    &:last-child {
      margin-right: 200px;
    }
  }
}
.p3 {
  padding: 2.25rem;
  padding-top:8rem;
  background:#e7e7e7;
  padding-left:35px;
  .arrows {
    display: block;
    text-align: right;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    img {
      width: 40px;
      &:first-child {
        margin-right: 50px;
        //opacity: 0.5;
      }
      &:last-child {
        margin-right: 200px;
      }
    }
  }
  .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 7.5rem;
    .left,
    .right {
      width: 50%;
    }
    .left {
      width:40%;
        font-size: 1.4rem;
      .common-tit {
        font-size: 7.2rem;
      }
      > p {
        margin-bottom: 2.5rem;
        font-size: 1.4rem;
        width: 100%;
        &:nth-child(3) {
          font-size: 4.8rem;
          margin-top: 2.5rem;
          margin-bottom: 2.5rem;
        }
        &:nth-child(4) {
          font-size: 1.7rem;
        }
      }
      span{
        p{
          padding-bottom:4rem;
        }
      }
    }
    .right {
      .top-tit {
        margin-bottom: 9.5rem;
        font-size:1.7rem;
        > p {
          margin-bottom: 1.5rem;
          display: flex;
          flex-wrap:wrap;
          span {
            margin-right: 4rem;
            margin-top:2rem;
            position: relative;
            cursor:pointer;
            &:after {
              content: "";
              display: block;
              width: 0;
              height: 2px;
              background: @lineColor;
              position: absolute;
              bottom: -5px;
              left: 0;
              transition: all 0.5s;
            }
            &.active {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    > p {
      font-size: 1.875rem;
      margin-bottom: 2.8rem;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .img-lists {
        width: 100%;
        > img {
          width: 100%;
        }
      }
    }
  }
}
.our-people {
  display: flex;
  flex-wrap: wrap;
  padding:0 2.25rem;
  padding-top: 8rem;
  justify-content: space-between;
  padding-left: 35px;
  padding-bottom:8rem;
  .left {
    width: 40%;
    .common-tit {
      margin-bottom: 3rem;
      font-size:7.2rem;
    }
    > p {
      &:nth-child(2) {
        font-size: 1.7rem;
        line-height: 2;
        margin-bottom: 10rem;
      }
      &:nth-child(3) {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
    }
    > img {
      width: 75%;
    }
  }
  .right {
    text-align: right;
    width:50%;
    >a{
      margin-right:25rem;
      border-bottom:2px solid #24ddd2;
      display:inline-block;
      font-size:2.4rem;
    }
    >img{
      width:100%;
      margin-top:18rem;
    }
  }
}
.p4 {
  display: flex;
  flex-wrap: wrap;
  padding:0 2.25rem;
  padding-top: 8rem;
  justify-content: space-between;
  padding-left: 35px;
    background: #e7e7e7;
    padding-bottom:4rem;
  .left {
    width:40%;
    .common-tit {
      margin-bottom: 3rem;
      font-size:7.2rem;
    }
    > p {
      &:nth-child(2) {
        font-size: 1.7rem;
        line-height: 2;
        margin-bottom: 10rem;
      }
      &:nth-child(3) {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
    }
    > img {
      width: 75%;
    }
  }
  .right {
    width:50%;
  }
}
.p5 {
  padding-top: 6rem;
  margin-top: 2rem;
  padding-left: 35px;
  .common-tit {
    margin-bottom: 5rem;
  }
  .awards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    .item {
      width: 25%;
      position: relative;
      padding: 2rem;
      box-sizing: border-box;
      padding-bottom: 10rem;
      &:after {
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        background: #cfd2d6;
        position: absolute;
        top: 0;
        right: 0;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      .show {
        >div {
          width: 100%;
          height: 14rem;
          line-height: 8rem;
          margin-bottom: 2rem;
          >img {
            width: 40%;
          }
        }
        > p {
          &:nth-child(2) {
            font-size: 3rem;
            height: 5rem;
          }
          &:nth-child(4) {
            font-size: 1.7rem;
            height: 2.5rem;
          }
        }
        i {
          display: block;
          width: 4rem;
          height: 2px;
          background: @baseTextColor;
          margin-top:  7rem;
          margin-bottom: 10rem;
        }
      }
      .hover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 2rem;
        box-sizing: border-box;
        background: #e7e7e7;
        transition: all 0.5s;
        opacity: 0;
        span{font-size:1.4rem;}
        p {
          font-size: 2rem;
          line-height: 1.5;
          margin-bottom: 3rem;
          position: relative;
          padding-left: 1.25rem;
          &:first-child,
          &:nth-child(2) {
            &:before {
              content: "";
              display: block;
              width: 5px;
              height: 5px;
              background: @lineColor;
              border-radius: 5px;
              position: absolute;
              top: 1.3rem;
              left: 0;
            }
          }
          img {
            width: 50%;
            max-width: 100px;
            margin-top:2rem;
          }
          &.tit {
            margin-top: 10rem;
            font-size: 3.6rem;
            position:absolute;
            bottom:3rem;
            margin-bottom: 1.25rem;
            &:before {
              display: none;
            }
          }
        }
      }
      &:hover {
        .hover {
          opacity: 1;
          .border {
            opacity: 1;
            i:first-child {
              height: 25%;
              transition: all 0.2s 0.4s linear;
            }
            i:nth-child(2) {
              width: 100%;
              transition: all 0.2s 0.2s linear;
            }
            i:nth-child(3) {
              height: 100%;
              transition: all 0.2s linear;
            }
          }
        }
      }
    }
  }
}
.p6 {
  .common-tit {
    margin-top: 9rem;
    margin-bottom: 3.25rem;
    padding-left: 35px;
  }
  .top {
    display: flex;
    color: #fff;
    .left {
      width: 45%;
      background: @baseBgColor url("../assets/p2/p6-icon.png") no-repeat;
      background-position: -20% 110%;
      background-size:40%;
      p {
        margin-left: 3.25rem;
        &:first-child {
          margin-top: 4.5rem;
        }
        &:nth-child(2) {
          font-size: 4.8rem;
          margin-bottom: 2.75rem;
        }
        &:last-child {
          margin-left: 50%;
          margin-top: 30%;
          line-height: 2;
          font-size:2rem;
        }
      }
      i {
        display: block;
        width: 4rem;
        height: 2px;
        background: #fff;
        margin-left: 3.25rem;
      }
    }
    .right {
      width: 55%;
      > img {
        width: 100%;
        vertical-align: text-bottom;
      }
    }
  }
  .bottom {
    margin: 2rem auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      position: relative;
      > img {
        width: 100%;
      }
      .hover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #0f62b0;
        color: #fff;
        transition: all 0.5s;
        opacity: 0;
        > i {
          display: block;
          width: 4rem;
          height: 2px;
          background: #fff;
          margin-left: 6rem;
          margin-top: 2.25rem;
          margin-bottom: 6rem;
        }
        > p {
          margin-left: 6rem;
          &:first-child {
            font-size:3.6rem;
            margin-top: 2.5rem;
          }
          &:last-child {
            font-size:1.7rem;
            position:absolute;
            bottom:3rem;
          }
        }
        .border {
          i {
            &:first-child {
              height: 0%;
              left: 4px;
              top: calc(20% - 4px);
              bottom: auto;
            }
            &:nth-child(2) {
              width: 0;
              top: auto;
              left: 4px;
              bottom: 4px;
            }
            &:last-child {
              height:0%;
              right: 4px;
              top: auto;
              bottom: 4px;
            }
          }
        }
      }
      &:hover {
        .hover {
          opacity: 1;
          .border {
            i:first-child {
              height: 80%;
              transition: all 0.2s linear;
            }
            i:nth-child(2) {
              width: calc(100% - 8px);
              transition: all 0.2s 0.2s linear;
            }
            i:nth-child(3) {
              height: 20%;
              transition: all 0.2s 0.4s linear;
            }
          }
        }
      }
    }
  }
  .arrows {
    text-align: right;
    display: block;
    margin-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media screen and (max-width: 960px) {
  .arrows {
    img {
      &:first-child {
      }
      &:last-child {
      }
    }
  }
  .p3 {
    padding:3.5rem;
    padding-top:12rem;
    .top {
      flex-direction: column;
      .left,
      .right {
        width: 100%;
      }
      .left {
        .top-tit {
          margin-bottom: 7.5rem;
          font-size: 2.5rem;
          > p {
            margin-bottom: 1.5rem;
            display: flex;
            margin: 0;
            flex-direction: column;
            
            span {
              margin-right: 2rem;
              position: relative;
              width: fit-content;
              padding-top: 2rem;
              &:after {
                content: "";
                display: block;
                width: 0;
                height: 2px;
                background: @lineColor;
                position: absolute;
                bottom: -5px;
                left: 0;
                transition: all 0.5s;
              }
              &.active {
                &:after {
                  width: 100%;
                }
              }
            }
          }
        }
        .common-tit {
        }
        > p {
          width: 100%;
          font-size: 2rem;
            line-height:4rem;
            margin-bottom:7rem;
          &:nth-child(1) {
          }
          &:nth-child(3) {
            font-size: 5rem;
          }
        }
        >span{
          font-size:2rem;
          padding-bottom:8rem;
          padding-top:3rem;
          display:inline-block;
          line-height:4rem;
        }
      }
      .right {
      }
    }
    .bottom {
      > p {
        font-size: 3rem;
      }
      .list {
        flex-direction: column;
        > div {
          width: 100%;
          margin-top: 4rem;
          > img {
          }
        }
      }
    }
  }
.our-people {
  flex-wrap:wrap;
  padding-left:2.25rem;
  .left {
    width:100%;
    .common-tit {
    }
    > p {
      &:nth-child(2) {
        margin-bottom:4rem;
      }
      &:nth-child(3) {
      }
    }
    > img {
    }
  }
  .right {
    width:100%;
    text-align:left;
    >a{
    }
    >img{
      margin-top:11.5rem;
      margin-bottom:11.5rem;
    }
  }
}
  .p4 {
    flex-direction: column;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top:10rem;
    .left,
    .right {
      width: 100%;
    }
    .left {
      .common-tit {
      }
      > p {
        &:nth-child(2) {
          font-size: 2.5rem;
        }
        &:nth-child(3) {
          font-size: 2rem;
        }
      }
      > img {
        width: 100%;
      }
    }
    .right {
      margin-top: 10rem;
    }
  }
  .p5 {
    padding-left:3.5rem;
    .common-tit {
    }
    .awards {
      .item {
        border-top:1px solid #cfd2d6;
        width: 48%;
        padding-top:4rem;
        &:nth-child(1),&:nth-child(2){
          border:0;
        }
        &:nth-of-type(even){
          &::after{
            display:none;
          }
        }
        &:after {
        }
        &:last-child {
          &:after {
          }
        }
        .show {
          > img {
          }
          > p {
            &:nth-child(2) {
              height:8rem;
            }
            &:nth-child(4) {
              font-size: 1.8rem;
            }
          }
          i {
            height:1px;
          }
          >div{
            height:12rem;
            img{
              width:50%;
            }
          }
        }
        .hover {
          p {
            &:first-child,
            &:nth-child(2) {
              &:before {
                top: 1rem;
              }
            }
            img {
            }
            &:nth-child(3) {
            }
          }
        }
        &:hover {
          .hover {
            .border {
            }
          }
        }
      }
    }
  }
  .p6 {
    .common-tit {
      padding-left: 3.5rem;
      margin-top:10rem;
    }
    .top {
      flex-direction: column;
      .left {
        width: 100%;
        background-size:35rem;
        height: 73rem;
        position: relative;
        p {
          &:first-child {
            font-size: 2rem;
            margin-top:8rem;
          }
          &:nth-child(2) {
          }
          &:last-child {
            font-size: 2.5rem;
            position: absolute;
            bottom: 7rem;
            right:3.5rem;
          }
        }
        i {
        }
      }
      .right {
        width: 100%;
        > img {
        }
      }
    }
    .bottom {
      flex-direction: column;
      margin-top:12rem;
      margin-bottom:0;
      padding-bottom:12rem;
      .item {
        width: 100%;
        margin-top: 4rem;
        > img {
        }
        .hover {
          > i {
            margin-top:4rem;
          }
          > p {
            font-size: 2rem;
            &:first-child {
              margin-top:6rem;
            }
            &:last-child {
              bottom:5rem;
              font-size:2.6rem;
              width:60%;
            }
          }
          .border {
            i {
              &:first-child {
              }
              &:nth-child(2) {
              }
              &:last-child {
              }
            }
          }
        }
        &:hover {
          .hover {
            .border {
            }
          }
        }
      }
    }
    .arrows {
    }
  }
}
.p7{
  background:#f4f4f4;
    padding-top: 1px;
  .model-1{
    width:100%;
    height:108rem;
    background:url("../assets/p6/model-1.jpg") no-repeat;
    background-size:cover;
    background-position:center;
    position:relative;
    h2{
      font-size:7.2rem;
      color:#fff;
      position:absolute;
      top:10rem;
      left:4.5rem;
    }
    .title{
      font-size:6rem;
      width:105rem;
      color:#fff;
      position:absolute;
      top:20rem;
      right:4.5rem;
    }
    .content{
      font-size:1.7rem;
      width:70rem;
      color:#fff;
      position:absolute;
      bottom:10rem;
      left:4.5rem;
    }
    
    .logo{
        position:absolute;
        bottom:10rem;
        right:9rem;
        width:fit-content;
        h5{
          font-size:2.6rem;
          color:#fff;
          margin-bottom:5rem;
        }
        .logo-list{
          display: flex;
          justify-content: space-between;;
          img{
            height:4rem;
            margin-right:3rem;
          }
        }
      }
  }


  .model-2{
    padding:13rem 4rem;
    display: flex;
    justify-content: space-between;
    .left{
      color:#183763;
      .title{
        font-size:3.6rem;
      }
      .content{
        font-size:1.7rem;
        width:70rem;
        p{
          margin-top:3rem;
        }
      }
    }
    .right{
      font-size:2.6rem;
      width: 50%;
      .icon{
        width:3.8rem;
        margin-right:2rem;
        margin-left:2rem;
      }
      .down-icon{
        width:6.2rem;
        cursor:pointer;
      }
      >div{
        border-bottom:2px solid #183763;
        display: flex;
        align-items: center;
        position:relative;
        padding:2rem 0;
        cursor:pointer;
        .border {
          margin-top:-2px;
        }
        &:hover,
        &.hover {
          .border {
            i:first-child {
              height: 20%;
              transition: all 0.2s 0.4s linear;
            }
            i:nth-child(2) {
              width: 100%;
              transition: all 0.2s 0.2s linear;
            }
            i:nth-child(3) {
              height: 100%;
              transition: all 0.2s linear;
            }
          }
        }
        span{
          width:70%;
        }
        .button-down{
          height:6.2rem;
          position:absolute;
          bottom:0;
          right:2rem;
          top:0;
          margin:auto;
        }
      }
    }
  }




}
@media screen and (max-width: 960px) {
  .p7{
    >h2{
      padding:0 4rem;
      font-size:4.6rem;
    }
    >.content{
      padding:0 4rem;
      line-height:4rem;
      font-weight:600;
      margin-bottom:5rem;
    }
    .model-1{
      h2,.content{
        display: none;
      }
      .title{
        font-size:6rem;
        right:auto;
        left:5rem;
        width:56rem;
      }
      .border.border-left{
        &:nth-child(1){
          height: 40%;
        }
      }
      
      .logo{
          bottom:5rem;
          right:auto;
          left:6%;;
          width:90%;
          h5{
            font-size:2.6rem;
            color:#fff;
            margin-bottom:5rem;
          }
          .logo-list{
            display: flex;
            justify-content: space-between;;
            img{
              height:4rem;
              margin-right:3rem;
            }
          }
        }
    }
    .model-2{
      display: block;
      .left{
        .content{
          width:auto;
          font-size:2.4rem;
        }
      }
      .right{
        width:auto;
        margin-top:6rem;
        border-top:2px solid #183763;
      }
    }
  }
}


















</style>
<style lang="less">
.p5 .awards .item:hover .hover p img{
  max-width:100%;
}
.p7 .model-2 .left .content p{
  margin-top:3rem;
}
</style>