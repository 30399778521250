import { createI18n } from 'vue-i18n'

import CN from './cn'
import EN from './en'

// 语言库
const messages = {
    'CN': CN,
    'EN': EN
}

// 默认语言
const langDefault = window.localStorage.getItem('language') || 'EN'
// const langDefault = 'EN'

const i18n = createI18n({
    locale: langDefault,		//默认显示的语言
    messages
})

export default i18n; // 将i18n暴露出去，在main.js中引入挂载
